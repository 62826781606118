import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { space, layout, color, flexbox, compose, border } from 'styled-system'

import { modalSizes } from '../../configs'
import Icon from '../Icon'
import Row from '../Row'


const style = compose(space, layout, color, flexbox, border)

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1060;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
`

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  animation: slideDownModal 0.5s ease;
  width: 100%;
  height: fit-content;
  max-width: ${({ size = 'md' }) => modalSizes[size]};
  ${style};
`

const ModalHeaderStyle = styled.div`
  background-color: #fff;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
  ${style}
`
export const ModalBody = styled.div`
  background-color: #fff;
  padding: 16px;
  ${style}
`
export const ModalFooterStyle = styled.div`
  background-color: #fff;
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  ${style}
`

export const ModalHeader = ({ children, onClose = null, iconStyles = {}, ...props }) => (
  <ModalHeaderStyle {...props}>
    <Row alignItems='center' justifyContent={onClose && children ? 'space-between' : onClose && !children ? 'flex-end' : 'flex-start'}>
      {children}
      {onClose && <Icon icon='Close' color='grey.300' cursor='pointer' size='lg' onClick={onClose} {...iconStyles} />}
    </Row>
  </ModalHeaderStyle>
)

export const ModalFooter = ({ children, actionStyles = {}, ...props }) => (
  <ModalFooterStyle {...props}>
    <Row width='100%' justifyContent='flex-end' gap='8px' {...actionStyles}>
      {children}
    </Row>
  </ModalFooterStyle>
)

const portalContainer = document.getElementById('portal-container')

const Modal = ({ open, closeClickOut, onClose, children, ...props }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    }

    return () => (document.body.style.overflow = 'auto')
  }, [open])

  if (!open || !portalContainer) return null

  const handleClose = (event) => {
    if (closeClickOut && event.target === event.currentTarget) {
      onClose()
    }
  }

  return (
    portalContainer &&
    open &&
    createPortal(
      <ModalWrapper onClick={handleClose}>
        <ModalContent {...props}>{children}</ModalContent>
      </ModalWrapper>,
      portalContainer,
    )
  )
}

Modal.defaultProps = {
  closeClickOut: false,
}

export default Modal
