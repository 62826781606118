import { useEffect, useState } from 'react'

import { PageHeader, ModalTags, ModalConfirmation, RobotHelp } from 'components'
import { Column, Row, Button, Icon, Card, Table, ColorChip } from 'design-system'
import { notify, withAuthorization } from 'helpers'
import { deleteTag, showTagsList } from 'services'

import { useTagsReducer, actionTypes } from './store'

const Tags = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
    page_size: 50,
    page: 1,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useTagsReducer()

  useEffect(() => {
    fetch()
  }, [pagination?.page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showTagsList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de etiquetas.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteTag(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Etiqueta excluída com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir a etiqueta.')
    }
  }

  const columns = [
    {
      header: 'Título',
      field: 'name',
      cellProps: {
        width: '50%',
      },
    },
    {
      header: 'Destaque',
      cellProps: { width: '50%' },
      render: ({ color }) => <ColorChip color={color} />,
    },
    {
      header: 'Ações',
      render: (row) => (
        <Row gap='20px'>
          <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
          <Icon
            cursor='pointer'
            icon='Trash'
            color='danger'
            onClick={() =>
              setModalConfirmation({
                type: 'danger',
                title: `Excluir etiqueta ${row?.name}.`,
                message:
                  'Deseja realmente deletar esta etiqueta? A etiqueta deletada será removida de todos os contatos. Você pode inativar a etiqueta e impedir que ela seja atrelada a outros contatos.',
                handler: () => handleDelete(row.id),
              })
            }
          />
        </Row>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Etiquetas'>
        <Row gap='8px' justifyContent='flex-end'>
          <Button variant='icon' color='primary' onClick={() => setModal(true)}>
            <Icon icon='Add' />
          </Button>
        </Row>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='etiqueta'
          columns={columns}
          loading={loading}
          data={state.tags}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não tem etiquetas, adicione para começar a organizar seus contatos!'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <RobotHelp
        maxWidth='580px'
        mt='16px'
        text='Com as etiquetas personalizadas, você pode identificar seus contatos para facilitar a gestão de informações na página de relatórios. Além disso, é possível enviar campanhas facilmente para um grupo de contatos que tenham a etiqueta desejada.'
      />
      <ModalTags tag={modal} onClose={() => setModal(null)} dispatch={dispatch} actionTypes={actionTypes} />

      <ModalConfirmation open={modalConfirmation} onClose={() => setModalConfirmation(null)} {...modalConfirmation} />
    </Column>
  )
}

export default withAuthorization(Tags, ['manage_contacts'])
