import Icon from 'design-system/Icon'
import Row from 'design-system/Row'

const Toolbar = ({ editor }) => {
  const commandDict = {
    mark: (command) => editor.chain().focus().toggleMark(command).run(),
    list: (command) => editor.chain().focus().toggleList(command).run(),
  }

  return (
    <Row gap='8px' p='8px 0'>
      {optionsMark.map(({ icon, command, type }) => (
        <Icon
          key={command}
          icon={icon}
          cursor='pointer'
          color={editor.isActive(command) ? 'secondary' : 'grey.300'}
          onClick={() => commandDict[type](command)}
        />
      ))}
    </Row>
  )
}

const optionsMark = [
  {
    icon: 'FontBold',
    command: 'bold',
    type: 'mark',
  },
  {
    icon: 'FontItalic',
    command: 'italic',
    type: 'mark',
  },
  {
    icon: 'FontStrike',
    command: 'strike',
    type: 'mark',
  },
  // {
  //   icon: 'ListBullet',
  //   command: 'bulletList',
  //   type: 'list',
  // },

  // {
  //   icon: 'ListOrdered',
  //   command: 'orderedList',
  //   type: 'list',
  // },
]

export default Toolbar
