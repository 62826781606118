import { memo } from 'react'

const FontItalic = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10 5V8H12.21L8.79 16H6V19H14V16H11.79L15.21 8H18V5H10Z' fill='black' />
    </svg>
  )
}
const Memo = memo(FontItalic)
export default Memo
