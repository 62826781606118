export const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/

export const underscoreValidateRegex = /^[a-z_]+$/

export const strongRegex = /\*(.*?)\*/g
export const italicRegex = /\_(.*?)\_/g
export const strikeRegex = /\~(.*?)\~/g

export const breakLineRegex = /\n/g

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
