import { AxiosResponse } from 'axios'

import { clientApi } from 'providers'
import { PaginatedResponse } from 'types/generic.types'

import {
  TicketListParams,
  Ticket,
  TicketMessage,
  TicketMessageListParams,
  SearchMessagesParams,
  TicketCounters,
  ForwardMessagesRequest,
  SearchMessagesRepose
} from './types'

export const showTicketInfo = (id: number): Promise<AxiosResponse<Ticket>> => clientApi.get(`/tickets/${id}/`)

export const showTicketList = (params: TicketListParams): Promise<AxiosResponse<PaginatedResponse<Ticket>>> => clientApi.get(`/tickets/`, { params })

export const createTicket = (data: Partial<Ticket>): Promise<AxiosResponse<Ticket>> => clientApi.post('/tickets/', data)

export const updateTicket = (id: number, data: Partial<Ticket>): Promise<AxiosResponse<Ticket>> => clientApi.put(`/tickets/${id}/`, data)

export const showTicketCounters = (params: TicketListParams): Promise<AxiosResponse<TicketCounters>> =>
  clientApi.get(`/tickets/counters/`, { params })

// #MESSAGES
export const sendMessageTicket = (id: number, data: TicketMessage): Promise<AxiosResponse<TicketMessage>> =>
  clientApi.post(`/tickets/${id}/messages/`, data)

export const showTicketMessagesList = (id: number, params: TicketMessageListParams): Promise<AxiosResponse<PaginatedResponse<Ticket>>> =>
  clientApi.get(`/tickets/${id}/messages/`, { params })

export const searchMessages = (params: SearchMessagesParams): Promise<AxiosResponse<PaginatedResponse<SearchMessagesRepose>>> =>
  clientApi.get(`/tickets/messages/search/`, { params })

export const markMessageRead = (id: number, data: Partial<Ticket>): Promise<AxiosResponse<Ticket>> =>
  clientApi.post(`/tickets/${id}/mark-messages-as-read/`, data)

export const forwardMessages = (data: ForwardMessagesRequest): Promise<AxiosResponse<ForwardMessagesRequest>> =>
  clientApi.post('/tickets/forward-messages/', data)
