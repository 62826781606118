import { useEffect } from 'react'

import { Icon, ListItem, Popover, Row, Typography } from 'design-system'
import { useClickout } from 'hooks'

const PopoverMenuOptions = ({ openedState, elementRef, actions, currentFolder, folders, file, moveAction, ...props }) => {
  const { openedState: moveState, setOpenedState: setMoveState, elementRef: moveRef } = useClickout(false)

  useEffect(() => {
    if (!openedState) {
      setMoveState(false)
    }
  }, [openedState])

  return (
    <Popover ref={elementRef} open={openedState} width='130px' {...props}>
      {actions?.map(({ label, icon, color, action, move }) => (
        <ListItem key={label} onClick={move ? () => setMoveState(true) : action}>
          <Row gap='4px' className='cursor-pointer'>
            <Icon icon={icon} color={color} />
            <Typography color={color}>{label}</Typography>
          </Row>
        </ListItem>
      ))}
      {folders?.length > 0 && (
        <Popover ref={moveRef} width='140px' open={moveState} top='35px' right='-140px'>
          {folders
            .filter((f) => f?.id !== currentFolder?.id)
            .map(({ name, id }) => (
              <ListItem key={id} onClick={() => moveAction(currentFolder?.id, id, file)}>
                <Typography cursor='pointer'>{name}</Typography>
              </ListItem>
            ))}
        </Popover>
      )}
    </Popover>
  )
}

PopoverMenuOptions.defaultProps = {
  actions: [],
}

export default PopoverMenuOptions
