import { memo } from 'react'

const FontStrike = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.24 10.2451C6.98 9.76512 6.85 9.21512 6.85 8.57512C6.85 7.96512 6.98 7.41512 7.25 6.90512C7.51 6.40512 7.88 5.97512 8.36 5.61512C8.84 5.26512 9.41 4.98512 10.06 4.78512C10.72 4.59512 11.45 4.49512 12.24 4.49512C13.05 4.49512 13.78 4.60512 14.45 4.83512C15.11 5.05512 15.68 5.37512 16.14 5.77512C16.61 6.17512 16.97 6.65512 17.22 7.20512C17.47 7.75512 17.6 8.35512 17.6 9.01512H14.59C14.59 8.70512 14.54 8.42512 14.44 8.16512C14.35 7.89512 14.2 7.67512 14 7.48512C13.8 7.29512 13.55 7.15512 13.25 7.04512C12.95 6.94512 12.59 6.88512 12.19 6.88512C11.8 6.88512 11.45 6.92512 11.16 7.01512C10.87 7.10512 10.63 7.22512 10.44 7.37512C10.25 7.53512 10.1 7.71512 10 7.92512C9.9 8.13512 9.85 8.35512 9.85 8.58512C9.85 9.06512 10.1 9.46512 10.59 9.79512C10.97 10.0451 11.36 10.2751 12 10.4951H7.39C7.34 10.4151 7.28 10.3251 7.24 10.2451ZM21 11.4951H3V13.4951H12.62C12.8 13.5651 13.02 13.6351 13.17 13.6951C13.54 13.8651 13.83 14.0351 14.04 14.2051C14.25 14.3751 14.39 14.5651 14.47 14.7751C14.54 14.9751 14.58 15.2051 14.58 15.4651C14.58 15.6951 14.53 15.9151 14.44 16.1251C14.35 16.3251 14.21 16.5051 14.02 16.6551C13.83 16.8051 13.6 16.9151 13.31 17.0051C13.02 17.0851 12.68 17.1351 12.3 17.1351C11.87 17.1351 11.47 17.0951 11.12 17.0051C10.77 16.9151 10.46 16.7751 10.21 16.5851C9.96 16.3951 9.76 16.1451 9.62 15.8351C9.48 15.5251 9.37 15.0751 9.37 14.6251H6.4C6.4 15.1751 6.48 15.7551 6.64 16.2051C6.8 16.6551 7.01 17.0551 7.29 17.4151C7.57 17.7651 7.89 18.0751 8.27 18.3351C8.64 18.5951 9.05 18.8151 9.49 18.9851C9.93 19.1551 10.39 19.2851 10.87 19.3751C11.35 19.4551 11.83 19.5051 12.31 19.5051C13.11 19.5051 13.84 19.4151 14.49 19.2251C15.14 19.0351 15.7 18.7751 16.16 18.4351C16.62 18.0951 16.98 17.6651 17.23 17.1651C17.48 16.6651 17.61 16.0951 17.61 15.4551C17.61 14.8551 17.51 14.3151 17.3 13.8451C17.25 13.7351 17.19 13.6151 17.13 13.5151H21V11.4951Z'
        fill='black'
      />
    </svg>
  )
}
const Memo = memo(FontStrike)
export default Memo
