import { format } from 'date-fns'

import { colors } from 'configs'
import { Column, Icon, Row, SanitizeDiv, Tooltip, Typography } from 'design-system'

import headerComponentDict from './typeHeaders'

const PreviewTemplateCard = ({ template, hiddenTitle, ...props }) => {
  const { content, footer, header, buttons, content_variants } = template || {}
  const { header_type } = header || {}

  const buttonsIcon = {
    QUICK_REPLY: 'Share',
    PHONE_NUMBER: 'Phone',
    URL: 'Launch',
    COPY_CODE: 'Copy',
  }

  const handleReplaceTemplateData = (contentValue) => {
    if (content_variants?.length > 0) {
      content_variants.forEach(({ key, text }) => {
        contentValue = text ? contentValue?.replace(key, text) : contentValue
      })
    }
    return contentValue
  }

  return (
    <Column width='100%' maxWidth='342px' p='32px' justifyContent='center' background={colors.grey[25]} borderRadius='8px' {...props}>
      {!hiddenTitle && (
        <Typography mb='16px' fontSize='18px' fontWeight='600' textAlign='center'>
          Visualização do template
        </Typography>
      )}
      <Column width='100%' maxWidth='342px' p='8px' borderRadius={buttons?.length > 0 ? '8px 8px 0 0' : '8px'} backgroundColor={colors.violet[100]}>
        <Row>{header_type?.category && headerComponentDict[header_type?.category](header)}</Row>
        {content && <SanitizeDiv content={handleReplaceTemplateData(content)} />}
        {footer && (
          <Row justifyContent='flex-end' alignItems='center'>
            <Typography>{footer}</Typography>
          </Row>
        )}
        <Row mt='8px' gap='4px' justifyContent='flex-end' alignItems='center'>
          <Typography color='grey.200'>{format(new Date(), 'hh:mm')}</Typography>
          <Icon icon='CheckAll' color='grey.200' />
        </Row>
      </Column>
      {buttons?.length > 0 && (
        <Column mt='1px' gap='1px' width='100%' maxWidth='342px'>
          {buttons?.map(({ button_type, text, phone, url }, index) => (
            <Row
              width='100%'
              key={`${button_type}_${index}`}
              justifyContent='center'
              borderRadius={buttons?.length - 1 === index ? '0 0 4px 4px' : '0'}
              alignItems='center'
              p='8px'
              background={colors.violet[100]}
            >
              <Tooltip title={phone || url || text}>
                <Icon icon={buttonsIcon[button_type]} color='grey.400' />
                <Typography ml='4px'>{text}</Typography>
              </Tooltip>
            </Row>
          ))}
        </Column>
      )}
    </Column>
  )
}

export default PreviewTemplateCard
