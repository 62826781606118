/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import { Button, Column, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography } from 'design-system'
import { notify } from 'helpers'
import { sendMessageTicket } from 'services'

import PreviewTemplate from '../ModalCreateNewTicket/PreviewTemplate'
import ModalManageFiles from '../ModalManageFiles'

const ModalSendConversationTemplate = ({ open, onClose, ticket, callBack }) => {
  const [loading, setLoading] = useState(false)
  const [manageFile, setManageFile] = useState(false)

  const methods = useForm()

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.template = values.template.id
      values.message_type = 'template'
      values.template_params = values.template_params.filter((i) => i.param).map((i) => i.text)
      values.header = {
        media: values?.file,
      }
      await sendMessageTicket(ticket?.id, values)

      callBack && callBack(true)
      onClose()
    } catch {
      notify.error('Não foi possível enviar template na conversa.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={open}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Enviar template
        </Typography>
      </ModalHeader>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalBody>
            <Column>
              <PreviewTemplate defaultConnection={ticket?.connection} loading={loading} setManageFile={setManageFile} />
            </Column>
            {loading && (
              <Row alignItems='center' justifyContent='flex-end' mt='16px' gap='16px'>
                <Column>
                  <Spinner size='sm' />
                </Column>
                <Typography>Estamos enviando seu template agora... Este processo pode levar alguns segundos. Por favor, aguarde.</Typography>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
              Cancelar
            </Button>
            <Button maxWidth='70px' type='submit' disabled={loading}>
              Enviar
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
      {manageFile && (
        <ModalManageFiles
          open={manageFile}
          onClose={() => setManageFile(null)}
          setFieldValue={(file) => {
            methods.setValue('file', file.uuid)
            methods.setValue('template.header.default_media', file)
          }}
        />
      )}
    </Modal>
  )
}

export default ModalSendConversationTemplate
