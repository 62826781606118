import { useNavigate } from 'react-router-dom'

import { robot404 } from 'assets/ilustrations'
import { Button, Column, Image, Typography } from 'design-system'

const Page404 = () => {
  const navigate = useNavigate()

  return (
    <Column width='100%' height='auto' alignItems='center' justifyContent='center' p='24px'>
      <Image maxWidth='400px' width='100%' src={robot404} />
      <Typography mt='24px' fontWeight='600' fontSize='24px'>
        Página não encontrada (404)
      </Typography>
      <Typography width='415px' textAlign='center' fontSize='16px' mt='16px'>
        Parece que a página que você está procurando não existe. Talvez você tenha digitado o
        endereço errado ou a página tenha sido movida.
      </Typography>
      <Button maxWidth='320px' mt='24px' onClick={() => navigate('/tickets')}>
        Voltar para página inicial
      </Button>
    </Column>
  )
}

export default Page404
