import { useRef } from 'react'

import { actionLabelByValue } from 'components/modals/ModalCreateTemplate/templateOptions'
import { Column, Icon, Input, Row, Tooltip } from 'design-system'

const fieldDict = {
  QUICK_REPLY: ({ name, errorField, register, index }) => (
    <Input
      label='Texto do botão'
      error={errorField?.[index] && errorField?.[index]?.text}
      errorMessage={errorField?.[index]?.text?.message}
      required
      {...register(`${name}.text`, {
        required: { value: true, message: 'Por favor, insira o texto do botão.' },
        maxLength: {
          value: 25,
          message: `O texto da resposta rápida não pode exceder 25 caracteres.`,
        },
      })}
    />
  ),
  PHONE_NUMBER: ({ name, errorField, register, index }) => (
    <>
      <Input
        label='Telefone'
        error={errorField?.[index] && errorField?.[index]?.phone}
        errorMessage={errorField?.[index]?.phone?.message}
        required
        {...register(`${name}.phone`, {
          required: { value: true, message: 'Por favor, insira o texto do telefone.' },
          maxLength: {
            value: 20,
            message: `Máximo 20 caracteres.`,
          },
        })}
      />
      <Input
        label='Texto do botão'
        error={errorField?.[index] && errorField?.[index]?.text}
        errorMessage={errorField?.[index]?.text?.message}
        required
        {...register(`${name}.text`, {
          required: { value: true, message: 'Por favor, insira o texto do botão.' },
          maxLength: {
            value: 25,
            message: `Máximo 25 caracteres.`,
          },
        })}
      />
    </>
  ),
  URL: ({ name, errorField, register, index }) => (
    <>
      <Input
        label='URL do botão'
        error={errorField?.[index] && errorField?.[index]?.url}
        errorMessage={errorField?.[index]?.url?.message}
        required
        {...register(`${name}.url`, {
          required: { value: true, message: 'Por favor, insira o texto da url.' },
        })}
      />
      <Input
        label='Texto do botão'
        error={errorField?.[index] && errorField?.[index]?.text}
        errorMessage={errorField?.[index]?.text?.message}
        required
        {...register(`${name}.text`, {
          required: { value: true, message: 'Por favor, insira o texto da url.' },
          maxLength: {
            value: 25,
            message: `Máximo 25 caracteres.`,
          },
        })}
      />
    </>
  ),
  COPY_CODE: ({ name, errorField, register, index }) => (
    <Input label='Código' error={errorField?.[index] && errorField?.[index]?.text} {...register(`${name}.text`, { required: true })} />
  ),
}

const ActionField = ({ field, name, removeField, index, register, containerIndex, errors, ...props }) => {
  const { button_type } = field

  const fieldRef = useRef()

  const errorField = errors?.containers?.[containerIndex]?.buttons

  return (
    <Column p='16px'>
      <Row ref={fieldRef} gap='16px' {...props}>
        <Input maxWidth='150px' label='Tipo de botão' value={actionLabelByValue[button_type]} {...register(`${name}.button_type`)} disabled />
        {fieldDict[button_type]({ name, errorField, register, containerIndex, index })}
        <Tooltip position='bottom' title='Deletar'>
          <Icon mt='24px' icon='Close' color='text' onClick={() => removeField(index, containerIndex)} cursor='pointer' />
        </Tooltip>
      </Row>
    </Column>
  )
}

export default ActionField
