export const initialValues = {
  connection: '',
  attendant: '',
  department: '',
  contact: '',
}

export const templateTypeDict = {
  VIDEO: 'MEDIA',
  IMAGE: 'MEDIA',
  DOCUMENT: 'MEDIA',
}
