import { memo } from 'react'

const Less = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M19 13H5V11H19V13Z' fill='black' />
    </svg>
  )
}
const Memo = memo(Less)
export default Memo
