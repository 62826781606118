import { documentThumb, imageThumb, videoThumb } from 'assets'
import { FilePreview, Image, Typography, VideoPlayer } from 'design-system'

const headerComponentDict = {
  TEXT: ({ text }) => (
    <Typography color='grey.400' fontWeight='600'>
      {text}
    </Typography>
  ),
  IMAGE: ({ default_media }) =>
    default_media?.file ? (
      <Image key={default_media?.uuid} src={default_media?.file} width='100%' height='129px' objectFit='cover' />
    ) : (
      <Image width='100%' height='129px' src={imageThumb} />
    ),
  VIDEO: ({ default_media }) =>
    default_media?.file ? (
      <VideoPlayer key={default_media?.uuid} height='129px' minHeight='129px' background='black' src={default_media?.file} />
    ) : (
      <Image src={videoThumb} width='100%' height='129px' />
    ),

  DOCUMENT: ({ default_media }) =>
    default_media?.file ? (
      <FilePreview
        key={default_media?.uuid}
        width='100%'
        fileName={default_media?.name}
        src={default_media?.file}
        preview={default_media?.category}
        hiddenDownload
      />
    ) : (
      <Image src={documentThumb} width='100%' height='129px' />
    ),
  LOCATION: () => <Typography>LOCATION</Typography>,
}

export default headerComponentDict
