import { memo } from 'react'

const FolderRecent = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V12.125C21.3333 11.425 20.5667 10.8958 19.7 10.5375C18.8333 10.1792 17.925 10 16.975 10C15.0417 10 13.3958 10.6792 12.0375 12.0375C10.6792 13.3958 10 15.0417 10 16.975C10 17.5083 10.0583 18.025 10.175 18.525C10.2917 19.025 10.4667 19.5167 10.7 20H4ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 22 17 22C18.3833 22 15.025 20.025 16 21C16.975 21.975 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22ZM17 14.5C16.9 14.6 17.1333 14.5 17 14.5C17 14.6333 17.1 14.4 17 14.5C17 14.3667 17.1498 14.5279 17.0498 14.4279C16.9498 14.3279 17.0936 14.4279 16.9603 14.4279C16.827 14.4279 17.1 14.4 17 14.5C17 14.3667 16.9 14.6 17 14.5C17 14.6333 16.8603 14.5073 16.9603 14.6073C17.0603 14.7073 16.8667 14.5 17 14.5Z'
        fill='#0D0D0D'
      />
      <path
        d='M18.65 19.35L19.35 18.65L17.5 16.8V14.5H16.5V17.2L18.65 19.35ZM17 22C16.3083 22 15.6583 21.8688 15.05 21.6063C14.4417 21.3438 13.9125 20.9875 13.4625 20.5375C13.0125 20.0875 12.6563 19.5583 12.3938 18.95C12.1313 18.3417 12 17.6917 12 17C12 16.3083 12.1313 15.6583 12.3938 15.05C12.6563 14.4417 13.0125 13.9125 13.4625 13.4625C13.9125 13.0125 14.4417 12.6563 15.05 12.3938C15.6583 12.1313 16.3083 12 17 12C17.6917 12 18.3417 12.1313 18.95 12.3938C19.5583 12.6563 20.0875 13.0125 20.5375 13.4625C20.9875 13.9125 21.3438 14.4417 21.6063 15.05C21.8688 15.6583 22 16.3083 22 17C22 17.6917 21.8688 18.3417 21.6063 18.95C21.3438 19.5583 20.9875 20.0875 20.5375 20.5375C20.0875 20.9875 19.5583 21.3438 18.95 21.6063C18.3417 21.8688 17.6917 22 17 22Z'
        fill='#0D0D0D'
      />
    </svg>
  )
}
const Memo = memo(FolderRecent)
export default Memo
