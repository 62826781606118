import { useState } from 'react'
import styled from 'styled-components'
import { space, layout, color, compose, position, border } from 'styled-system'

import { imageThumb } from 'assets'

const style = compose(space, layout, color, position, border)

const StyledImage = styled.img`
  object-fit: ${({ objectFit = 'initial' }) => objectFit};
  cursor: ${({ cursor = 'initial' }) => cursor};
  display: ${({ loading }) => (loading ? 'none' : 'block')};

  border-radius: ${({ borderRadius = '0' }) => borderRadius};

  ${style};
`

const Image = (props) => {
  const [loading, setLoading] = useState(true)

  return (
    <>
      {loading && props?.hasThumb && <StyledImage {...props} src={imageThumb} />}
      <StyledImage onLoad={() => setLoading(false)} loading={loading} {...props} />
    </>
  )
}

export default Image
