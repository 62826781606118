import { useMemo } from 'react'
import styled from 'styled-components'

import CardSurveyEmoji from 'components/specific/dashboard/CardSurveyEmoji'
import { colors, dashboardSurveyDict } from 'configs'
import { Avatar, Column, Row, Tooltip, Typography } from 'design-system'
import { ratingRangeReduce } from 'helpers'

const Progress = styled(Row)`
  width: 0;
  height: 8px;
  border-radius: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;
  animation-delay: ${({ delay }) => delay};
`

const ProgressLine = styled(Row)`
  width: ${({ percent }) => (percent ? '100%' : '0%')};
`

const ProgressSurvey = ({ user, selectedUser, index }) => {
  const [, setSelectedUser] = selectedUser || []

  const { company_user, rates, rated_amount } = user

  const goodOrGreat = useMemo(() => ratingRangeReduce(rates, [4, 5]), [rates])

  return (
    <Column width='100%' p='8px'>
      {selectedUser && (
        <Row width='100%' alignItems='center' justifyContent='space-between'>
          <Row alignItems='center' gap='8px' className='cursor-pointer' onClick={() => setSelectedUser(user)}>
            <Avatar src={company_user?.picture} alt={company_user?.name || 'Sem atendente'} />
            <Typography fontSize='14px' color='blue.80'>
              {company_user?.name || 'Sem atendente responsável'}
            </Typography>
          </Row>
          <Typography>{`${goodOrGreat || 0}%`}</Typography>
        </Row>
      )}
      <Progress mt='8px' backgroundColor={colors.grey[25]} className='animation-grow-element' delay={`0.${index}s`}>
        {rated_amount > 0 ? (
          rates?.map(({ rate, percent, amount }, idx) => {
            const { color, label, emoji } = dashboardSurveyDict[rate?.value]

            return (
              <Tooltip
                key={idx}
                bgColor={colors.white}
                containerProps={{
                  width: `${percent}%`,
                  backgroundColor: 'transparent',
                  borderRadius: idx === rates.length - 1 ? '0 20px 20px 0' : '0',
                }}
                title={<CardSurveyEmoji bgColor='#fff' label={label} emoji={emoji} percent={`${percent}%`} value={amount} color={color} />}
              >
                <ProgressLine percent={`${percent}%`} backgroundColor={color} borderRadius={idx === rates.length - 1 ? '0 20px 20px 0' : '0'} />
              </Tooltip>
            )
          })
        ) : (
          <Tooltip
            bgColor={colors.white}
            containerProps={{
              width: '100%',
              backgroundColor: 'transparent',
              borderRadius: '20px',
            }}
            title={<CardSurveyEmoji bgColor='#fff' label='Sem avaliação' color='grey.300' />}
          >
            <ProgressLine percent='100%' backgroundColor='grey.50' borderRadius='20px' />
          </Tooltip>
        )}
      </Progress>
    </Column>
  )
}

export default ProgressSurvey
