import { Column, Tabs } from 'design-system'

import ContactInfos from './ContactInfos'

const TicketContactDrawer = ({ openDrawer, setOpenDrawer, loading, ticket }) => {
  const tabs = [
    {
      index: 0,
      action: 'close',
      icon: 'ArrowRight',
      type: 'text',
      handler: () => setOpenDrawer(false),
    },
    {
      index: 1,
      icon: 'User',
      content: <ContactInfos ticket={ticket} loading={loading} />,
    },
  ]

  return (
    <Column
      border={openDrawer ? '1px solid #E8E8E8' : 'none'}
      maxWidth={openDrawer ? '320px' : '0'}
      width='100%'
      background='#fff'
      height='100%'
    >
      <Tabs tabs={tabs} defaultTab={1} minHeight='74px' sticky />
    </Column>
  )
}

export default TicketContactDrawer
