import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import PreviewTemplate from 'components/specific/templates/PreviewTemplateCard'
import { Column, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography } from 'design-system'
import { convertMarkdownToHtml, convertHtmlToMarkdown, formatBrokerName, formatButtonsToContainers, formatContainersToButtons, notify } from 'helpers'
import { createTemplate, existBrokerName, showTemplateInfo, updateTemplate } from 'services'

import ModalManageFiles from '../ModalManageFiles'

import BasicInfos from './BasicInfos'
import Footer from './Footer'
import ModelConfig from './ModelConfig'
import { initialValues, languageOptions, templateHeaderTypeDict, templateTypesDict } from './templateOptions'

const ModalCreateTemplate = ({ open, onClose, currentTemplate, editing, dispatch, actionTypes }) => {
  const [currentStep, setCurrentStep] = useState(editing ? 1 : 0)
  const [loading, setLoading] = useState(false)
  const [manageFile, setManageFile] = useState(false)

  const methods = useForm({
    defaultValues: initialValues,
  })

  useEffect(() => {
    currentTemplate && handleGetTemplate(currentTemplate.id)
  }, [currentTemplate])

  const handleGetTemplate = async (id) => {
    try {
      setLoading(true)

      const { data } = await showTemplateInfo(id)

      const formatData = {
        ...data,
        template_type: templateTypesDict[data.template_type],
        language: languageOptions.find((i) => i.value === data.language),
        header: {
          ...data.header,
          header_type: data.header ? templateHeaderTypeDict[data?.template_type] : null,
        },
        containers: data?.buttons?.length > 0 ? formatButtonsToContainers(data?.buttons) : [],
        content_variants: Array.from({ length: currentTemplate.params_amount }, (_, index) => ({ key: `{{${index + 1}}}`, text: '' })),
        content: convertMarkdownToHtml(data.content),
      }

      methods.reset(formatData)
    } catch {
      notify.error('Não foi possível buscar informações da template.')
    } finally {
      setLoading(false)
    }
  }

  const watchValues = methods.watch()

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.content = convertHtmlToMarkdown(values.content)
      values.tags = values?.tags?.map((t) => t.id)
      values.connection = values.connection.id
      values.language = values.language.value
      values.template_type = values?.header?.header_type?.category
      values.broker_name = formatBrokerName(values?.name)
      values.buttons = formatContainersToButtons(values?.containers)
      values.header = {
        ...values.header,
        default_media: values?.header?.default_media?.uuid,
        header_type: values?.header?.header_type?.value,
      }

      const { data } = editing ? await updateTemplate(currentTemplate.id, values) : await createTemplate(values)
      dispatch({ type: editing ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM, payload: data })
      notify.success('Template criado com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível criar template. Verifique as informações.')
    } finally {
      setLoading(false)
    }
  }

  const checkExistBrokerName = async () => {
    try {
      if (editing) {
        setCurrentStep(1)
        return true
      }

      setLoading(true)
      const { data } = await existBrokerName(formatBrokerName(watchValues.name))
      if (data?.exists) {
        methods.setError('name', { message: 'Você já possui um template com este nome' })
        return true
      }
      setCurrentStep(1)
    } catch {
      notify.error('Erro ao verificar nome do broker. Verifique as informações.')
    } finally {
      setLoading(false)
    }
  }

  const handleChangePreviousStep = () => setCurrentStep((prev) => prev - 1)

  useEffect(() => {
    return () => methods.reset(initialValues)
  }, [])

  return (
    <Modal size='xl' open={open}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader onClose={onClose}>
            <Typography fontSize='24px' fontWeight='600' color='primary'>
              {`${editing ? 'Editar' : 'Novo'} modelo de mensagem | Templates`}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Row gap='16px'>
              {loading ? (
                <Column width='100%' justifyContent='center'>
                  <Spinner />
                </Column>
              ) : (
                <Column width='100%' maxHeight='700px' overflow='auto'>
                  {currentStep === 0 && !loading && <BasicInfos editing={editing} />}
                  {currentStep === 1 && !loading && <ModelConfig setManageFile={setManageFile} />}
                </Column>
              )}
              <PreviewTemplate key='template' template={{ ...watchValues, buttons: formatContainersToButtons(watchValues?.containers) }} />
            </Row>
            {loading && (
              <Row p='8px 18px' alignItems='center' justifyContent='flex-end' gap='16px'>
                <Typography>Aguarde...</Typography>
                <Column>
                  <Spinner size='sm' />
                </Column>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Footer loading={loading} currentStep={currentStep} next={checkExistBrokerName} previous={handleChangePreviousStep} />
          </ModalFooter>
        </Form>
      </FormProvider>
      {manageFile && (
        <ModalManageFiles
          open={manageFile}
          onClose={() => setManageFile(null)}
          categoryFilter={watchValues.header.header_type.media}
          setFieldValue={(file) => methods.setValue('header.default_media', file)}
        />
      )}
    </Modal>
  )
}

export default ModalCreateTemplate
