import { useEffect, useRef, useState } from 'react'

import ModalCreateNewTicket from 'components/modals/ModalCreateNewTicket'
import PopoverMessageContact from 'components/popovers/PopoverMessageContact'
import PopoverNewContact from 'components/popovers/PopoverNewContact'
import { Avatar, Button, Column, Icon, Row, Typography } from 'design-system'
import { useClickout, useTickets } from 'hooks'

const Contact = ({ contacts }) => {
  const hasMoreContact = contacts.length > 1
  const firstNameContact = contacts[0]?.first_name || contacts[0]?.phones[0]?.phone
  const lastNameContact = contacts[0]?.last_name || ''

  return (
    <Column>
      <Row p='8px' alignItems='center' gap='8px' justifyContent='space-between'>
        <Row alignItems='center' gap='8px'>
          <Avatar square borderRadius='4px' alt={firstNameContact} />
          {hasMoreContact ? (
            <Typography fontWeight='600' color='grey.400'>{`${firstNameContact} e outros ${contacts?.length} contatos`}</Typography>
          ) : (
            <Typography fontWeight='600' color='grey.400'>{`${firstNameContact} ${lastNameContact}`}</Typography>
          )}
        </Row>
      </Row>
    </Column>
  )
}

export const CustomContactActionButton = ({ contacts, containerMessageRef }) => {
  const [newTicketModal, setNewTicketModal] = useState(null)

  const containerRef = useRef(null)

  const { dispatch, actionTicketTypes, actionCurrentStatusTypes } = useTickets()

  const {
    triggerRef: triggerMany,
    elementRef: elementMany,
    openedState: manyContactsState,
    setOpenedState: manyContactsSetState,
  } = useClickout(false)

  const {
    triggerRef: triggerSingle,
    elementRef: elementSingle,
    openedState: singleContactsState,
    setOpenedState: singleContactsSetState,
  } = useClickout(false)

  const hasMoreContact = contacts?.length > 1

  const container = containerMessageRef?.current

  useEffect(() => {
    const handleScroll = () => {
      if (singleContactsState) singleContactsSetState(false)

      if (manyContactsState) manyContactsSetState(false)
    }
    if ((singleContactsState && singleContactsSetState) || (manyContactsState && manyContactsSetState)) {
      container?.addEventListener('scroll', handleScroll)
    }

    return () => container?.removeEventListener('scroll', handleScroll)
  }, [container, manyContactsSetState, manyContactsState, singleContactsState, singleContactsSetState])

  return (
    <Row ref={containerRef} mt='8px' position='relative' borderTop='1px solid #fff' justifyContent='center'>
      {hasMoreContact ? (
        <Button ref={triggerMany} variant='text' color='secondary'>
          Ver todos
        </Button>
      ) : (
        <Button ref={triggerSingle} variant='text' color='secondary'>
          <Icon icon='Plus' />
          Salvar contato
        </Button>
      )}
      {manyContactsState && (
        <PopoverMessageContact
          contacts={contacts}
          elementRef={elementMany}
          open={manyContactsState}
          setNewTicketModal={setNewTicketModal}
          customPosition={{ top: -100, left: 150 }}
          parentRef={containerRef}
          withPortal
        />
      )}
      {singleContactsState && (
        <PopoverNewContact
          width='360px'
          setNewTicketModal={setNewTicketModal}
          open={singleContactsState}
          contact={contacts[0]}
          setOpenedState={singleContactsSetState}
          elementRef={elementSingle}
          parentRef={containerRef}
          customPosition={{ top: -100, left: 150 }}
          withPortal
        />
      )}

      {newTicketModal && (
        <ModalCreateNewTicket
          info={newTicketModal}
          dispatch={dispatch}
          actionTypes={actionTicketTypes}
          actionCurrentStatusTypes={actionCurrentStatusTypes}
          onClose={() => setNewTicketModal(null)}
        />
      )}
    </Row>
  )
}

export default Contact
