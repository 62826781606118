import { AxiosResponse } from 'axios';

import { clientApi } from 'providers';

import { PaginatedResponse } from '../../types/generic.types';

import { QuickAnswer, QuickResponseListParams } from './type';

export const showQuickAnswersInfo = (id: number): Promise<AxiosResponse<QuickAnswer>> =>
  clientApi.get(`/quick-answers/${id}/`);

export const showQuickAnswersList = (params: QuickResponseListParams): Promise<AxiosResponse<PaginatedResponse<QuickAnswer>>> =>
  clientApi.get('/quick-answers/', { params });

export const createQuickAnswer = (data: Partial<QuickAnswer>): Promise<AxiosResponse<QuickAnswer>> =>
  clientApi.post('/quick-answers/', data);

export const updatedQuickAnswer = (id: number, data: Partial<QuickAnswer>): Promise<AxiosResponse<QuickAnswer>> =>
  clientApi.put(`/quick-answers/${id}/`, data);

export const deleteQuickAnswer = (id: number): Promise<AxiosResponse<void>> =>
  clientApi.delete(`/quick-answers/${id}/`);
