import { useEffect, useState } from 'react'

import { Column, Card, ConfigSelect, ConfigSwitch, Row, Typography, CopyBoard } from 'design-system'
import { notify, withAuthorization } from 'helpers'
import { useAuth } from 'hooks'
import { updateCompanyInfo, showTimezonesList } from 'services'

const GeneralPreferences = () => {
  const [timeszones, setTimeszones] = useState([])

  const {
    userData,
    userData: {
      company_user: { company },
    },
    updateLoggedUser,
    userActionTypes,
  } = useAuth()

  useEffect(() => {
    handleGetTimezones()
  }, [])

  const handleGetTimezones = async () => {
    try {
      const { data } = await showTimezonesList({ page_size: 1000 })

      setTimeszones(data)
    } catch {
      notify.error('Não possível resgatar informações de timezones.')
    }
  }

  const handlerChangeSettings = async (target) => {
    try {
      const { data } = await updateCompanyInfo(company?.id, {
        settings: {
          [target.name]: target.value,
        },
      })

      updateLoggedUser({
        type: userActionTypes.UPDATE_ITEM,
        payload: { ...userData, company_user: { ...userData?.company_user, company: { ...data } } },
      })

      notify.success('Preferências salvas com sucesso!')
    } catch {
      notify.error('Não foi possível salvar configuração.')
    }
  }

  return (
    <Column>
      <Row m='4px 0' width='100%' justifyContent='flex-end' gap='16px'>
        <Row gap='8px'>
          <Column>
            <CopyBoard title='Copiar identificador' content={company?.uuid} color='primary' />
            <Typography mt='4px' textAlign='center' fontSize='8px' color='grey.100'>
              {company.uuid}
            </Typography>
          </Column>
        </Row>
      </Row>
      <Card>
        <Column gap='8px'>
          <ConfigSwitch
            name='force_working_schedule'
            checked={company.settings?.force_working_schedule}
            onChange={(e) =>
              handlerChangeSettings({ name: e.target?.name, value: e?.target?.checked })
            }
            label='Restringir horário de expediente'
            description='Você pode configurar o sistema para impedir o acesso do usuário à
       conta fora do horário de expediente definido nas configurações dele.'
          />
          <ConfigSelect
            label='Timezone'
            name='timezone'
            options={timeszones}
            defaultValue={{ label: company?.settings?.timezone, value: company.settings.timeszone }}
            onChange={(e) => handlerChangeSettings({ name: 'timezone', value: e.value })}
            description='Você pode configurar o sistema para um fuso horário diferente. Essa configuração afetará todos os usuários da empresa.'
          />
        </Column>
      </Card>
    </Column>
  )
}

export default withAuthorization(GeneralPreferences, ['manage_company'])
