import { useMemo } from 'react'

import { CardSurveyEmoji, CardAverage, SkeletonSurveyEmoji } from 'components'
import { dashboardSurveyDict } from 'configs'
import { Card, Row, Typography } from 'design-system'
import { ratingRangeReduce } from 'helpers'

import TableSurvey from './TableSurvey'

const SatisfactionSurvey = ({ ratings, loadingDash }) => {
  const goodOrGreat = useMemo(() => ratingRangeReduce(ratings.rates, [4, 5]), [ratings])
  return (
    <Card mt='16px'>
      <Typography fontSize='16px' color='primary' fontWeight='600'>
        Pesquisa de satisfação
      </Typography>
      <Row
        gap='16px'
        mt='12px'
        justifyContent={['center', 'center', 'center', 'space-between']}
        alignItems={['center', 'center', 'center', 'space-between']}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Row gap='16px'>
          <CardAverage title='Total de respostas' value={ratings?.rated_amount} loading={loadingDash} />
          <CardAverage title='Bom ou ótimo' value={`${goodOrGreat}%`} loading={loadingDash} />
        </Row>
        <Row gap='16px' flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}>
          {loadingDash ? (
            <SkeletonSurveyEmoji />
          ) : (
            <>
              {ratings?.rates?.length > 0 && (
                <CardSurveyEmoji label={`Sem resposta`} percent={`${ratings?.unrated_percent}%`} value={ratings?.unrated_amount} />
              )}
              {ratings?.rates?.map(({ rate, amount, percent }, idx) => {
                const { emoji, label, color } = dashboardSurveyDict[rate.value]
                return <CardSurveyEmoji key={`${idx}_rate`} emoji={emoji} label={label} color={color} percent={`${percent}%`} value={amount} />
              })}
            </>
          )}
        </Row>
      </Row>
      <TableSurvey users={ratings?.users} />
    </Card>
  )
}

export default SatisfactionSurvey
