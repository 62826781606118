import { useState } from 'react'

import { defaultColorsTags } from 'configs'
import { AccordionBadges, Badge, Column, Row, Typography } from 'design-system'
import { getRandomColor, notify } from 'helpers'
import { useTickets } from 'hooks'
import { createTag, showTagsList, updatedContact } from 'services'

const ContactTags = ({ contact }) => {
  const { state, dispatch, actionSelectedTicketTypes } = useTickets()
  const { tags } = contact || {}
  const [loading, setLoading] = useState(false)

  const handleChange = async (tag, action) => {
    setLoading(true)
    try {
      let updatedTags = [...tags]

      if (action === 'remove') {
        updatedTags = updatedTags.filter((t) => t.id !== tag.id)
      } else if (!contact?.tags.some((t) => t.id === tag.id)) {
        updatedTags.push(tag)
      }

      await updatedContact(contact?.id, { tags: updatedTags.map((t) => t.id) })

      dispatch({
        type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET,
        payload: {
          contact: { ...state?.selected_ticket?.contact, tags: updatedTags },
        },
      })

      notify.success('Contato alterado com sucesso.')
    } catch {
      notify.error('Não foi possível alterar etiqueta do contato.')
    } finally {
      setLoading(false)
    }
  }

  const handleCreateAndUpdateContact = async (name) => {
    setLoading(true)
    try {
      const { data } = await createTag({
        name,
        color: getRandomColor(defaultColorsTags),
      })

      await handleChange(data)
    } catch (err) {
      notify.error(err?.response?.data?.name[0] || 'Não foi possível realizar ação no momento.')
    } finally {
      setLoading(false)
    }
  }

  const getTagsOptions = async (search) => {
    const response = await showTagsList({ page_size: 100, name__icontains: search })
    return response.data.results
      .filter((tag) => !tags.some((t) => t.id === tag.id))
      .map((tag) => ({
        ...tag,
        label: tag?.name,
        value: tag?.id,
      }))
  }

  return (
    <AccordionBadges
      mt='16px'
      title='Etiquetas'
      items={tags}
      maxItems={2}
      loading={loading}
      permissions={['manage_contacts']}
      badgesAction={handleChange}
      createAction={handleCreateAndUpdateContact}
      loadOptions={getTagsOptions}
    >
      <Row flexWrap='wrap' gap='8px'>
        {tags?.length > 0 ? (
          tags.map((tag) => (
            <Badge
              key={tag.id}
              action={() => handleChange(tag, 'remove')}
              color={tag.color}
              backgroundColor={tag.color}
              fontStyle={{ fontSize: '12px' }}
              title={tag.name}
            />
          ))
        ) : (
          <Column width='100%' p='18px' alignItems='center'>
            <Typography textAlign='center'>Contato não possui etiquetas</Typography>
          </Column>
        )}
      </Row>
    </AccordionBadges>
  )
}

export default ContactTags
