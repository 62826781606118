import Template from 'components/specific/tickets/TicketMessage/Template'
import { Modal, ModalBody, ModalHeader, Button, Typography, ModalFooter, Card } from 'design-system'

const ModalSendSurvey = ({ open, onClose, loading, callBack, ticketId, params, ...props }) => {
  const message = {
    body: {
      text: '*Queremos saber como foi sua experiência!*\nClique abaixo e escolha a opção que melhor descreve nosso atendimento.',
    },
    buttons: [{ button_type: 'QUICK_REPLY', text: 'Responder' }],
  }
  return (
    <Modal size='sm' open={open} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Pesquisa de satisfação
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography fontSize='18px' fontWeight='600' color='grey.400'>
          Deseja enviar uma pesquisa de satisfação para o cliente?
        </Typography>
        <Typography mt='8px' color='grey.400'>
          Esta pesquisa ajuda a entender a qualidade do atendimento prestado e melhorar a experiência dos nossos clientes.
        </Typography>
        <Card mt='8px' backgroundColor='grey.25'>
          <Template message={message} backgroundColor='violet.100' noHeader />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button width='fit-content' variant='text' color='text' onClick={() => callBack(ticketId, { ...params, send_satisfaction_survey: false })}>
          Não enviar pesquisa
        </Button>
        <Button width='fit-content' onClick={() => callBack(ticketId, { ...params, send_satisfaction_survey: true })} disabled={loading}>
          Enviar pesquisa
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalSendSurvey
