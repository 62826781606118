import React, { forwardRef, memo } from 'react'
import styled from 'styled-components'
import { space, layout, typography, color, compose } from 'styled-system'


import { theme } from 'configs'
import Icon from 'design-system/Icon'
import Row from 'design-system/Row'
import Tooltip from 'design-system/Tooltip'

import Column from '../Column'
import Spinner from '../Spinner'

import Empty from './Empty'
import Pagination from './Pagination'

const styles = compose(space, layout, typography, color)

const TableWrapper = styled(Column)`
  max-height: 550px;
  height: 100%;
  overflow: auto;
  z-index: 1;
  position: relative;
`

const Base = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  table-layout: auto;
  overflow: auto;
  height: 100%;
  max-height: 450px;

  tbody > :nth-child(even) {
    background-color: ${theme.colors.grey[30]};
  }

  ${styles};
`

const TableRow = styled.tr`
  border-radius: 5px;
  font-size: 14px;
  color: ${theme.colors.text};

  &:hover {
    background-color: ${theme.colors.grey[30]};
  }

  ${styles};
`
const TableHeader = styled.tr`
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.grey[400]};
  background-color: ${theme.colors.grey[30]};
  position: sticky;
  top: -1px;
  z-index: 1;
  ${styles};
`
const TableHeaderCell = styled.td`
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  padding: 16px;
  ${styles};
`

const TableCell = styled.td`
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  padding: 16px;
  color: ${theme.colors.grey[300]};
  ${styles};
`

const Table = forwardRef(({ data, columns, pagination, setPagination, emptyMessage, handleEmptyData, title, loading, ...rest }, ref) => {
  if (loading)
    return (
      <Column height='550px' alignItems='center' justifyContent='center'>
        <Spinner size='lg' />
      </Column>
    )

  return (
    <Column width='100%'>
      {data.length > 0 ? (
        <TableWrapper>
          <Base ref={ref} {...rest}>
            <thead>
              <TableHeader>
                {columns.map((column, index) => (
                  <TableHeaderCell key={index} {...column.headerProps}>
                    <Row gap='4px' alignItems='center'>
                      {column.header}
                      {column?.helper && (
                        <Tooltip width='300px' title={column?.helper}>
                          <Icon icon='Question' size='sm' />
                        </Tooltip>
                      )}
                    </Row>
                  </TableHeaderCell>
                ))}
              </TableHeader>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex} {...column.cellProps}>
                      {column.render ? column.render(row) : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </Base>
        </TableWrapper>
      ) : (
        <Empty title={title} emptyMessage={emptyMessage} handleEmptyData={handleEmptyData} />
      )}
      {data.length > 0 && <Pagination pagination={pagination} setPagination={setPagination} />}
    </Column>
  )
})

Table.defaultProps = {
  pagination: {
    totalPages: 0,
    currentPage: 1,
  },
  data: [],
  column: [],
  emptyMessage: '',
  handleEmptyData: null,
}

export default memo(Table)
