import { forwardRef } from 'react'

import { theme } from 'configs'

import Column from '../Column'

const Card = forwardRef(({ children, ...rest }, ref) => (
  <Column ref={ref} p='24px' backgroundColor='#fff' borderRadius='8px' border={`1px solid ${theme.colors.grey[50]}`} {...rest}>
    {children}
  </Column>
))

export default Card
