const formatTextOverflow = (text, startLength, endLength) => {
  if (text.length <= startLength + endLength) return text

  const start = text.slice(0, startLength)
  const end = text.slice(-endLength)

  return `${start}...${end}`
}

export default formatTextOverflow
