import styled from 'styled-components'
import { space, layout, color, compose } from 'styled-system'

import { spinnerSizes } from 'configs'
import Column from 'design-system/Column'

const style = compose(space, layout, color)

const SpinnerBase = styled.div`
  position: absolute;
  border-radius: 50%;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(39, 174, 96, 0) 0deg,
    rgba(31, 144, 255, 0) 0.04deg,
    #5e54a9 360deg
  );
  transform: translate(-50%, -50%);
  animation: 1s spin infinite linear;
  height: ${({ size = 'md' }) => `calc(56px * ${spinnerSizes[size]})`};
  width: ${({ size = 'md' }) => `calc(56px * ${spinnerSizes[size]})`};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    height: ${({ size = 'md' }) => `calc(40px * ${spinnerSizes[size]})`};
    width: ${({ size = 'md' }) => `calc(40px * ${spinnerSizes[size]})`};
    background: ${({ background }) => background};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    background: #5e54a9;
    transform: translateY(-50%);
    height: ${({ size = 'md' }) => `calc(8px * ${spinnerSizes[size]})`};
    width: ${({ size = 'md' }) => `calc(8px * ${spinnerSizes[size]})`};
    border-radius: ${({ size }) => `calc(8px * ${spinnerSizes[size]})`};
  }
  ${style}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Spinner = ({ size, ...rest }) => {
  return (
    <Column
      position='relative'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height={`${56 * Number(spinnerSizes[size])}px`}
    >
      <SpinnerBase size={size} {...rest} />
    </Column>
  )
}

Spinner.defaultProps = {
  size: 'md',
  background: '#fff',
}

export default Spinner
