import styled from 'styled-components'

import { weekDays } from 'configs'
import { hexToRGBA } from 'helpers'

import Column from '../Column'
import Row from '../Row'
import Tooltip from '../Tooltip'
import Typography from '../Typography'


const DaySelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 4px;
  cursor: pointer;
  border: ${({ theme: { colors }, active }) =>
    `1px solid ${active ? colors.primary : colors.grey[700]}`};
  background-color: ${({ theme: { colors }, active }) =>
    active ? hexToRGBA(colors.primary, 1) : '#fff'};
`

const WeekDaysSelector = ({ working_schedules, ...props }) => {
  return (
    <Column {...props}>
      <Row gap='8px'>
        {weekDays.map((day) => {
          const dayActive = working_schedules?.find((d) => d?.weekday === day?.weekday)
          return dayActive ? (
            <Tooltip
              key={day.value}
              minWidth='130px'
              title={
                dayActive?.start && dayActive?.end ? `${dayActive.start}h às ${dayActive.end}h` : ''
              }
            >
              <DaySelector key={day.value} active={dayActive}>
                <Typography
                  cursor='pointer'
                  color={dayActive ? 'white' : 'grey.500'}
                  fontSize='14px'
                  fontWeight='bold'
                >
                  {day?.label}
                </Typography>
              </DaySelector>
            </Tooltip>
          ) : (
            <DaySelector key={day.value} active={dayActive}>
              <Typography
                cursor='pointer'
                color={dayActive ? 'white' : 'grey.500'}
                fontSize='14px'
                fontWeight='bold'
              >
                {day?.label}
              </Typography>
            </DaySelector>
          )
        })}
      </Row>
    </Column>
  )
}

WeekDaysSelector.defaultProps = {
  active: false,
}

export default WeekDaysSelector
