import { Card, Column, Row, Typography } from 'design-system'
import { notify } from 'helpers'
import { deleteFolder, updatedFolder } from 'services'

import { Folder } from '../components'


const FoldersDirectory = ({ folders, setFolders, setModalConfirmation, setSelectedFolder, connection, category }) => {
  const defaultFolders = [
    { name: 'Recentes', key: 'recent', icon: 'FolderRecent', id: null, filters: { ordering: '-last_used_at', connection, category } },
    { name: 'Geral', key: 'general', icon: 'Folder', id: null, filters: { folder__isnull: true, connection, category } },
  ]

  const handleDeleteFolder = async (value) => {
    try {
      const { data } = await deleteFolder(value)

      if (data.usages.length === 0) {
        setFolders((prevState) => prevState.filter((f) => f.id !== value))
        setModalConfirmation(null)
        notify.success(`Pasta excluída com sucesso. `)
      } else {
        setModalConfirmation((prevState) => ({
          ...prevState,
          text: `Pasta não pode ser deletada, pois ${data.usages.length} sua(s) mídia(s) está(ão) em uso no GestãoDS. Total de ${data.medias_deleted} mídias foram excluídas com sucesso da pasta.`,
          action: null,
          cancelActionLabel: 'Fechar',
          content: (
            <Column mt='16px' gap='8px' width='100%'>
              {data?.usages.map(({ table, field, flux, user, client }) => (
                <Card p='8px' width='100%'>
                  <Typography fontWeight='600'>{table}</Typography>
                  <Column p='4px 24px' as='ul'>
                    {field && <Typography as='li'>Configuração: {field}</Typography>}
                    {flux && <Typography as='li'>Fluxo: {flux}</Typography>}
                    {user && <Typography as='li'>Usuário: {user}</Typography>}
                    {client && <Typography as='li'>Clínica: {client}</Typography>}
                  </Column>
                </Card>
              ))}
            </Column>
          ),
        }))
        notify.success(`Arquivos excluídos com sucesso. `)
      }
    } catch {
      notify.error('Não foi possível deletar pasta.')
    }
  }

  const handleRenameFolder = async ({ id, name }, callBack) => {
    try {
      const { data } = await updatedFolder(id, { name })

      setFolders((prevFolders) => prevFolders.map((folder) => (folder.id === data.id ? { ...folder, name: data.name } : folder)))

      callBack(null)

      notify.success('Nome atualizado com sucesso.')
    } catch (err) {
      if (err?.response?.data?.name?.[0]) return notify.error(err?.response?.data?.name?.[0])

      notify.error('Não foi possível renomear a pasta no momento.')
    }
  }

  return (
    <Column>
      <Column gap='8px'>
        <Typography fontSize='14px' fontWeight='600'>
          Arquivos Rápidos
        </Typography>
        <Row gap='16px'>
          {defaultFolders.map((folder) => (
            <Folder key={folder.id} noSubmenu onClick={setSelectedFolder} folder={folder} />
          ))}
        </Row>
      </Column>
      <Column mt='16px' gap='8px'>
        {folders.length > 0 && (
          <Typography fontSize='14px' fontWeight='600'>
            Minha Biblioteca
          </Typography>
        )}
        <Row gap='16px' flexWrap='wrap'>
          {folders.map((folder) => (
            <Folder
              key={folder.id}
              deleteAction={handleDeleteFolder}
              renameFolder={handleRenameFolder}
              setModalConfirmation={setModalConfirmation}
              folder={folder}
              onClick={setSelectedFolder}
            />
          ))}
        </Row>
      </Column>
    </Column>
  )
}

export default FoldersDirectory
