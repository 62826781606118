import { useState } from 'react'

import { fileInfoThumb } from 'assets'
import ModalExpandImage from 'components/modals/ModalExpandImage'
import { buttonsTemplateDict } from 'configs'
import { Column, Icon, Image, Row, SanitizeDiv, Typography } from 'design-system'
import { handleLetterHighlights } from 'utils'

import headerComponentDict from './templateTypeDict'

const Template = ({ message, search, messageIdSearch, noHeader, ...props }) => {
  const [expand, setExpand] = useState(null)

  const { body, header, buttons, footer } = message || {}

  const handleFormat = (textParams) => {
    if (search && textParams && messageIdSearch) {
      return (
        <Row>
          <Typography>{handleLetterHighlights({ textParams, search }) || textParams}</Typography>
        </Row>
      )
    }
    return (
      <Row>
        <Typography>{textParams}</Typography>
      </Row>
    )
  }

  return (
    <Column p='4px 8px' background='transparent' borderRadius={buttons?.length > 0 ? '8px 8px 0 0' : '8px'} {...props}>
      {!noHeader && header && (
        <Row>
          {message?.template_type && headerComponentDict?.[message?.template_type]
            ? headerComponentDict[message?.template_type](header, setExpand)
            : message?.template_type && (
                <Row p='16px 0' gap='8px' width='100%' justifyContent='center' alignItems='center'>
                  <Image src={fileInfoThumb} />
                  <Typography>Não foi possível visualizar a mídia.</Typography>
                </Row>
              )}
        </Row>
      )}
      {body?.text && <SanitizeDiv id={messageIdSearch} content={body.text} search={search} />}
      {footer?.text && (
        <Row mt='16px' width='100%' justifyContent='flex-end'>
          <Typography>{handleFormat(footer?.text)}</Typography>
        </Row>
      )}
      {buttons?.length > 0 && (
        <Column pb='16px' backgroundColor='transparent'>
          {buttons?.map((button, idx) => (
            <Row key={idx} gap='4px' justifyContent='center' background='transparent'>
              <Row
                width='90%'
                boxShadow='0px 2px 0px 0px #0000000D'
                background='#fff'
                borderRadius='8px'
                justifyContent='center'
                alignItems='center'
                mt='4px'
                p='8px'
                gap='2px'
              >
                <Icon icon={buttonsTemplateDict[button?.button_type]?.icon} color='grey.300' />
                <Typography>{button?.text}</Typography>
              </Row>
            </Row>
          ))}
        </Column>
      )}
      {expand && <ModalExpandImage closeClickOut open={expand} clo onClose={() => setExpand(null)} data={expand} />}
    </Column>
  )
}

export default Template
