import { useEffect, useRef, useState } from 'react'

import { Icon, Row, Tooltip } from 'design-system'

import Ready from './Ready'
import Recording from './Recording'

const AudioMessage = ({ setVoiceMessage, onSubmit, loading }) => {
  const [audioUrl, setAudioUrl] = useState(null)
  const [isRecording, setIsRecording] = useState(true)
  const [stream, setStream] = useState(null)
  const [currentTime, setCurrentTime] = useState(0)

  const amplitudeRef = useRef(new Array(20).fill(0))
  const mediaRecorderRef = useRef(null)

  const deleteAndCancelAudio = () => {
    amplitudeRef.current = null
    mediaRecorderRef.current.cancelar = true
    mediaRecorderRef.current.stop()
    setAudioUrl(null)
    setVoiceMessage(false)
  }

  useEffect(() => {
    const handleStartStream = async () => {
      if (!stream) {
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
          setStream(audioStream)
        } catch (error) {
          console.error('Erro ao acessar o microfone:', error)
        }
      }
    }

    if (isRecording) {
      handleStartStream()
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [stream, isRecording])

  return (
    <Row width='100%' alignItems='center' justifyContent='flex-end' gap='4px'>
      <Tooltip position='top' title='Cancelar' width='120px'>
        <Icon icon='Trash' color='grey.300' onClick={deleteAndCancelAudio} cursor='pointer' />
      </Tooltip>
      {isRecording ? (
        <Recording
          amplitudeRef={amplitudeRef}
          setAudioUrl={setAudioUrl}
          setIsRecording={setIsRecording}
          stream={stream}
          mediaRecorderRef={mediaRecorderRef}
          setVoiceMessage={setVoiceMessage}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
        />
      ) : (
        <Ready
          amplitudeRef={amplitudeRef}
          audioUrl={audioUrl}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
        />
      )}
      <Tooltip position='top' title='Enviar áudio' width='120px'>
        <Icon
          disabled={loading}
          icon='SendButton'
          onClick={isRecording ? () => undefined : onSubmit}
          color={isRecording ? 'grey.50' : 'grey.300'}
          direction='right'
          cursor='pointer'
        />
      </Tooltip>
    </Row>
  )
}

export default AudioMessage
