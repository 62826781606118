import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import ProtectedComponent from 'components/common/ProtectedComponent'
import TagsList from 'components/common/TagsList'
import { countryCodes } from 'configs'
import { Button, Column, Form, Icon, Input, Popover, Row, Select, Typography } from 'design-system'
import { normalizePhoneNumber, notify } from 'helpers'
import { createContact, showContactsList, updatedContact } from 'services'
import { removeMask } from 'utils'

const PopoverNewContact = ({ elementRef, open, contact, updateField, setOpenedState, setNewTicketModal, onlySaveContact, ...props }) => {
  const [loading, setLoading] = useState(false)

  const contactExists = useRef(null)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = useForm()

  const getContact = useCallback(
    async (values) => {
      try {
        setLoading(true)
        contactExists.current = null

        const { first_name, last_name, phones } = values

        const { phone, country_code } = normalizePhoneNumber(phones[0]?.phone)

        const { data } = phone
          ? await showContactsList({
              page_size: 1,
              phone,
            })
          : {}

        if (data?.results.length > 0) contactExists.current = true

        const contactData = data?.results[0] || {
          name: first_name ? `${first_name || ''} ${last_name || ''}` : '',
          country_code,
          dial_code: phone,
        }
        contactData.country_code = countryCodes.find((c) => c.code === country_code)

        reset(contactData)
      } catch {
        notify.error('Não foi possível verificar se o contato já existe.')
      } finally {
        setLoading(false)
      }
    },
    [reset],
  )

  useEffect(() => {
    if (contact) {
      getContact(contact)
    }
  }, [contact, getContact])

  const onSubmit = async (values, action) => {
    try {
      setLoading(true)

      values.tags = values?.tags?.map((t) => t.id) || []
      values.country_code = values.country_code.code
      values.dial_code = removeMask(values.dial_code)
      values.extra_fields = []

      const { data } = values?.id ? await updatedContact(values?.id, values) : await createContact(values)

      updateField && updateField(data)

      if (action === 'open_ticket') {
        return setNewTicketModal(data)
      }

      setOpenedState(null)
      notify.success('Contato criado com sucesso!')
    } catch {
      notify.error('Não foi possível criar novo contato')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popover ref={elementRef} open={open} {...props}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Column p='16px'>
          <Typography fontWeight='bold'>Cadastro rápido de contato</Typography>
          <Typography mt='8px'>Essas são as informações básicas do contato, que podem ser editadas a qualquer momento.</Typography>

          <Column mt='8px' gap='8px'>
            <Input
              label='Nome'
              placeholder='Nome do contato'
              error={errors?.name}
              disabled={loading}
              loading={loading}
              required
              {...register('name', { required: true })}
            />
            <Row gap='16px'>
              <Select
                maxWidth='90px'
                label='Código do país'
                options={countryCodes}
                name='country_code'
                defaultValue={{ label: 'Brasil +55', code: '55' }}
                control={control}
                keys={{ label: 'label', value: 'code' }}
                loading={loading}
                menuPortalTarget={false}
              />
              <Input
                label='Telefone'
                name='dial_code'
                error={errors?.dial_code}
                errorMessage={errors?.dial_code?.message}
                loading={loading}
                {...register('dial_code', {
                  required: true,
                  maxLength: {
                    value: 11,
                    message: 'Certifique-se de que este campo não tenha mais de 11 caracteres.',
                  },
                })}
                required
              />
            </Row>
            <Input disabled={loading} loading={loading} label='E-mail' placeholder='nome@gmail.com' error={errors?.email} {...register('email')} />
            <ProtectedComponent allowedRoles={['manage_contacts']} unauthorizedComponent={null}>
              <TagsList setValue={setValue} name='tags' tags={getValues('tags')} />
            </ProtectedComponent>
            {contactExists?.current && (
              <Row alignItems='center' gap='4px'>
                <Icon icon='Alert' color='danger' />
                <Typography variant='caption' color='danger'>
                  Você já possui esse contato salvo
                </Typography>
              </Row>
            )}
            <Row alignItems='center' justifyContent='flex-end' mt='16px' gap='8px'>
              <Button
                type='submit'
                variant={onlySaveContact ? 'filled' : 'text'}
                color={onlySaveContact ? 'primary' : 'grey.500'}
                onClick={handleSubmit((data) => onSubmit(data))}
                disabled={loading}
              >
                Salvar contato
              </Button>
              {!onlySaveContact && (
                <Button type='submit' onClick={handleSubmit((data) => onSubmit(data, 'open_ticket'))} disabled={loading}>
                  Salvar e iniciar conversa
                </Button>
              )}
            </Row>
          </Column>
        </Column>
      </Form>
    </Popover>
  )
}

export default PopoverNewContact
