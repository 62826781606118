import React, { useEffect, useState, forwardRef } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import { colors } from 'configs'

const WaveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: fit-content;
  gap: 1.5px;
`

const WaveBar = styled.div`
  min-width: 2px;
  background-color: ${colors.grey[100]};

  transition: height 0.1s ease;
  min-height: 1px;
  max-height: 100%;
  height: ${({ height }) => height};
  ${space}
`

const AudioWaveBars = forwardRef(({ staticWave }, ref) => {
  const [bars, setBars] = useState(staticWave || Array(10).fill(0))

  useEffect(() => {
    if (!ref?.current) return

    const updateBars = () => {
      const amplitudes = ref.current
      if (amplitudes) {
        const newBars = amplitudes.map((value) => (value / 255) * 100)
        setBars(newBars)
      }
      requestAnimationFrame(updateBars)
    }

    !staticWave && updateBars()
  }, [ref, staticWave])

  return (
    <WaveContainer>
      {bars.map((height, index) => (
        <WaveBar key={index} height={`${height}%`} />
      ))}
    </WaveContainer>
  )
})

export default AudioWaveBars
