import { useEffect, useState } from 'react'

import { PreviewTemplateCard, ProtectedComponent } from 'components'
import { Alert } from 'components/common'
import { colors, languageDict, templateCategoryDict, templateStatusDict, templateStatusEdit, templateTypeDict } from 'configs'
import { Badge, Button, Card, Column, HighlightedText, Modal, ModalBody, ModalFooter, ModalHeader, Row, Skeleton, Typography } from 'design-system'
import { formatDate, notify } from 'helpers'
import { showTemplateInfo } from 'services'

import { templateHeaderTypeDict } from '../ModalCreateTemplate/templateOptions'

const ModalTemplatePreview = ({ open, onClose, templateId, handler }) => {
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (templateId) {
      getTemplateInfo(templateId)
    }
  }, [templateId])

  const getTemplateInfo = async (id) => {
    try {
      setLoading(true)

      const { data } = await showTemplateInfo(id)

      const formatData = {
        ...data,
        header: {
          ...data.header,
          header_type: templateHeaderTypeDict[data?.template_type],
        },
        alert: templateStatusDict[data.status],
      }
      setPreview(formatData)
    } catch {
      notify.error('Não foi possível resgatar informações do template.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={open} onClose={onClose} closeClickOut>
      <ModalHeader onClose={onClose}>
        <Typography fontWeight='bold' fontSize='24px' color='primary'>
          {`Template: ${loading ? 'Carregando...' : preview?.name}`}
        </Typography>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Column gap='16px'>
            <Column alignItems='flex-end' gap='8px'>
              <Skeleton height='15px' width='140px' />
              <Skeleton height='15px' width='140px' />
            </Column>
            <Skeleton height='40px' width='100%' />
            <Skeleton height='100px' width='100%' />
          </Column>
        ) : (
          <>
            <Column>
              <Column>
                <Typography textAlign='right'>Criado em: {formatDate(preview?.created_at)}</Typography>
                <Typography textAlign='right'>Última atualização: {formatDate(preview?.updated_at)}</Typography>
              </Column>
              <Alert
                mt='16px'
                icon='Information'
                title={preview?.alert?.title}
                text={preview?.alert?.text}
                color={preview?.alert?.statusColor}
                helper={preview?.alert?.rejected_reason}
              />
            </Column>
            <Row mt='16px' p='16px' borderRadius='8px' background={colors.grey[25]}>
              <PreviewTemplateCard p='16px' alignItems='center' hiddenTitle mt='0' template={preview} />
              <Column width='100%' alignItems='flex-end'>
                <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
                  {templateStatusEdit.includes(preview?.status) && preview.template_type === 'TEXT' && (
                    <Button
                      variant='text'
                      iconLeft='Edit'
                      color='secondary'
                      onClick={() => {
                        handler(preview.id)
                        onClose()
                      }}
                    >
                      Editar
                    </Button>
                  )}
                </ProtectedComponent>
                <Card mt='16px' p='8px' width='100%'>
                  <Typography fontSize='16px' fontWeight='600'>
                    Configuração
                  </Typography>
                  <Column mb='8px' height='1px' backgroundColor='grey.50' />
                  <Row justifyContent='space-between'>
                    <HighlightedText hightLight='Conexão' text={preview?.connection?.name} p='2px 0px' border='none' />
                    <Badge
                      noTooltip
                      backgroundColor={templateCategoryDict[preview?.category]?.color}
                      title={templateCategoryDict[preview?.category]?.label || 'Status não identificado'}
                    />
                  </Row>
                  <HighlightedText hightLight='Tipo de template' text={templateTypeDict[preview?.template_type]?.label} p='2px 0px' border='none' />
                  <HighlightedText hightLight='Idioma' text={languageDict[preview?.language]?.label} p='2px 0px' border='none' />
                </Card>
              </Column>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Fechar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalTemplatePreview
