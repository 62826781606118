import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { MAX_CONTENT_LENGTH } from 'components/modals/ModalCreateTemplate/templateOptions'
import { colors } from 'configs'
import { Button, Column, Icon, Input, RichText, Row } from 'design-system'

const TemplateTextVariants = () => {
  const [editorState, setEditorState] = useState(null)

  const {
    register,
    getValues,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  const { fields, append, remove, replace } = useFieldArray({ name: `content_variants`, control })

  const contentWatch = watch('content')

  const insertCurrentFields = () => {
    if (!contentWatch) return

    const keysRegex = /{{(\d+)}}/g
    const matches = [...contentWatch.matchAll(keysRegex)]

    const uniqueKeys = [...new Set(matches.map((match) => match[0]))].sort((a, b) => {
      const numA = parseInt(a.match(/\d+/)[0], 10)
      const numB = parseInt(b.match(/\d+/)[0], 10)
      return numA - numB
    })

    const keyMapping = uniqueKeys.reduce((acc, key, index) => {
      acc[key] = `{{${index + 1}}}`
      return acc
    }, {})

    let updatedContent = contentWatch.replace(keysRegex, (match) => keyMapping[match] || match)

    const keys = Object.values(keyMapping).map((newKey, index) => ({
      key: newKey,
      text: getValues().content_variants[index]?.text || '',
    }))

    replace(keys)
    setValue('content', updatedContent)
  }

  const handleCreateVariant = () => {
    const key = `{{${fields.length + 1}}}`
    editorState.commands.insertContent(key)

    append({ key, text: '' })
  }

  const handleRemoveVariant = (field) => {
    const newContent = contentWatch.replaceAll(field.key, '')
    setValue('content', newContent)
    remove(field.id)
  }

  useEffect(() => {
    insertCurrentFields()
  }, [contentWatch])

  return (
    <Column>
      <Controller
        name='content'
        control={control}
        render={({ field }) => (
          <RichText
            setEditorState={setEditorState}
            limit={MAX_CONTENT_LENGTH}
            error={errors?.content_markdown}
            errorMessage={errors?.content_markdown?.message}
            footerProps={{ variables: false }}
            {...field}
          />
        )}
      />
      <Column mt={fields?.length > 0 ? '16px' : '0'} gap='16px'>
        {fields.map((field, index) => (
          <Row
            p='16px'
            borderRadius='8px'
            key={field.id}
            background={fields?.length > 0 ? colors.grey[25] : colors.white}
            gap='16px'
            alignItems='center'
          >
            <Input maxWidth='94px' label='Variável' {...register(`content_variants.${index}.key`)} disabled />
            <Input maxWidth='200px' tabIndex='1' label='Valor de exemplo' {...register(`content_variants.${index}.text`)} />
            <Row mt='16px' alignItems='center'>
              <Icon icon='Trash' color='danger' onClick={() => handleRemoveVariant(field)} cursor='pointer' />
            </Row>
          </Row>
        ))}
      </Column>
      <Row mt='16px' justifyContent='flex-end'>
        <Button iconLeft='Plus' variant='text' color='secondary' onClick={() => handleCreateVariant(getValues('content'))}>
          Adicionar variável
        </Button>
      </Row>
    </Column>
  )
}

export default TemplateTextVariants
