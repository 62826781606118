import { useReducer } from 'react'

import { compareDates } from 'helpers'
import { filterMessageUniqueId } from 'utils'

export const initialValues = {
  tickets_list: [],
  messages: [],
  selected_ticket: {},
  current_status: 'attending',
  tickets_list_filters: {
    attendant: null,
    solved: null,
  },
  replied_message: null,
  counters: {
    unsolved_tickets: 0,
  },
}

export const actionCurrentStatusTypes = {
  SELECT_STATUS: 'SELECT_STATUS',
}

export const actionTicketTypes = {
  LOAD_TICKETS_LIST: 'LOAD_TICKETS_LIST',
  ADD_TICKET: 'ADD_TICKET',
  UPDATE_TICKET: 'UPDATE_TICKET',
  REMOVE_TICKET: 'REMOVE_TICKET',
  UPDATE_TICKETS_LIST_FILTER: 'UPDATE_TICKETS_LIST_FILTER',
}

export const actionMessagesTypes = {
  LOAD_MESSAGES: 'LOAD_MESSAGES',
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  RESET_MESSAGES: 'RESET_MESSAGES',
}

export const actionSelectedTicketTypes = {
  LOAD_SELECTED_TICKET: 'LOAD_SELECTED_TICKET',
  UPDATE_SELECTED_TICKET: 'UPDATE_SELECTED_TICKET',
}

export const actionRepliedMessage = {
  SET_REPLY: 'SET_REPLY',
  REMOVE_REPLY: 'REMOVE_REPLY',
}

export const actionCountTickets = {
  COUNTERS: 'COUNTERS',
}

const reducer = (state, action) => {
  const key = action?.payload?.key
  const removeTicketFromList = (id) => state?.tickets_list?.filter((ticket) => ticket?.id !== id)
  const filters = state.tickets_list_filters
  const solved_status = action?.payload?.solved_status ? 'solved' : 'not_solved'

  switch (action.type) {
    case actionCurrentStatusTypes.SELECT_STATUS:
      return {
        ...state,
        current_status: action.payload,
      }
    case actionTicketTypes.LOAD_TICKETS_LIST:
      return {
        ...state,
        tickets_list: action.payload.sort((itemA, itemB) => compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at)),
      }
    case actionTicketTypes.UPDATE_TICKETS_LIST_FILTER:
      return {
        ...state,
        tickets_list_filters: { ...state.tickets_list_filters, ...action.payload },
      }
    case actionTicketTypes.ADD_TICKET:
      if (filters?.solved && filters?.solved !== solved_status) return state

      return {
        ...state,
        tickets_list: [...state.tickets_list, action.payload].sort((itemA, itemB) =>
          compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at),
        ),
      }
    case actionTicketTypes.UPDATE_TICKET:
      if (filters?.solved && filters?.solved !== solved_status) return state

      return {
        ...state,
        tickets_list: [...removeTicketFromList(action.payload.id), action.payload].sort((itemA, itemB) =>
          compareDates(itemA?.last_message?.created_at, itemB?.last_message?.created_at),
        ),
      }

    case actionTicketTypes.REMOVE_TICKET:
      return {
        ...state,
        tickets_list: removeTicketFromList(action.payload),
      }
    case actionMessagesTypes.LOAD_MESSAGES:
      return { ...state, messages: action.payload }
    case actionMessagesTypes.ADD_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [key]: filterMessageUniqueId([action.payload, ...(state.messages[key] || [])]),
        },
      }
    case actionMessagesTypes.UPDATE_MESSAGES:
      const messagesForKey = state.messages[key]

      if (messagesForKey) {
        const updatedMessages = state.messages[key]?.find((m) => m?.id === action?.payload?.id)

        if (updatedMessages) {
          updatedMessages.status = updatedMessages?.status?.value > action?.payload?.status?.value ? updatedMessages?.status : action?.payload?.status
        }
      }
      return {
        ...state,
        messages: state.messages,
      }
    case actionMessagesTypes.RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      }
    case actionSelectedTicketTypes.LOAD_SELECTED_TICKET:
      return { ...state, selected_ticket: action.payload }
    case actionSelectedTicketTypes.UPDATE_SELECTED_TICKET:
      return {
        ...state,
        selected_ticket: { ...state.selected_ticket, ...action.payload },
      }
    case actionRepliedMessage.SET_REPLY:
      return {
        ...state,
        replied_message: action.payload,
      }
    case actionRepliedMessage.REMOVE_REPLY:
      return {
        ...state,
        replied_message: null,
      }
    case actionCountTickets.COUNTERS:
      return {
        ...state,
        counters: {
          unsolved_tickets: action.payload.unsolved_tickets,
        },
      }
    default:
      return state
  }
}

export const useTicketReducer = () => useReducer(reducer, initialValues)
