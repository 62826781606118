import Row from 'design-system/Row'
import Typography from 'design-system/Typography'
import { useClickout } from 'hooks'

import Emoji from './Emojis'
import Variables from './Variables'

const Footer = ({ editor, emojis = true, variables = true, limit }) => {
  const { triggerRef: triggerEmojiRef, elementRef: elementEmojiRef, openedState: openedStateEmoji } = useClickout(false)
  const { triggerRef: triggerTextVariablesRef, elementRef: elementTextVariablesRef, openedState: openedStateTextVariables } = useClickout(false)

  const insertText = (text) => {
    if (!editor) return
    editor.chain().focus().insertContent(text).run()
  }

  return (
    <Row gap='8px' justifyContent='space-between' p='8px 0'>
      <Row gap='8px'>
        {emojis && <Emoji trigger={triggerEmojiRef} elementRef={elementEmojiRef} open={openedStateEmoji} onChange={insertText} />}
        {variables && (
          <Variables trigger={triggerTextVariablesRef} elementRef={elementTextVariablesRef} open={openedStateTextVariables} onChange={insertText} />
        )}
      </Row>
      {limit > 0 && <Typography>{`${editor?.getText()?.length} / ${limit}`}</Typography>}
    </Row>
  )
}

export default Footer
