import { imageThumb } from 'assets'
import { colors } from 'configs'
import { AudioPlayer, Image, FilePreview, VideoPlayer, Column, SanitizeDiv } from 'design-system'
import { hexToRGBA } from 'helpers'

import Caption from './Caption'
import Contact from './Contact'
import Location from './Location'
import Template from './Template'

const categoryFiles = {
  audio: ({ file, caption, search, messageIdSearch }) => (
    <Column key={`${caption}_${file}`} minWidth='300px'>
      <AudioPlayer borderRadius='8px' src={file} />
      {caption && <Caption caption={caption} search={search} messageIdSearch={messageIdSearch} />}
    </Column>
  ),
  file: ({ file, caption, file_category, file_name, sender, search, messageIdSearch }) => (
    <Column key={file_name}>
      <Column p='10px' background={sender === 'contact' ? hexToRGBA(colors.grey[100], 0.2) : hexToRGBA(colors.violet[100], 0.7)} borderRadius='8px'>
        <FilePreview preview={file_category.value} fileName={file_name} downloadLink={file} />
      </Column>
      {caption && <Caption caption={caption} search={search} messageIdSearch={messageIdSearch} />}
    </Column>
  ),
  video: ({ file, caption, search, messageIdSearch }) => (
    <>
      <VideoPlayer borderRadius='8px' height='auto' src={file} />
      {caption && <Caption caption={caption} search={search} messageIdSearch={messageIdSearch} />}
    </>
  ),
  sticker: ({ file, caption }) => (
    <>
      <Image width='125px' src={file} alt={caption || 'image'} borderRadius='8px' />
      {caption && <Caption caption={caption} search={search} messageIdSearch={messageIdSearch} />}
    </>
  ),
  image: ({ file, caption, setExpand, search, messageIdSearch }) => (
    <>
      {file ? (
        <Image
          width='166px'
          objectFit='cover'
          height='166px'
          src={file}
          alt={caption || 'image'}
          onClick={() => setExpand({ file, caption })}
          borderRadius='8px'
          cursor='pointer'
          hasThumb
        />
      ) : (
        <Image width='100%' height='129px' src={imageThumb} />
      )}
      {caption && <Caption caption={caption} search={search} messageIdSearch={messageIdSearch} />}
    </>
  ),
}

const messagesTypeDict = {
  text: ({ message, search, messageIdSearch }) => <SanitizeDiv content={message.body.text} search={search} id={messageIdSearch} />,
  list: ({ message, search, messageIdSearch }) => <SanitizeDiv content={message.body.text} search={search} id={messageIdSearch} />,
  file: ({ message, setExpand, search, messageIdSearch }) =>
    message.files.map((file) => categoryFiles[file.file_type.value]({ ...file, setExpand, sender: message.sender_type, search, messageIdSearch })),
  template: ({ message, search, messageIdSearch }) => <Template message={message} search={search} messageIdSearch={messageIdSearch} />,
  location: ({
    message: {
      location: { longitude, latitude },
    },
  }) => <Location longitude={longitude} latitude={latitude} />,
  contact: ({ message: { contacts } }) => <Contact contacts={contacts} />,
}

export default messagesTypeDict
