import { robotWarning } from 'assets/ilustrations'
import { Column, Image, Typography } from 'design-system'

const EmptyData = ({ text }) => {
  return (
    <Column alignItems='center' justifyContent='center' height='350px' gap='16px'>
      <Image src={robotWarning} width='56px' opacity='0.5' />
      <Typography>{text || 'Parece que você ainda não tem nenhum arquivo nessa pasta.'}</Typography>
    </Column>
  )
}

export default EmptyData
