import { robotAlert } from 'assets/ilustrations'
import { Alert } from 'components/common'
import { Button, Column, Image, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'design-system'

const ModalConfirmation = ({ title, onClose, message, content, handler, type, helper, helperProps, buttonConfirmProps, ...props }) => {
  return (
    <Modal open={open} onClose={onClose} size='sm' {...props}>
      <ModalHeader border='none' onClose={onClose} />
      <ModalBody>
        <Column alignItems='center' gap='8px'>
          <Image src={robotAlert} width='115px' />
          <Typography textAlign='center' fontSize='16px' fontWeight='600'>
            {title}
          </Typography>
          <Typography textAlign='center'>{message}</Typography>
          {content && content}
        </Column>
        {helper && <Alert mt='8px' {...helperProps} />}
      </ModalBody>
      <ModalFooter>
        <Button variant='text' color='text' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button color={type} onClick={handler} {...buttonConfirmProps}>
          Sim, excluir
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalConfirmation.defaultProps = {
  size: 'md',
  content: null,
  handler: () => {},
}

export default ModalConfirmation
