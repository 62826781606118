import { useEffect, useState } from 'react'

import { PageHeader, ModalConfirmation, ProtectedComponent, ModalExtraFields, RobotHelp } from 'components'
import { fieldTypesDict } from 'configs'
import { Column, Row, Button, Icon, Card, Table, Typography } from 'design-system'
import { notify, withAuthorization } from 'helpers'
import { deleteExtraField, showExtraFieldsList } from 'services'

import { actionTypes, useSettingsReducer } from './store'

const ExtraFields = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useSettingsReducer()

  useEffect(() => {
    fetch()
  }, [pagination?.current_page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showExtraFieldsList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
        tab: 'extra_fields',
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de campos personalizados.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteExtraField(id)

      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
        tab: 'extra_fields',
      })
      notify.success('Campo personalizado excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir o campo personalizado no momento.')
    }
  }

  const columns = [
    {
      header: 'Nome',
      field: 'name',
      cellProps: { width: '50%' },
    },
    {
      header: 'Tipo do campo',
      field: 'field_type',
      cellProps: { width: '50%' },
      render: ({ field_type }) => <Typography>{fieldTypesDict[field_type]}</Typography>,
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_contacts']} unauthorizedComponent={false}>
          <Row gap='20px' width='100%'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
            <Icon
              cursor='pointer'
              icon='Trash'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Excluir modelo ${row?.name}.`,
                  message:
                    'Você realmente deseja excluir o campo personalizado? Esta ação não pode ser revertida e todos os dados salvos serão perdidos.',
                  handler: () => handleDelete(row.id),
                })
              }
            />
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Column>
      <Card>
        <PageHeader title='Gerenciamento de campos personalizados'>
          <ProtectedComponent allowedRoles={['manage_contacts']} unauthorizedComponent={false}>
            <Row gap='8px' justifyContent='flex-end'>
              <Button variant='icon' color='primary' onClick={() => setModal(true)}>
                <Icon icon='Add' />
              </Button>
            </Row>
          </ProtectedComponent>
        </PageHeader>
        <Card mt='16px' overflow='auto'>
          <Table
            title='campo personalizado'
            columns={columns}
            loading={loading}
            data={state.extra_fields.sort((itemA, itemB) => itemA?.name?.localeCompare(itemB?.name))}
            pagination={pagination}
            setPagination={setPagination}
            emptyMessage='Ops. Parece que você ainda não criou um campo personalizado. Crie novos campos para personalizar os dados dos seus contatos.'
            handleEmptyData={() => setModal(true)}
          />
        </Card>
        <ModalExtraFields info={modal} onClose={() => setModal(null)} dispatch={dispatch} actionTypes={actionTypes} />
        <ModalConfirmation open={modalConfirmation} onClose={() => setModalConfirmation(null)} {...modalConfirmation} />
      </Card>
      <RobotHelp
        mt='16px'
        maxWidth='580px'
        text='Com os campos personalizados, você pode adicionar novas informações para identificar seus contatos. Cada novo campo criado nesta listagem será adicionado ao formulário de contatos. Caso você adicione um campo após criar seu contato, será necessário retornar ao cadastro dele e adicionar a nova informação.'
      />
    </Column>
  )
}

export default withAuthorization(ExtraFields, ['access_contacts'])
