const insertAtCursor = (r, content, value) => {
  if (!r?.current) return

  const input = r?.current
  const start = input.selectionStart
  const end = input.selectionEnd

  if (start === null || end === null) return

  const newText = content.slice(0, start) + value + content.slice(end)

  input.value = newText

  const newCursorPos = start + value.length
  input.setSelectionRange(newCursorPos, newCursorPos)

  input.focus()
  return newText
}

export default insertAtCursor
