import { colors } from 'configs'
import { Button, Card, Column, Icon, Input, RadioButton, Row, Typography } from 'design-system'

import { MAX_FOOTER_LENGTH, MAX_HEADER_LENGTH, mediaTypesDict, mediaTypesOptions } from '../templateOptions'

const headerTypeDict = {
  TEXT: ({ getValues, register, errors }) => (
    <Column>
      <Input
        label='Texto do cabeçalho'
        error={errors?.header && errors?.header?.text}
        errorMessage={errors?.header?.text?.message}
        required
        helperText={`${MAX_HEADER_LENGTH - getValues()?.header?.text?.length} caracteres`}
        {...register('header.text', {
          required: { value: true, message: 'Por favor, insira o texto do cabeçalho da template' },
          maxLength: {
            value: MAX_HEADER_LENGTH,
            message: `O texto do header não pode exceder ${MAX_FOOTER_LENGTH} caracteres.`,
          },
        })}
      />
    </Column>
  ),
  MEDIA: ({ header: { header_type, default_media }, register, setValue, errors, setManageFile }) => (
    <Column gap='16px'>
      <Row gap='12px' flexDirection={['column', 'column', 'row', 'row']}>
        {mediaTypesOptions.map(({ label, value, icon, accepts, media }) => (
          <Card
            className='cursor-pointer'
            minWidth={['100%', '100%', '154px', '154px']}
            backgroundColor={colors.grey[25]}
            p='16px'
            width='fit-content'
            border={`1px solid ${header_type?.category === value ? colors.secondary : colors.grey[50]}`}
            onClick={() => {
              setValue('header.header_type.category', value)
              setValue('header.header_type.accepts', accepts)
              setValue('header.header_type.media', media)
              setValue('header.default_media', null)
            }}
          >
            <Row gap='8px' alignItems='center'>
              <RadioButton
                value={value}
                {...register('header.header_type.category', {
                  required: { value: true, message: 'Por favor selecione uma categoria de mídia.' },
                })}
              />
              <Typography>{label}</Typography>
              <Icon icon={icon} color={header_type?.category === value ? colors.secondary : colors.text} />
            </Row>
          </Card>
        ))}
      </Row>
      <Row alignItems='center' gap='8px'>
        <input hidden value={default_media?.file} {...register('header.default_media.file', { required: true })} />
        {default_media?.file ? (
          <Column>
            <Button iconRight={mediaTypesDict[header_type.category]?.icon} disabled>
              Arquivo selecionado
            </Button>
          </Column>
        ) : (
          <Button variant='outlined' iconRight='Upload' onClick={() => setManageFile(true)}>
            Selecionar arquivo
          </Button>
        )}
        {default_media?.file && (
          <Icon
            icon='Trash'
            color='danger'
            cursor='pointer'
            onClick={() => {
              setValue('header.default_media.file', '')
              setValue('header.default_media.category', '')
            }}
          />
        )}
      </Row>
      {errors?.header?.default_media?.file && (
        <Typography variant='caption' color='danger'>
          Por favor, selecione o arquivo
        </Typography>
      )}
    </Column>
  ),
  LOCATION: () => <Typography>Localização</Typography>,
}

export default headerTypeDict
