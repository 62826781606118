import { theme } from 'configs'
import Checkbox from 'design-system/Checkbox'
import Row from 'design-system/Row'
import Typography from 'design-system/Typography'

const CheckboxDayCard = ({ active, day, color, onClick, ...props }) => {
  return (
    <Row
      id={`day_week_${day?.weekday}`}
      maxWidth='75px'
      p='8px 16px'
      justifyContent='center'
      alignItems='center'
      border={`1px solid ${active ? theme.colors[color] : theme.colors.grey[50]}`}
      borderRadius='4px'
      gap='8px'
      className='cursor-pointer'
      onClick={() => onClick(day)}
      {...props}
    >
      <Checkbox checked={active} readOnly />
      {day?.suffix && <Typography whiteSpace='nowrap'>{day?.suffix}</Typography>}
    </Row>
  )
}

CheckboxDayCard.defaultProps = {
  color: 'secondary',
  active: false,
  label: false,
  weekday: 0,
}

export default CheckboxDayCard
