import { format } from 'date-fns'

import { messageStatusDict } from 'configs'
import { Icon, Row, Tooltip, Typography } from 'design-system'

const MessageFooter = ({ createdAt, status, senderType, userSender, failCode, failReason }) => {
  const ignoreStatusSender = ['contact', 'internal']
  const ignoreUserSender = ['contact', 'internal']

  return (
    <Row gap='4px' mt='8px' width='100%' justifyContent='space-between' alignItems='center' className='cursor-pointer'>
      {!ignoreUserSender.includes(senderType) ? (
        <Typography ellipsis className='conversation__user_sender' fontSize='12px' color='grey.200'>
          {userSender?.name || 'Sistema'}
        </Typography>
      ) : (
        <Typography></Typography>
      )}
      <Row gap='4px'>
        {createdAt && senderType !== 'internal' && (
          <Typography fontSize='12px' textAlign='right'>
            {format(createdAt, 'HH:mm')}
          </Typography>
        )}
        {status && !ignoreStatusSender.includes(senderType) && (
          <Tooltip title={failCode ? `Error: [${failCode}] - ${failReason}` : ''}>
            <Icon size='14' icon={messageStatusDict[status?.value]?.icon} color={messageStatusDict[status?.value]?.color || '#7F7F7F'} />
          </Tooltip>
        )}
      </Row>
    </Row>
  )
}

export default MessageFooter
