import { Button, Checkbox, ListItem, Popover, Row, Select } from 'design-system'
import { notify } from 'helpers'
import { useClickout } from 'hooks'
import { moveFile } from 'services'

import ActionButton from '../ActionButton'

const Header = ({
  categoryFilter,
  selectedFolder,
  setCheckedFiles,
  connections,
  selectedConnection,
  folders,
  setSelectedConnection,
  checkedFiles,
  setFolders,
  setFiles,
  setSelectedFolder,
  canMoveFiles,
  setLoading,
  loading,
}) => {
  const { openedState, elementRef, setOpenedState } = useClickout(false)

  const handleMoveAllFiles = async (source_folder, target_folder) => {
    try {
      await moveFile({ source_folder, target_folder })

      setSelectedFolder(null)
      setCheckedFiles(null)
      setOpenedState(false)
      notify.success('Arquivos movido com sucesso.')
    } catch {
      notify.error('Não foi possível mover arquivo no momento.')
    }
  }

  return (
    <Row justifyContent={canMoveFiles ? 'space-between' : 'flex-end'} alignItems='center' gap='16px'>
      {canMoveFiles && <Checkbox label='Selecionar todos' checked={checkedFiles} onClick={(e) => setCheckedFiles(e.target.checked)} />}
      <Row gap='16px'>
        {connections.length > 1 && !selectedFolder && (
          <Select
            maxWidth='180px'
            value={selectedConnection}
            keys={{ label: 'name', value: 'id' }}
            onChange={(e) => setSelectedConnection(e)}
            options={connections}
            placeholder='Selecione a conexão'
          />
        )}
        {checkedFiles ? (
          <Row position='relative'>
            <Button variant='text' color='secondary' iconLeft='FolderOpened' onClick={() => setOpenedState(true)}>
              Mover
            </Button>
            {openedState && (
              <Popover ref={elementRef} open={openedState} right='0'>
                {folders
                  .filter((f) => f?.id !== selectedFolder?.id)
                  .map(({ name, id }) => (
                    <ListItem key={id} onClick={() => handleMoveAllFiles(selectedFolder.id, id)}>
                      {name}
                    </ListItem>
                  ))}
              </Popover>
            )}
          </Row>
        ) : (
          <ActionButton
            categoryFilter={categoryFilter}
            loading={loading}
            setLoading={setLoading}
            connection={selectedConnection?.id}
            selectedFolder={selectedFolder}
            setFolders={setFolders}
            setFiles={setFiles}
          />
        )}
      </Row>
    </Row>
  )
}

export default Header
