import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import WorkdaysSelector from 'components/common/WorkdaysSelector'
import { weekDays } from 'configs'
import { Form, Modal, ModalBody, ModalHeader, Input, Row, Button, Typography, Switch, Select, ModalFooter } from 'design-system'
import { notify, validateRangeHour } from 'helpers'
import { useAuth } from 'hooks'
import { createCompanyUser, showDepartmentsList, showRolesList, showCompanyUserInfo, updatedCompanyUser } from 'services'

const ModalUsers = ({ onClose, info, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [departments, setDepartments] = useState([])

  const {
    userData,
    userData: { company_user },
    updateLoggedUser,
    userActionTypes,
  } = useAuth()

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    working_schedules: weekDays,
    shouldFocusError: false,
  })

  useEffect(() => {
    if (info?.user?.id) {
      handleGetInfo()
    }

    info && fetchOptions()

    return () => reset({ name: '', active: true })
  }, [info])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const hasErrors = values.working_schedules.filter((r) => r.selected && validateRangeHour(r.start, r.end))

      if (hasErrors.length > 0) {
        hasErrors.forEach((day) => {
          if (!day.start) {
            setError(`working_schedules.${day.weekday}.start`)
          }
          if (!day.end) {
            setError(`working_schedules.${day.weekday}.end`)
          }
          if (validateRangeHour(day.start, day.end)) {
            setError(`working_schedules.${day.weekday}.start`)
            setError(`working_schedules.${day.weekday}.end`)
          }
        })

        return false
      }

      values.roles = values.roles.map((r) => r.id)
      values.departments = values.departments.map((d) => d.id)
      values.working_schedules = values.working_schedules.filter((f) => f.selected && f.start && f.end)

      const { data } = info?.id ? await updatedCompanyUser(info?.id, values) : await createCompanyUser(values)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'users',
      })

      if (company_user.id === info?.id) {
        updateLoggedUser({
          type: userActionTypes.UPDATE_ITEM,
          payload: {
            ...userData,
            company_user: {
              ...userData.company_user,
              roles: data.roles,
              departments: data.departments,
            },
          },
        })
      }

      notify.success(info?.id ? 'Informações salvas com sucesso.' : 'Novo usuário criado com sucesso.')
      onClose()
    } catch {
      notify.error('Não foi possível salvar usuário.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showCompanyUserInfo(info?.id)

      data.working_schedules = weekDays.map((w) => ({
        ...w,
        ...data.working_schedules.find((ws) => ws.weekday === w.weekday),
        selected: !!data.working_schedules.find((ws) => ws.weekday === w.weekday),
      }))

      reset({ ...data })
    } catch (err) {
      notify.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchOptions = async () => {
    try {
      setLoading(true)
      const [rolesOptions, departmentsOptions] = await Promise.all([
        showRolesList({ page_size: 1000 }),
        showDepartmentsList({ page_size: 1000, active: true }),
      ])

      setRoles(rolesOptions.data.results)
      setDepartments(departmentsOptions.data.results)
    } catch {
      notify.error('Não foi possível recuperar a lista de cargos.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='md' open={info} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {info?.id ? 'Editar usuário' : 'Novo usuário'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row justifyContent='flex-end' gap='8px' alignItems='center'>
            <Typography>Usuário ativo</Typography>
            <Switch {...register('active')} />
          </Row>
          <Row mt='8px' gap='16px'>
            <Input label='Nome' error={errors?.name} disabled={loading || info?.id} {...register('user.name', { required: true })} />
          </Row>
          <Row mt='16px' gap='16px'>
            <Input label='E-mail' error={errors?.email} disabled={loading || info?.id} {...register('user.email', { required: true })} />
          </Row>
          <Row mt='16px'>
            <Select isMulti label='Cargos' options={roles} name='roles' control={control} keys={{ label: 'name', value: 'id' }} />
          </Row>
          <Row mt='16px'>
            <Select isMulti label='Departamentos' options={departments} name='departments' control={control} keys={{ label: 'name', value: 'id' }} />
          </Row>
          <Row mt='16px'>
            <WorkdaysSelector
              initialValue={getValues('working_schedules')}
              setValue={setValue}
              name='working_schedules'
              control={control}
              register={register}
              errors={errors}
              loading={loading}
              reset={reset}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalUsers
