import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { robotTagsBW } from 'assets/ilustrations'
import { ProtectedComponent, ModalCreateNewTicket } from 'components'
import { Column, Typography, Row, Input, Icon, Image, Button } from 'design-system'
import { notify } from 'helpers'
import { useAnalytics, useAuth, useClickout, useDebounce, useTickets } from 'hooks'
import { showTicketCounters, showTicketList } from 'services'

import PopoverTicketsFilter from '../../../popovers/PopoverTicketsFilter'
import TicketCard from '../TicketCard'

import SkeletonTicketCard from './SkeletonTicketCard'
import TicketTabItem from './TicketTabItem'



const PAGE_SIZE_CLOSED_TICKET = 50

const TicketList = () => {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const [search, setSearch] = useState('')
  const [departmentsFilter, setDepartmentsFilter] = useState([])
  const [attendantFilter, setAttendantFilter] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const location = useLocation()

  const { trackEvent } = useAnalytics()

  const { triggerRef, elementRef, openedState } = useClickout(false)

  const {
    userData: { company_user },
  } = useAuth()

  const debounceSearch = useDebounce(search, 800)
  const debounceDepartment = useDebounce(departmentsFilter, 500)
  const debounceAttendant = useDebounce(attendantFilter, 500)

  const { state, dispatch, actionTicketTypes, actionCurrentStatusTypes, actionCountTickets } = useTickets()
  const haveMoreTicketItems = state.tickets_list.length < count

  const debounceUser = useDebounce(state.tickets_list_filters?.attendant, 500)
  const debounceSolved = useDebounce(state.tickets_list_filters?.solved, 500)

  const statusListDict = {
    attending: 'pending,attending',
    closed: 'closed',
  }

  const resetPagination = () => {
    setPage(1)
    setCount(0)
  }

  const fetchTickets = async (params) => {
    try {
      state?.tickets_list?.length === 0 && setLoading(true)

      if (location?.state?.status) {
        dispatch({ type: actionCurrentStatusTypes.SELECT_STATUS, payload: location?.state?.status })
        location.state.status = null
      }

      const { data } = await showTicketList({ page_size: params.page_size, page: params.page, ...params })
      setCount(data.count)
      let newTicketList = data.results
      if (params.status__in === 'closed' && !params.search && params.page !== 1) {
        newTicketList = [...state.tickets_list.filter((item) => item.status === 'closed'), ...data.results]
      }
      dispatch({
        type: actionTicketTypes.LOAD_TICKETS_LIST,
        payload: newTicketList,
      })
    } catch {
      notify.error('Não foi possível resgatar listagem de mensagens.')
    } finally {
      setLoading(false)
    }
  }

  const fetchCounter = useCallback(async () => {
    try {
      const { data } = await showTicketCounters({ status__in: 'attending,pending' })
      dispatch({
        type: actionCountTickets.COUNTERS,
        payload: { unsolved_tickets: data.unsolved_tickets },
      })
    } catch {}
  }, [actionCountTickets.COUNTERS, dispatch])

  useEffect(() => {
    resetPagination()
  }, [debounceSearch, debounceDepartment, debounceAttendant, debounceUser, debounceSolved])

  useEffect(() => {
    const withPagination =
      state.current_status === 'closed' &&
      !debounceSearch &&
      !debounceSolved &&
      debounceDepartment.length === 0 &&
      debounceAttendant.length === 0 &&
      (!debounceUser || debounceUser === 'all')

    fetchTickets({
      status__in: statusListDict[location?.state?.status] || statusListDict[state.current_status],
      search: debounceSearch,
      page: 1,
      page_size: withPagination ? PAGE_SIZE_CLOSED_TICKET : 1000,
      ordering: '-created_at',
      ...(debounceUser === 'attendant' ? { attendant: company_user?.id } : ''),
      ...(debounceSolved ? { is_solved: debounceSolved === 'solved' ? true : false } : ''),
      ...(debounceDepartment.length > 0 ? { department__in: debounceDepartment.map((i) => i.id).join(',') } : ''),
      ...(debounceAttendant.length > 0 ? { attendant__in: debounceAttendant.map((i) => i.id).join(',') } : ''),
    })
  }, [
    state.current_status,
    state?.selected_ticket?.solved_status,
    debounceSearch,
    debounceDepartment,
    debounceAttendant,
    debounceUser,
    debounceSolved,
  ])

  useEffect(() => {
    fetchCounter()
  }, [fetchCounter, state?.selected_ticket?.solved_status, state.current_status])

  const handleChangeTab = (status) => {
    setLoading(true)
    dispatch({ type: actionCurrentStatusTypes.SELECT_STATUS, payload: status })
  }

  const handleSetFilter = (value) => dispatch({ type: actionTicketTypes.UPDATE_TICKETS_LIST_FILTER, payload: value })

  const handleCheckCurrentTab = (value) => (value === state.current_status ? undefined : handleChangeTab(value))

  const handleShowMore = async () => {
    const newPage = page + 1
    if (haveMoreTicketItems) {
      setPage(newPage)
      fetchTickets({
        page: newPage,
        status__in: 'closed',
        search: debounceSearch,
        page_size: PAGE_SIZE_CLOSED_TICKET,
        ordering: '-created_at',
        ...(debounceUser === 'attendant' ? { attendant: company_user?.id } : ''),
        ...(debounceSolved ? { is_solved: debounceSolved === 'solved' ? true : false } : ''),
        ...(debounceDepartment.length > 0 ? { department__in: debounceDepartment.map((i) => i.id).join(',') } : ''),
        ...(debounceAttendant.length > 0 ? { attendant__in: debounceAttendant.map((i) => i.id).join(',') } : ''),
      })
    }
  }

  return (
    <Column width='100%' maxWidth={['100%', '100%', '400px']} overflow='auto' border='1px solid #E8E8E8' background='#fff' p='16px'>
      <Typography fontSize='18px' fontWeight='600'>
        Conversas
      </Typography>
      <Row alignItems='center' mt='16px' gap='16px'>
        <Input
          width='100%'
          placeholder='Pesquisar'
          type='search'
          value={search}
          onChange={(event) => {
            setSearch(event.target.value.toLowerCase())
            if (!event.target.value) resetPagination()
          }}
          icon='Search'
          iconPosition='left'
          iconProps={{ color: 'grey.100' }}
        />
        <Row position='relative'>
          <Icon ref={triggerRef} icon='Filter2' color={openedState ? 'grey.300' : 'grey.200'} cursor='pointer' size='lg' />
          {openedState && (
            <PopoverTicketsFilter
              stateFilter={state?.tickets_list_filters}
              handleSetFilter={handleSetFilter}
              departmentsFilter={departmentsFilter}
              setDepartmentsFilter={setDepartmentsFilter}
              attendantFilter={attendantFilter}
              setAttendantFilter={setAttendantFilter}
              elementRef={elementRef}
              open={openedState}
            />
          )}
        </Row>
        <ProtectedComponent allowedRoles={['manage_tickets']} unauthorizedComponent={false}>
          <Icon
            icon='Edit2'
            size='lg'
            color='grey.200'
            onClick={() => {
              trackEvent('button_click', 'interaction', 'new_ticket', 1)
              setOpen(!open)
            }}
            cursor='pointer'
          />
        </ProtectedComponent>
      </Row>
      <Row mb='18px' mt='12px'>
        <TicketTabItem
          active={state.current_status === 'attending'}
          label='Em atendimento'
          onClick={() => {
            handleCheckCurrentTab('attending')
            resetPagination()
          }}
          count={state?.counters?.unsolved_tickets}
        />
        <TicketTabItem active={state.current_status === 'closed'} label='Encerradas' onClick={() => handleCheckCurrentTab('closed')} />
      </Row>
      {loading ? (
        <SkeletonTicketCard />
      ) : (
        <Column mt='6px' pb='24px' pr='10px' overflow='auto'>
          {state?.tickets_list?.length > 0 ? (
            state?.tickets_list?.map((ticket) => <TicketCard key={`${ticket?.id}_${ticket?.connection?.id}`} ticket={ticket} />)
          ) : (
            <Column mt='50px' alignItems='center' justifyContent='center'>
              <Image width='110px' src={robotTagsBW} />
              <Typography mt='24px'>Sem conversas por aqui</Typography>
            </Column>
          )}
          {state.current_status === 'closed' && haveMoreTicketItems && (
            <Row marginTop='16px'>
              <Button color='primary' onClick={handleShowMore} style={{ width: '100%' }}>
                CARREGAR MAIS
              </Button>
            </Row>
          )}
        </Column>
      )}
      {open && (
        <ModalCreateNewTicket
          open={open}
          dispatch={dispatch}
          actionTypes={actionTicketTypes}
          actionCurrentStatusTypes={actionCurrentStatusTypes}
          onClose={() => setOpen(null)}
        />
      )}
    </Column>
  )
}

export default TicketList
