import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import CategorySelection from 'components/specific/templates/CreateTemplate/CategorySelection'
import { Card, Checkbox, Column, Input, Row, Select, SelectAsyncCreate, Typography } from 'design-system'
import { notify } from 'helpers'
import { createTemplateTag, showConnectionsListSelect, showTemplateTagsList } from 'services'

import { categoriesOptions, languageOptions, templateTypesOptions } from '../templateOptions'

const Category = ({ editing }) => {
  const [connections, setConnections] = useState([])

  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext()

  useEffect(() => {
    getConnectionsOptions()
    getTagsList()
  }, [])

  const getConnectionsOptions = async () => {
    try {
      const { data } = await showConnectionsListSelect({
        page_size: 100,
        active: true,
      })

      if (data?.results?.length === 1) setValue('connection', data.results[0])

      setConnections(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de conexões.')
    }
  }

  const getTagsList = async (search) => (await showTemplateTagsList({ page_size: 100, active: true, name__icontains: search })).data.results

  const handleCreateTag = async (value) => {
    try {
      const { data } = await createTemplateTag({ name: value })

      const currentTags = getValues()?.tags || []

      setValue('tags', [...currentTags, data])

      notify.success('Etiqueta criada com sucesso.')
    } catch {
      notify.error('Não foi possível criar nova tag no momento.')
    }
  }
  return (
    <Column gap='16px'>
      <Typography>Configure e crie o seu modelo de mensagem</Typography>
      {connections?.length > 1 && (
        <Row maxWidth={['100%', '100%', '194px', '194px']}>
          <Select
            label='Conexões'
            options={connections}
            keys={{ label: 'name', value: 'id' }}
            name='connection'
            control={control}
            error={errors?.connection}
            errorMessage={errors?.connection?.message}
            required={{ value: true, message: 'Por favor, selecione uma conexão para vincular está template' }}
            disabled={editing}
          />
        </Row>
      )}
      <Row>
        <CategorySelection options={categoriesOptions} disabled={editing} />
      </Row>
      <Row>
        <Checkbox
          label='Permitir que a meta altere a categoria deste modelo durante a aprovação'
          disabled={editing}
          {...register('allow_template_category_change')}
        />
      </Row>
      <Column>
        <Card p='16px' gap='16px'>
          <Row gap='16px' flexDirection={['column', 'column', 'row', 'row']}>
            <Input
              label='Dê um nome para seu template'
              placeholder='Insira o nome'
              error={errors?.name}
              errorMessage={errors?.name?.message}
              required
              disabled={editing}
              {...register('name', { required: { value: true, message: 'Por favor, digite um nome para seu template' } })}
            />
            <Select
              maxWidth={['100%']}
              disabled={editing}
              label='Selecione o tipo de template'
              options={templateTypesOptions}
              required={{ value: true, message: 'Por favor, selecione o tipo de template' }}
              control={control}
              error={errors?.template_type}
              errorMessage={errors?.template_type?.message}
              {...register('template_type')}
            />
            <Select
              maxWidth={['100%']}
              disabled={editing}
              label='Selecione o idioma do template'
              options={languageOptions}
              control={control}
              {...register('language')}
            />
          </Row>
          <Row gap='16px' flexDirection={['column', 'row']}>
            <SelectAsyncCreate
              name='tags'
              disabled={editing}
              isMulti
              label='Dê palavras chaves para o template'
              placeholder='Ex. Vendas, Produtos'
              loadOptions={getTagsList}
              keys={{ label: 'name', value: 'id' }}
              isCreatable
              onCreateOption={handleCreateTag}
              control={control}
              errorMessage={errors?.tags?.message}
              required={{ value: true, message: 'Por favor, selecione ou crie uma palavra chave para esse template' }}
              error={errors?.tags}
            />
          </Row>
        </Card>
      </Column>
    </Column>
  )
}

export default Category
