import PopoverEmoji from 'components/popovers/PopoverEmoji'
import { Row, Icon } from 'design-system'

const Emojis = ({ trigger, elementRef, open, onChange }) => (
  <Row position='relative'>
    <Icon ref={trigger} icon='Emoji' color='grey.300' cursor='pointer' />
    {open && <PopoverEmoji elementRef={elementRef} top='20px' open={open} onEmojiSelect={onChange} />}
  </Row>
)

export default Emojis
