import { useRef, useState } from 'react'
import styled from 'styled-components'

import { LoadingLogo } from 'assets'
import { Column, Image, Input, Tooltip, Typography } from 'design-system'
import { formatTextOverflow } from 'helpers'

import { extensionThumbDict } from '../../constants'
import PopoverMenuOptions from '../PopoverMenuOptions'

const File = ({
  active,
  checkedFiles,
  setSelectedFile,
  menuContextState,
  onSelectFile,
  file,
  selectedFolder,
  setModalConfirmation,
  renameAction,
  deleteAction,
  moveAction,
  folders,
}) => {
  const [onEdit, setOnEdit] = useState(null)
  const fileRef = useRef(null)
  const containerRef = useRef(null)

  const { openedState, elementRef, setOpenedState } = menuContextState
  const { category, name, uuid } = file
  const { id, connection } = selectedFolder

  const preview = category === 'image' ? file.file : extensionThumbDict[category]
  const previewStyles = category === 'image' ? { objectFit: 'cover' } : { objectFit: 'contain' }

  const contextMenuActions = [
    {
      label: 'Renomear',
      icon: 'Edit',
      color: 'grey.300',
      action: () => {
        setOpenedState(false)
        setOnEdit(uuid)
      },
    },
    {
      label: 'Mover para',
      icon: 'FolderOpened',
      color: 'grey.300',
      move: true,
    },
    {
      label: 'Excluir',
      icon: 'Trash',
      color: 'danger',
      action: () => {
        setOpenedState(false)
        setModalConfirmation({
          open: true,
          action: () => deleteAction(uuid),
          title: `Excluir arquivo ${name}`,
          text: 'Deseja realmente excluir o arquivo? Esta ação é irreversível e não poderá ser desfeita.',
        })
      },
    },
  ]

  const handleRenameFile = (charCode, name) =>
    charCode === 13 ? renameAction({ uuid, name, folder: id, connection: connection?.id }, setOnEdit) : null

  const handleContextMenu = (e) => {
    e.preventDefault()
    if (checkedFiles) return

    if (openedState) {
      return setOpenedState(null)
    } else {
      return setOpenedState(uuid)
    }
  }

  const handleSelectFile = (file) => {
    onSelectFile(file)
    setSelectedFile(uuid)
  }

  return (
    <FileCard ref={containerRef} active={active} onContextMenu={handleContextMenu} onClick={() => handleSelectFile(file)}>
      <Column width='58px' height='58px' overflow='hidden'>
        <Image src={preview} width='100%' height='100%' alt='thumb' hasThumb thumbFile={LoadingLogo} {...previewStyles} />
      </Column>
      {onEdit ? (
        <Input
          width='72px'
          ref={fileRef}
          height='21px'
          onKeyPress={(e) => handleRenameFile(e.charCode, e.target.value)}
          autoFocus
          placeholder={name}
          onBlur={() => setOnEdit(null)}
        />
      ) : (
        <Tooltip title={name}>
          <Typography textAlign='center' width='55px' cursor='initial'>
            {formatTextOverflow(name, 10, 6)}
          </Typography>
        </Tooltip>
      )}
      <PopoverMenuOptions
        optionId={uuid}
        openedState={openedState === uuid}
        elementRef={elementRef}
        parentRef={containerRef}
        setModalConfirmation={setModalConfirmation}
        actions={contextMenuActions}
        setOpenedState={setOpenedState}
        currentFolder={selectedFolder}
        folders={folders}
        file={file}
        moveAction={moveAction}
        withPortal
        customPosition={{ top: -30, left: 60 }}
      />
    </FileCard>
  )
}

const FileCard = styled(Column)`
  min-height: 120px;
  border-radius: 4px;
  position: relative;
  padding: 0px 6px;
  align-items: center;
  border: 1px solid ${({ active, theme }) => (active ? theme.colors.secondary : 'transparent')};
  & * {
    cursor: pointer;
  }

  &:hover {
    border-color: ${({ theme }) => theme?.colors?.secondary};
  }
`

export default File
