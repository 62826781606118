import { GlobalConfigs } from 'configs'
import { AuthProvider } from 'providers'
import Routes from 'routes'

const App = () => {
  return (
    <GlobalConfigs>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </GlobalConfigs>
  )
}

export default App
