import { Button, Modal, ModalBody, Row, Typography } from 'design-system'

const ModalConfirmation = ({ onClose, title, text, action, content, cancelActionLabel = 'Cancelar', ...props }) => {
  return (
    <Modal size='xs' {...props}>
      <ModalBody>
        <Typography fontSize='16px' fontWeight='600'>
          {title}
        </Typography>
        <Typography mt='8px'>{text}</Typography>
        {content && content}
        <Row mt='16px' gap='8px' justifyContent='flex-end'>
          <Button variant='text' color='text' onClick={onClose}>
            {cancelActionLabel}
          </Button>
          {action && (
            <Button color='danger' onClick={action}>
              Excluir
            </Button>
          )}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmation
