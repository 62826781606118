import { containerActionsValuesDict } from 'components/modals/ModalCreateTemplate/templateOptions'

const formatButtonsToContainers = (data) =>
  data?.length > 0
    ? data?.reduce((acc, item) => {
        const container = item.button_type === 'QUICK_REPLY' ? 'QUICK_REPLY' : 'CTA'

        const existingContainer = acc.find((c) => c.container === container)

        if (existingContainer) {
          existingContainer.buttons.push(item)
        } else {
          acc.push({ ...containerActionsValuesDict[container], buttons: [item] })
        }

        return acc
      }, [])
    : []

const formatContainersToButtons = (data) => (data?.length > 0 ? data?.flatMap((c) => c.buttons) : [])

export { formatButtonsToContainers, formatContainersToButtons }
