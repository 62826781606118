import { Navigate } from 'react-router-dom'

import { useAuth } from 'hooks'

import isOutsideWorkHours from './isOutsideWorkHours'

const withAuthorization = (WrappedComponent, allowedRoles) => {
  const WithAuthorization = (props) => {
    const { userData } = useAuth()

    if (
      !userData?.company_user?.owner &&
      userData?.company_user?.company?.settings?.force_working_schedule &&
      isOutsideWorkHours(userData?.company_user?.working_schedules)
    )
      return <Navigate to='/unauthorized' state='workschedule' />

    if (
      !userData ||
      (!userData?.company_user?.owner &&
        !userData?.permissions?.find((l) => allowedRoles?.includes(l)))
    )
      return <Navigate to='/unauthorized' state='permission' />

    return <WrappedComponent {...props} />
  }

  return WithAuthorization
}

export default withAuthorization
