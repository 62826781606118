import { memo } from 'react'

const ListOrdered = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M2.5 17H4.5V17.5H3.5V18.5H4.5V19H2.5V20H5.5V16H2.5V17ZM3.5 8H4.5V4H2.5V5H3.5V8ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 5V7H21.5V5H7.5ZM7.5 19H21.5V17H7.5V19ZM7.5 13H21.5V11H7.5V13Z'
        fill='black'
      />
    </svg>
  )
}
const Memo = memo(ListOrdered)
export default Memo
