const convertHtmlToMarkdown = (content) => {
  return content
    .replace(/<strong>(.*?)<\/strong>/g, '*$1*')
    .replace(/<em>(.*?)<\/em>/g, '_$1_')
    .replace(/<s>(.*?)<\/s>/g, '~$1~')
    .replace(/<br\s*\/?>/g, '\n')
    .replace(/<p>\s*<\/p>/g, '\n')
    .replace(/<\/p>/g, '\n')
    .replace(/<p>/g, '')
    .replace(/<ol[^>]*>([\s\S]*?)<\/ol>/g, (match, items) => {
      let index = 1
      return items.trim().replace(/<li>(.*?)<\/li>/g, () => `${index++}. \n`) // Lista ordenada
    })
    .replace(/<ul[^>]*>([\s\S]*?)<\/ul>/g, (match, items) => {
      return items.trim().replace(/<li>(.*?)<\/li>/g, '- \n') // Lista não ordenada
    })
    .trim()
}

export default convertHtmlToMarkdown
