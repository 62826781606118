import Chart from 'react-apexcharts'

import { theme } from 'configs'


const GraphicDonut = ({
  title,
  series,
  labels,
  optionsProps,
  ...props
}) => {
  const options = {
    chart: {
      id: title,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: '55%',
        },
      },
    },
    legend: {
      position: 'bottom',
      offsetY: 0,
      height: 'auto',
    },

    series,
    labels,
    ...optionsProps,
  }

  return <Chart type='donut' options={options} series={series} {...props} />
}

GraphicDonut.defaultProps = {
  title: 'ChatGDS-Graphic',
  labels: [],
  series: [],
  optionsProps: { colors: [theme.colors.primary] },
}

export default GraphicDonut
