import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import PopoverNewContact from 'components/popovers/PopoverNewContact'
import { Button, Column, Divider, Icon, Row, Select, SelectAsync, Tooltip, Typography } from 'design-system'
import { notify } from 'helpers'
import { useAuth, useClickout } from 'hooks'
import { showCompanyUsersListSelect, showConnectionsListSelect, showContactsListSelect, showDepartmentsListSelect, showTicketList } from 'services'

const Information = ({ setExistTicket, loading }) => {
  const [attendants, setAttendants] = useState([])
  const [connections, setConnections] = useState([])

  const { triggerRef, elementRef, openedState, setOpenedState } = useClickout(false)

  const {
    userData: { company_user },
  } = useAuth()

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext()

  useEffect(() => {
    getUserOptions(company_user?.departments[0])
    getConnectionsOptions()
  }, [])

  const getUserOptions = useCallback(
    async (department) => {
      try {
        const { data } = await showCompanyUsersListSelect({
          page_size: 100,
          active: true,
          departments: department?.id,
        })

        const checkCurrentUser = data?.results?.find((dp) => dp?.name === company_user?.name)

        setValue('attendant', checkCurrentUser || '')

        setAttendants(() => data.results)
      } catch {
        notify.error('Não foi possível resgatar lista de usuários.')
      }
    },
    [company_user?.name, setValue],
  )

  const checkExistisTicket = async (value) => {
    try {
      const { data } = await showTicketList({ status__in: 'pending,attending', contact: value.id })

      if (data.results.length > 0) {
        setExistTicket(data.results[0].id)
      } else {
        setExistTicket(null)
      }
    } catch {
      notify.error('Não foi possível verificar contato.')
    }
  }

  const getConnectionsOptions = async () => {
    try {
      const { data } = await showConnectionsListSelect({
        page_size: 100,
        active: true,
      })

      if (data?.results?.length === 1) setValue('connection', data.results[0])

      setConnections(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de conexões.')
    }
  }

  const getContactOptions = async (search) =>
    (await showContactsListSelect({ page_size: 100, search })).data.results.map((contact) => ({
      ...contact,
      name: `${contact.name} | ${contact.dial_code}`,
    }))

  const getDepartmentsOptions = async (search) =>
    (await showDepartmentsListSelect({ page_size: 100, active: true, name__icontains: search })).data.results

  return (
    <Column>
      <Typography mb='16px'>
        Para iniciar uma nova conversa, selecione o{' '}
        <Typography as='span' fontWeight='bold'>
          departamento
        </Typography>{' '}
        e o{' '}
        <Typography as='span' fontWeight='bold'>
          atendente
        </Typography>{' '}
        que conduzirão a conversa. Em seguida, escolha o{' '}
        <Typography as='span' fontWeight='bold'>
          contato
        </Typography>{' '}
        e selecione o{' '}
        <Typography as='span' fontWeight='bold'>
          template
        </Typography>{' '}
        que será enviado.
      </Typography>
      <Select
        label='Conexões'
        options={connections}
        keys={{ label: 'name', value: 'id' }}
        name='connection'
        control={control}
        error={errors?.connection}
        required
        isDisabled={connections?.length === 1}
      />
      <Divider m='16px 0' />
      <Row gap='16px'>
        <SelectAsync
          maxWidth={['100%', '100%', '1153px', '153px']}
          label='Departamento'
          cacheOptions
          defaultOptions
          loadOptions={getDepartmentsOptions}
          keys={{ label: 'name', value: 'id' }}
          name='department'
          control={control}
          error={errors?.department}
          errorMessage={errors?.department && errors?.department?.message}
          callBack={getUserOptions}
          required
          rules={{ required: { value: true, message: 'Por favor, selecione um departamento' } }}
          isDisabled={loading}
        />
        <Select
          maxWidth={['100%', '100%', '1153px', '153px']}
          label='Atendente responsável'
          defaultOptions
          options={attendants}
          keys={{ label: 'name', value: 'id' }}
          name='attendant'
          control={control}
          error={errors?.attendant}
          errorMessage={errors?.attendant && errors?.attendant?.message}
          required
          rules={{ required: { value: true, message: 'Por favor, selecione um atendente' } }}
          disabled={loading}
        />
        <Row width='100%' maxWidth={['100%', '100%', '203px', '203px']} alignItems='center' gap='8px'>
          <SelectAsync
            label='Contato'
            defaultOptions
            loadOptions={getContactOptions}
            keys={{ label: 'name', value: 'id' }}
            name='contact'
            control={control}
            error={errors?.contact}
            callBack={checkExistisTicket}
            required
            errorMessage={errors?.contact && errors?.contact?.message}
            rules={{ required: { value: true, message: 'Por favor, selecione um contato' } }}
            isDisabled={loading}
          />
          <Column position='relative'>
            <Tooltip title='Criar novo contato' mt='24px'>
              <Button ref={triggerRef} mt='24px' variant='icon'>
                <Icon icon='GroupAdd' />
              </Button>
            </Tooltip>
          </Column>
        </Row>
      </Row>

      {openedState && (
        <PopoverNewContact
          onlySaveContact
          open={openedState}
          setOpenedState={setOpenedState}
          elementRef={elementRef}
          updateField={(value) => setValue('contact', value)}
          top='15%'
          left='45%'
        />
      )}
    </Column>
  )
}

export default Information
