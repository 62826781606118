import { useNavigate } from 'react-router-dom'

import { colors } from 'configs'
import { Avatar, Column, Icon, ListItem, Popover, Typography } from 'design-system'
import { useAuth } from 'hooks'
const PopoverUserProfile = ({
  open,
  user,
  elementRef,
  ...props
}) => {
  const { logout } = useAuth()

  const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <Popover p='16px' ref={elementRef} open={open} {...props}>
      <Column p='8px' alignItems='center' borderBottom={`1px solid ${colors.grey[50]}`}>
        <Avatar src={user?.picture} alt={user?.name} square borderRadius='4px' />
        <Column alignItems='center'>
          <Typography mt='8px'>{user?.name}</Typography>
          <Typography variant='caption' whiteSpace='nowrap'>
            {user?.email}
          </Typography>
        </Column>
      </Column>
      <Column width='100%' mt='4px'>
        <ListItem onClick={() => handleNavigate('/settings/')}>
          <Icon icon='Cogs' color='grey.100' />
          <Typography>Configurações</Typography>
        </ListItem>
        <ListItem onClick={() => handleLogout()}>
          <Icon icon='Logout' color='grey.100' />
          <Typography>Sair</Typography>
        </ListItem>
      </Column>
    </Popover>
  )
}

export default PopoverUserProfile
