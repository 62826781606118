import { toast } from 'react-toastify'

import { RobotAlert } from 'components'

const notify = {
  success: (message, props) =>
    toast.success(message, { containerId: 'notify-container', ...props }),
  error: (message, props) => toast.error(message, { containerId: 'notify-container', ...props }),
  info: (message, props) => toast.info(message, { containerId: 'notify-container', ...props }),
  warning: (message, props) =>
    toast.warning(message, { containerId: 'notify-container', ...props }),
  message: (message, props) => toast(message, { containerId: 'messages-container', ...props }),
  robot: (message, color, props) =>
    toast(<RobotAlert message={message} color={color} />, {
      containerId: 'alert-robot-container',
      style: { width: '480px', backgroundColor: 'transparent', boxShadow: 'none' },
      ...props,
    }),
}

export default notify
