import Icon from 'design-system/Icon'
import Row from 'design-system/Row'
import Tooltip from 'design-system/Tooltip'
import Typography from 'design-system/Typography'

const TextLabelHelper = ({ label, required, helper, ...props }) => {
  return (
    <Row gap='4px' alignItems='center' {...props}>
      {label && (
        <Typography as='span' whiteSpace='nowrap' marginBottom='4px'>
          {label}
          {required ? (
            <Typography ml='2px' as='span' color='danger'>
              *
            </Typography>
          ) : (
            ''
          )}
        </Typography>
      )}
      {helper && (
        <Tooltip title={helper}>
          <Icon icon='Information' size='14' color='grey.300' cursor='pointer' />
        </Tooltip>
      )}
    </Row>
  )
}

export default TextLabelHelper
