import React from 'react'
import styled from 'styled-components'
import { compose, typography, space } from 'styled-system'

import { robotError } from 'assets/ilustrations'
import { colors } from 'configs'
import { Column, Image, Typography } from 'design-system'

const styles = compose(typography, space)

const ResetTypography = styled(Typography)`
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Source Sans 3';
  color: ${colors.grey[300]};

  ${styles}
`

const StyledButton = styled.button`
  margin-top: 24px;
  width: 320px;
  padding: 10px 5px;
  background-color: #5e54a9;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error('Erro capturado pelo ErrorBoundary: ', error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Column width='100%' height='100vh' alignItems='center' justifyContent='center'>
          <Image src={robotError} />
          <ResetTypography mt='48px' fontSize='24px' fontWeight='600'>
            Oops! Algo deu errado.
          </ResetTypography>
          <Column mt='8px' gap='4px' justifyContent='center' alignItems='center'>
            <ResetTypography textAlign='center' fontSize='16px'>
              Estamos enfrentando um problema técnico.
            </ResetTypography>
            <ResetTypography textAlign='center' fontSize='16px'>
              Estamos trabalhando para resolver o mais rápido possível.
            </ResetTypography>
            <ResetTypography textAlign='center' fontSize='16px'>
              Se precisar de ajuda, entre em contato com o suporte.
            </ResetTypography>
            <StyledButton
              onClick={() => {
                window.history.pushState({}, '', '/')
                window.location.reload()
              }}
            >
              Voltar para a página inicial
            </StyledButton>
          </Column>
        </Column>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
