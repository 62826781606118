import { useState } from 'react'

import Input from 'design-system/Input'
import Row from 'design-system/Row'

const Search = ({ searchState }) => {
  const [open, setOpen] = useState(true)

  const handleSearch = () => {
    if (searchState[0]) searchState[1]('')

    setOpen((prevState) => !prevState)
  }

  return (
    <Row maxWidth='280px' alignItems='center' width={open ? '32px' : '280px'} className='transition'>
      <Input
        minWidth='32px'
        padding={open ? '4px' : '4px 8px 4px 32px'}
        borderRadius='8px'
        icon='Search'
        placeholder={open ? '' : 'Pesquisar'}
        readOnly={open}
        value={searchState[0] || ''}
        onChange={(e) => searchState[1](e.target.value)}
        iconProps={{ onClick: handleSearch, cursor: 'pointer', color: 'primary' }}
      />
    </Row>
  )
}

export default Search
