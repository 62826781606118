import { useState } from 'react'

import { ListItem, Popover, Typography } from 'design-system'
import { notify } from 'helpers'
import { useAuth } from 'hooks'
import { exportContact } from 'services'

const PopoverMenuExportContact = ({ open, elementRef, ...props }) => {
  const [loading, setLoading] = useState(false)
  const {
    userData: {
      company_user: { company },
    },
  } = useAuth()

  const name = `${company.name.replaceAll(' ', '_')}_contatos`

  const handleGetFile = async (value) => {
    try {
      setLoading(true)
      const { data, headers } = await exportContact(value)

      const fileName = headers['content-disposition']?.split('filename=')[1]?.replace(/"/g, '') || `${name}.${value}`

      const blob = new Blob([data], { type: headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      URL.revokeObjectURL(link.href)
    } catch {
      notify.error('Não foi possível exportar contatos')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popover ref={elementRef} open={open} minWidth='100px' {...props}>
      <ListItem disabled={loading} onClick={() => (loading ? undefined : handleGetFile('csv'))}>
        <Typography color='grey.300'>Exportar CSV</Typography>
      </ListItem>
      <ListItem disabled={loading} onClick={() => (loading ? undefined : handleGetFile('xlsx'))}>
        <Typography color='grey.300'>Exportar XLSX</Typography>
      </ListItem>
    </Popover>
  )
}

export default PopoverMenuExportContact
