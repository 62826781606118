import { useEffect, useState } from 'react'

import { PageHeader, ModalConfirmation, ModalUsers, ProtectedComponent, ModalInvite } from 'components'
import { Row, Icon, Card, Table, ColorChip, WeekDaysSelector, Typography, Badge, Button } from 'design-system'
import { hexToRGBA, notify, withAuthorization } from 'helpers'
import { deleteCompanyUser, showCompanyUsersList } from 'services'

import { actionTypes, useSettingsReducer } from './store'

const Users = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [modalInvite, setModalInvite] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useSettingsReducer()

  useEffect(() => {
    fetch()
  }, [pagination?.current_page])

  const fetch = async (searchParam) => {
    try {
      setLoading(true)
      const { data } = await showCompanyUsersList(pagination, searchParam)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data?.results,
        tab: 'users',
      })
      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de usuarios.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteCompanyUser(id)

      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
        tab: 'users',
      })
      notify.success('Usuário excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir o usuário no momento.')
    }
  }

  const columns = [
    {
      header: 'Ativo',
      render: ({ active }) => <ColorChip color={active ? 'success' : 'danger'} borderRadius='100%' width='24px' height='24px' />,
    },
    {
      header: 'Nome',
      render: ({ user: { name } }) => <Typography>{name}</Typography>,
    },
    {
      header: 'E-mail',
      render: ({ user: { email } }) => <Typography>{email}</Typography>,
    },
    {
      header: 'Dias de trabalho',
      render: ({ working_schedules }) => <WeekDaysSelector working_schedules={working_schedules} />,
    },
    {
      header: 'Departamentos',
      render: ({ departments }) => (
        <Row gap='8px' maxWidth='200px' flexWrap='wrap'>
          {departments.map((department) => (
            <Badge width='100px' backgroundColor={hexToRGBA(department.color, 0.2)} color={department?.color} title={department?.name} />
          ))}
        </Row>
      ),
    },
    {
      header: 'Cargos',
      render: ({ roles }) => (
        <Row gap='8px' maxWidth='200px' flexWrap='wrap'>
          {roles.map((role) => (
            <Badge width='100px' backgroundColor={hexToRGBA(role.color, 0.2)} color={role?.color} title={role?.name} />
          ))}
        </Row>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_users']} unauthorizedComponent={false}>
          <Row gap='20px' width='100%'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
            {!row.owner && (
              <Icon
                cursor='pointer'
                icon='Trash'
                color='danger'
                onClick={() =>
                  setModalConfirmation({
                    type: 'danger',
                    title: `Excluir usuário ${row?.name}.`,
                    message:
                      'Você realmente deseja excluir este cargo? Ao excluir o cargo, ele será removido dos usuários aos quais está atrelado, e os mesmos perderão os acessos vinculados ao cargo.',
                    handler: () => handleDelete(row.id),
                  })
                }
              />
            )}
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Card>
      <PageHeader title='Gerenciamento de usuários'>
        <ProtectedComponent allowedRoles={['manage_users']} unauthorizedComponent={false}>
          <Button onClick={() => setModalInvite(true)}>Convites</Button>
        </ProtectedComponent>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='usuário'
          columns={columns}
          loading={loading}
          data={state.users.sort((itemA, itemB) => itemA.user.name?.localeCompare(itemB?.user?.name))}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Parece que você ainda não tem nenhum usuário criado. Adicione novos usuários para ajudar na gestão da clínica.'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <ModalUsers info={modal} onClose={() => setModal(null)} dispatch={dispatch} actionTypes={actionTypes} />
      <ModalConfirmation open={modalConfirmation} onClose={() => setModalConfirmation(null)} {...modalConfirmation} />
      <ModalInvite
        data={state.invites}
        open={modalInvite}
        dispatch={dispatch}
        actionTypes={actionTypes}
        onClose={() => setModalInvite(null)}
        setModalConfirmation={setModalConfirmation}
      />
    </Card>
  )
}

export default withAuthorization(Users, ['access_users'])
