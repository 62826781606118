import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Filter2, OrderArrow } from 'assets/icons'
import { robotMessagesTag } from 'assets/ilustrations'
import { PopoverRatingsFilter } from 'components'
import DateSeparator from 'components/specific/tickets/TicketContainer/DateSeparator'
import TicketMessage from 'components/specific/tickets/TicketMessage'
import { colors } from 'configs'
import { Card, Column, Image, Input, Row, Spinner, Typography } from 'design-system'
import { compareDates, notify } from 'helpers'
import { useClickout, useDebounce } from 'hooks'
import { showTicketList, showTicketMessagesList } from 'services'

import RatingTicketCard from './RatingTicketCard'
import SkeletonList from './SkeletonList'



const Historic = ({ user, hasRating }) => {
  const [loadingTickets, setLoadingTickets] = useState(false)
  const [loadingConversation, setLoadingConversation] = useState(false)
  const [ticketsList, setTicketsList] = useState([])
  const [conversation, setConversation] = useState([])

  const ticketRef = useRef(null)

  const { triggerRef, elementRef, openedState, setOpenedState } = useClickout(false)

  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('active_down')
  const [rateFilter, setRateFilter] = useState('')

  const debounceSearch = useDebounce(search, 1000)
  const debounceRate = useDebounce(rateFilter, 1000)

  const memoizedTicketList = useMemo(
    () =>
      ticketsList.sort((itemA, itemB) =>
        order === 'active_down'
          ? compareDates(itemA.closed_at, itemB.closed_at)
          : compareDates(itemB.closed_at, itemA.closed_at),
      ),
    [order, ticketsList],
  )

  const { company_user, rates } = user

  const handleGetHistoricTickets = useCallback(
    async (params) => {
      try {
        setLoadingTickets(true)
        const {
          data: { results },
        } = await showTicketList({
          attendant: company_user?.id,
          status: 'closed',
          page_size: '1000',
          ...params,
        })

        setTicketsList(results)
      } catch {
        notify.error('Não foi possível carregar lista de conversas.')
      } finally {
        setLoadingTickets(false)
      }
    },
    [company_user?.id],
  )

  useEffect(() => {
    handleGetHistoricTickets({
      search: debounceSearch,
      rating__rate: debounceRate === 'no_survey' ? '' : debounceRate,
      rating__rate__isnull: hasRating || debounceRate === 'no_survey',
    })

    
  }, [company_user?.id, debounceSearch, debounceRate, handleGetHistoricTickets])

  const handleGetTicketHistoric = useCallback(async (id) => {
    try {
      setLoadingConversation(true)

      const {
        data: { results },
      } = await showTicketMessagesList(id, { page_size: '1000' })

      const groupedMessages = results.reduce((acc, curr) => {
        const key = format(curr.created_at, 'dd/MM/yyyy')

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      ticketRef.current = id

      setConversation(groupedMessages)
    } catch {
      notify.error('Não foi possível listar histórico da conversa.')
    } finally {
      setLoadingConversation(false)
    }
  }, [])

  return (
    <Card p='0' background={colors.grey[40]}>
      <Row height='500px'>
        <Column width='450px' borderRight={`1px solid ${colors.grey[50]}`}>
          <Column p='8px 24px'>
            <Typography fontWeight='600'>Histórico de conversas</Typography>
            <Row mt='16px' justifyContent='space-between' gap='8px'>
              <Input
                width='100%'
                placeholder='Pesquisar'
                type='search'
                value={search}
                onChange={(event) => setSearch(event.target.value.toLowerCase())}
                icon='Search'
                iconPosition='left'
              />
              <OrderArrow
                icon='OrderArrow'
                cursor='pointer'
                onClick={() => setOrder(order === 'active_down' ? 'active_up' : 'active_down')}
                active={order}
              />
              <Row position='relative' overflow='visible'>
                <Filter2 ref={triggerRef} active={rateFilter} color='grey.200' cursor='pointer' />
                {openedState && (
                  <PopoverRatingsFilter
                    left='25px'
                    top='-10px'
                    setRateFilter={setRateFilter}
                    rateFilter={rateFilter}
                    elementRef={elementRef}
                    open={openedState}
                    setOpenedState={setOpenedState}
                    options={[
                      ...Object.keys(rates).map((key) => rates[key]?.rate),
                      { label: 'Sem avaliação', value: 'no_survey' },
                    ]}
                  />
                )}
              </Row>
            </Row>
          </Column>
          {loadingTickets ? (
            <SkeletonList />
          ) : (
            <Column mt='24px' overflow='auto'>
              {memoizedTicketList?.length > 0 ? (
                memoizedTicketList.map((ticket) => (
                  <RatingTicketCard
                    active={ticketRef.current === ticket.id}
                    ticket={ticket}
                    handleGetTicketHistoric={handleGetTicketHistoric}
                  />
                ))
              ) : (
                <Typography mt='100px' textAlign='center'>
                  Ops. Nenhuma conversa encontrada.
                </Typography>
              )}
            </Column>
          )}
        </Column>
        <Column width='100%' flexDirection='column-reverse' gap='16px' overflow='auto'>
          {loadingConversation ? (
            <Column height='100%' alignItems='center' justifyContent='center'>
              <Spinner size='lg' />
            </Column>
          ) : (
            <Column width='100%' flexDirection='column-reverse' height='100%' overflow='auto'>
              {Object.keys(conversation).length > 0 ? (
                Object.keys(conversation).map((date) => (
                  <Column position='relative' p='10px 10px 0'>
                    <Column flexDirection='column-reverse' gap='8px'>
                      {conversation[date].map((message) => (
                        <TicketMessage message={message} />
                      ))}
                      <DateSeparator date={date} />
                    </Column>
                  </Column>
                ))
              ) : (
                <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
                  <Column opacity='0.6'>
                    <Image width='233px' src={robotMessagesTag} />
                  </Column>
                  <Typography>Clique na conversa ao lado para visualizar o atendimento</Typography>
                </Column>
              )}
            </Column>
          )}
        </Column>
      </Row>
    </Card>
  )
}

export default Historic
