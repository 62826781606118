import PopoverTextVariables from 'components/popovers/PopoverTextVariables'
import { Row, Icon } from 'design-system'

const Variables = ({ trigger, elementRef, open, onChange }) => {
  return (
    <Row position='relative'>
      <Icon ref={trigger} icon='AddCircle' color='grey.300' cursor='pointer' />
      {open && <PopoverTextVariables top='20px' elementRef={elementRef} open={open} onTextSelect={onChange} />}
    </Row>
  )
}

export default Variables
