import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Form, Input, Modal, ModalBody, Row, Typography } from 'design-system'
import { formatErrorMessages, notify } from 'helpers'
import { createFolder } from 'services'

const ModalNewPaste = ({ setFolders, connection, onClose, ...props }) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm()

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const { data } = await createFolder({ ...values, connection })

      setFolders((prevState) => [...prevState, data])
      notify.success('Pasta criada com sucesso')
      onClose()
    } catch (err) {
      if (err?.response?.data) {
        const e = formatErrorMessages(err?.response?.data)

        setError('name', { value: true, message: e[0]?.message?.message })
      }
      notify.error('Não foi possível criar nova pasta')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='xs' {...props}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Typography mb='8px' fontSize='16px' fontWeight='600'>
            Criar nova pasta
          </Typography>
          <Row mt='16px'>
            <Input
              label='Nome da pasta'
              error={errors?.name}
              errorMessage={errors?.name?.message}
              required
              autoFocus
              {...register('name', { required: { value: true, message: 'Por favor, informe o nome da pasta' } })}
            />
          </Row>
          <Row mt='16px' justifyContent='flex-end'>
            <Button size='sm' variant='text' color='text' onClick={onClose}>
              Cancelar
            </Button>
            <Button size='sm' type='submit' disabled={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalNewPaste
