import { InputMasked, Row, Typography } from 'design-system'
import { timeRegex } from 'utils'

const WeekTimer = ({ name, field, errors, control, register, loading }) => (
  <Row key={field.id} gap='6px' alignItems='center'>
    <Row width='110px'>
      <Typography>{field.text}</Typography>
    </Row>
    <Row gap='8px'>
      <input hidden value={field.weekday} {...register(`${name}.${field.weekday}.weekday`)} />
      <InputMasked
        maxWidth='100px'
        mask='99:99'
        disabled={loading}
        control={control}
        textAlign='center'
        border={errors[name] && errors[name][field.weekday]?.start ? '1px solid red' : ''}
        rules={{
          pattern: {
            value: timeRegex,
          },
        }}
        {...register(`${name}.${field.weekday}.start`)}
      />
      <InputMasked
        maxWidth='100px'
        mask='99:99'
        disabled={loading}
        control={control}
        textAlign='center'
        border={errors[name] && errors[name][field.weekday]?.end ? '1px solid red' : ''}
        rules={{
          pattern: {
            value: timeRegex,
          },
        }}
        {...register(`${name}.${field.weekday}.end`)}
      />
    </Row>
  </Row>
)

export default WeekTimer
