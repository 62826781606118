import styled from 'styled-components'

import PopoverForwardsContacts from 'components/popovers/PopoverForwardsContacts'
import { Icon, ListItem, Button, Typography } from 'design-system'
import { useClickout } from 'hooks'

const HeaderSelectedMessage = ({
  onCancel,
  messageSelected,
  onClickCopySelected,
  onForwardsContactsClick,
}) => {

  const {
    elementRef: elementMany,
    openedState,
    triggerRef
  } = useClickout(false)

  return (
    <Wrapper>
      <Typography color='grey.400' fontWeight='600'>
        {`${messageSelected.length} mensagem selecionada(s)`}
      </Typography>
      <Actions>
        <ListItem onClick={onClickCopySelected}>
          <Icon icon='Copy' color='grey.300' />
        </ListItem>
        <ListItem ref={triggerRef} >
          <Icon icon='Forward' color='grey.300' />
        </ListItem>
        <Button onClick={onCancel} variant='text' color='secondary'>Cancelar</Button>
      </Actions>
      <PopoverForwardsContacts
        elementRef={elementMany}
        open={openedState}
        onForwardsContactsClick={onForwardsContactsClick}
        right='38px'
        top='55px'
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color:#ffffff;
  height: 82px;
  border-bottom: 1px solid #E8E8E8;
  position: relative;
  padding: 0 16px;
  justify-content: space-between;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    border-top: none;
  }
`

export default HeaderSelectedMessage
