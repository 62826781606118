import HardBreak from '@tiptap/extension-hard-break'
import Placeholder from '@tiptap/extension-placeholder'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { forwardRef, useEffect } from 'react'
import styled from 'styled-components'

import { Column, Icon, Row, TextLabelHelper, Typography } from 'design-system'

import Footer from './Footer'
import Toolbar from './Toolbar'

const RichText = forwardRef(
  ({ hasToolbar, hasFooter, error, label, errorMessage, limit, setEditorState, required, placeholder, value, footerProps, ...props }, ref) => {
    const editor = useEditor({
      extensions: [StarterKit, HardBreak, Placeholder.configure({ placeholder })],
      content: value || ``,
      onUpdate: ({ editor }) => {
        props?.onChange(editor.getHTML())
      },
    })

    useEffect(() => {
      if (setEditorState) {
        setEditorState(editor)
      }
    }, [editor])

    return (
      <Column ref={ref} width='100%' background='white' borderRadius='4px' gap='4px'>
        <Base>
          <TextLabelHelper label={label} required={required} helper={props?.helper} />
          {hasToolbar && <Toolbar editor={editor} />}
          <Column minHeight='80px' maxHeight='200px' overflow='auto' p='8px 4px' onClick={() => editor?.chain().focus().run()}>
            <EditorContent editor={editor} {...props} />
          </Column>
          {hasFooter && <Footer editor={editor} limit={limit} {...footerProps} />}
          {error && (
            <Row mt='4px' alignItems='center' gap='4px'>
              <Icon icon='Alert' color='danger' size='11' />
              <Typography mt='2px' ml='2px' variant='caption' color='danger' lineHeight='10px'>
                {errorMessage || 'Campo obrigatório'}
              </Typography>
            </Row>
          )}
        </Base>
      </Column>
    )
  },
)

const Base = styled(Column)`
  border: 1px solid ${({ theme }) => theme.colors.grey[50]};
  border-radius: 4px;
  padding: 8px;

  & .ProseMirror-trailingBreak {
    font-size: 0;
    line-height: 0;
    margin-top: -20px;
  }

  & .ProseMirror {
    color: ${({ theme }) => theme.colors.grey[300]};
  }
  & .ProseMirror ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  & .ProseMirror ol {
    list-style-type: decimal;
    padding-left: 20px;
  }

  & .tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`

RichText.defaultProps = {
  hasToolbar: true,
  hasFooter: true,
  placeholder: 'Digite seu texto',
  footerProps: {},
}

export default RichText
