import { useLocation } from 'react-router-dom'

import { PageHeader } from 'components'
import { Column, Tabs } from 'design-system'
import { useAuth } from 'hooks'

import Connections from './Connections'
import ExtraFields from './ExtraFields'
import GeneralPreferences from './GeneralPreferences'
import Roles from './Roles'
import UserPreferences from './UserPreferences'
import Users from './Users'



const Settings = () => {
  const location = useLocation()
  const { userData } = useAuth()

  const { company_user, permissions } = userData

  const tabs = [
    {
      index: 0,
      path: 'user-preferences',
      label: 'Perfil do usuário',
      content: <UserPreferences />,
    },
    {
      index: 1,
      path: 'company-preferences',
      label: 'Configuração da conta',
      content: <GeneralPreferences />,
      disabled: !(company_user && permissions.includes('manage_company')),
    },
    {
      index: 2,
      path: 'roles',
      label: 'Cargos e permissões',
      content: <Roles />,
      disabled: !(company_user && permissions.includes('access_roles')),
    },
    {
      index: 3,
      path: 'users',
      label: 'Usuários',
      content: <Users />,
      disabled: !(company_user && permissions.includes('access_users')),
    },
    {
      index: 4,
      path: 'extra_fields',
      label: 'Campos personalizados',
      content: <ExtraFields />,
      disabled: !(company_user && permissions.includes('manage_contacts')),
    },
    {
      index: 5,
      path: 'connections',
      label: 'Conexões',
      content: <Connections />,
      disabled: !(company_user && permissions.includes('access_connections')),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Configurações' />
      <Tabs
        tabs={tabs}
        defaultTab={tabs.find((l) => l.path === location?.state?.path)?.index || 0}
      />
    </Column>
  )
}

export default Settings
