import { useState } from 'react'

import { robotTagsBW } from 'assets/ilustrations'
import { ModalRatingSurvey, ProgressSurvey } from 'components'
import { colors } from 'configs'
import { Column, Image, Row, Typography } from 'design-system'

const TableSurvey = ({ users }) => {
  const selectedUser = useState(null)

  return (
    <Column mt='20px'>
      <Row p='8px' backgroundColor={colors.grey[25]} justifyContent='space-between'>
        <Typography fontWeight='600'>Nomes</Typography>
        <Typography fontWeight='600'>Bom ou ótimo</Typography>
      </Row>
      <Column>
        {users?.length > 0 ? (
          users?.map((user, index) => (
            <ProgressSurvey key={index} user={user} selectedUser={selectedUser} index={index} />
          ))
        ) : (
          <Column alignItems='center' justifyContent='center' gap='24px' height='280px'>
            <Image src={robotTagsBW} width='113px' />
            <Typography>
              Ainda não foram recebidas respostas para as pesquisas de satisfação.
            </Typography>
          </Column>
        )}
        {selectedUser[0] && (
          <ModalRatingSurvey
            open={selectedUser[0]}
            onClose={() => selectedUser[1](null)}
            user={selectedUser[0]}
          />
        )}
      </Column>
    </Column>
  )
}

export default TableSurvey
