/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { colors } from 'configs'
import { Typography } from 'design-system'
import { hexToRGBA } from 'helpers'

type Params = {
  textParams: string
  search: string
}

export const handleLetterHighlights = ({ textParams, search }: Params) => {
  const text = textParams
  const lowerText = text.toLowerCase()
  const lowerSearch = search.toLowerCase()
  const parts = []
  let lastIndex = 0

  while (lastIndex < text.length) {
    const index = lowerText.indexOf(lowerSearch, lastIndex)
    if (index === -1) {
      parts.push(text.slice(lastIndex))
      break
    }
    if (index > lastIndex) {
      parts.push(text.slice(lastIndex, index))
    }
    parts.push(
      <Typography as='span' key={index} color={colors.black[100]} backgroundColor={hexToRGBA(colors.blue[80], 0.5)}>
        {text.slice(index, index + search.length)}
      </Typography>,
    )
    lastIndex = index + search.length
  }
  return parts
}

export const filterMessageUniqueId = (mergeList: any) => {
  return mergeList
    .sort((a: any, b: any) => b.id - a.id)
    .reduce((acc: any, current: any) => {
      const x = acc.find((item: any) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
}
