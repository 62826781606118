import { AxiosResponse } from 'axios';

import { clientApi } from 'providers'
import { PaginatedResponse } from 'types/generic.types';

import { TextVariable, TextVariableListParams, TextVariableMapping } from './types';

export const convertTextVariable = (data: Partial<TextVariableMapping>): Promise<AxiosResponse<TextVariableMapping>> =>
  clientApi.post(`/text-variables/mapping/`, data)

export const showTextVariables = (params: TextVariableListParams): Promise<AxiosResponse<PaginatedResponse<TextVariable>>> =>
  clientApi.get('/text-variables/', { params });