/* eslint-disable @typescript-eslint/no-unused-vars */
import { lastMessageIconDict, messageStatusDict } from 'configs'
import { Icon, Row } from 'design-system'
import { SanitizeText } from 'design-system'

const ignoreSender = ['system', 'contact', 'internal']

const LastMessageText = ({ last_message }, solvedStatus) => (
  <Row alignItems='center' gap='4px'>
    {(!ignoreSender.includes(last_message?.sender_type) || last_message?.sent_through_whatsapp) && (
      <Icon
        size='14'
        icon={messageStatusDict[last_message?.status?.value]?.icon}
        color={messageStatusDict[last_message?.status?.value]?.color || 'grey.300'}
      />
    )}
    <SanitizeText text={last_message?.body?.text} fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const LastMessageTemplate = ({ last_message }, solvedStatus) => (
  <Row alignItems='center' gap='4px'>
    <SanitizeText text={last_message?.body?.text} fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const LastMessageFile = ({ last_message }, solvedStatus) => (
  <Row alignItems='center' gap='2px'>
    <Icon size='14' color='grey.300' icon={lastMessageIconDict[last_message?.files[0]?.file_category?.value || 'File']} />
    <SanitizeText text={last_message?.files[0]?.caption || last_message?.files[0]?.file_type?.label} fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const LastMessageList = ({ last_message }, solvedStatus) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='Megaphone' size='14' color='grey.300' />
    <SanitizeText text={last_message?.body?.text} fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const LastMessageLocation = (_, solvedStatus) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='Location' size='14' color='grey.300' />
    <SanitizeText text='Localização' fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const LastMessageContact = (_, solvedStatus) => (
  <Row alignItems='center' gap='4px'>
    <Icon icon='User' size='14' color='grey.300' />
    <SanitizeText text='Contato' fontWeight={solvedStatus ? '600' : '400'} />
  </Row>
)

const lastMessageDict = {
  text: LastMessageText,
  template: LastMessageTemplate,
  file: LastMessageFile,
  list: LastMessageList,
  location: LastMessageLocation,
  contact: LastMessageContact,
}

export default lastMessageDict
