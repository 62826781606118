import { useEffect, useState } from 'react'

import { Button, Column, Icon, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography } from 'design-system'
import { notify } from 'helpers'
import { showConnectionsListSelect, showFoldersList } from 'services'

import { ModalConfirmation } from './components'
import Header from './components/Header'
import FilesDirectory from './FilesDirectory'
import FoldersDirectory from './FoldersDirectory'

const ModalManageFiles = ({ ...props }) => {
  const [loading, setLoading] = useState(null)
  const [modalConfirmation, setModalConfirmation] = useState(null)
  const [folders, setFolders] = useState([])
  const [files, setFiles] = useState([])
  const [connections, setConnections] = useState([])
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [checkedFiles, setCheckedFiles] = useState(null)
  const [selectedConnection, setSelectedConnection] = useState(null)

  useEffect(() => {
    getConnections()
  }, [])

  useEffect(() => {
    selectedConnection && getFolders(selectedConnection?.id)
  }, [selectedConnection])

  const getConnections = async () => {
    try {
      setLoading(true)
      const { data } = await showConnectionsListSelect({ active: true })

      setConnections(data.results)
      setSelectedConnection(data.results[0])
    } catch {
      notify.error('Não foi possível buscar conexões.')
    } finally {
      setLoading(false)
    }
  }

  const getFolders = async (connection) => {
    try {
      const { data } = await showFoldersList({ page_size: 1000, connection })

      setFolders(data.results)
    } catch {
      notify.error('Não foi possível listar pastas.')
    }
  }

  const handleSelectFile = () => {
    props?.setFieldValue(selectedFile)
    props?.onClose()
  }

  return (
    <Modal {...props}>
      <ModalHeader onClose={props?.onClose}>
        <Column>
          <Typography fontSize='18px' fontWeight='600'>
            Gerenciador de Arquivos
          </Typography>
          {selectedFolder && (
            <Row alignItems='center' gap='4px'>
              <Icon icon='FolderOpened' color='secondary' />
              <Typography
                color='secondary'
                cursor='pointer'
                onClick={() => {
                  setCheckedFiles(null)
                  setSelectedFolder(null)
                }}
              >
                Minha Biblioteca
              </Typography>
              <Icon icon='Arrow' direction='right' color='grey.100' />
              <Typography>{selectedFolder?.name ?? 'Pasta'}</Typography>
            </Row>
          )}
        </Column>
      </ModalHeader>
      <ModalBody p='16px 16px 62px'>
        <Header
          categoryFilter={props.categoryFilter}
          files={files}
          selectedFolder={selectedFolder}
          setCheckedFiles={setCheckedFiles}
          setSelectedFolder={setSelectedFolder}
          connections={connections}
          selectedConnection={selectedConnection}
          setSelectedConnection={setSelectedConnection}
          checkedFiles={checkedFiles}
          setFolders={setFolders}
          setFiles={setFiles}
          folders={folders}
          canMoveFiles={selectedFolder && folders.length > 1 && files.length > 0 && !['recent', 'general'].includes(selectedFolder?.key) && false}
          setLoading={setLoading}
          loading={loading}
        />
        {loading ? (
          <Column height='140px' alignItems='center' justifyContent='center'>
            <Spinner />
          </Column>
        ) : (
          <Column height='400px' overflow='auto'>
            {selectedFolder ? (
              <FilesDirectory
                folders={folders}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                checkedFiles={checkedFiles}
                setModalConfirmation={setModalConfirmation}
                files={files}
                setFiles={setFiles}
                onSelectFile={setSelectedFile}
                categoryFilter={props?.categoryFilter}
              />
            ) : (
              <FoldersDirectory
                category={props?.categoryFilter}
                setSelectedFolder={setSelectedFolder}
                folders={folders}
                setFolders={setFolders}
                setModalConfirmation={setModalConfirmation}
                connection={selectedConnection?.id}
              />
            )}
          </Column>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant='text' color='text' onClick={selectedFolder ? () => setSelectedFolder(null) : props?.onClose}>
          {selectedFolder ? 'Voltar' : 'Cancelar'}
        </Button>
        {selectedFile && (
          <Button onClick={handleSelectFile} disabled={!selectedFile}>
            Aplicar
          </Button>
        )}
      </ModalFooter>
      {modalConfirmation && <ModalConfirmation {...modalConfirmation} onClose={() => setModalConfirmation(null)} />}
    </Modal>
  )
}

ModalManageFiles.defaultProps = {
  onClose: () => {},
  setFieldValue: () => {},
}

export default ModalManageFiles
