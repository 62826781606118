import { ToastContainer } from 'react-toastify'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import colors from './colors'
import theme from './theme'

import 'react-toastify/dist/ReactToastify.css'

const GlobalConfigs = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastContainer containerId='notify-container' {...toastConfigs} style={{ zIndex: 200000000 }} />
      <ToastContainer containerId='messages-container' {...toastMessageThreads} />
      <ToastContainer containerId='alert-robot-container' {...toastConfigs} {...toastRobotConfigs} />
      {children}
    </ThemeProvider>
  )
}

const toastConfigs = {
  autoClose: 2000,
  pauseOnHover: true,
}

const toastMessageThreads = {
  position: 'bottom-right',
  stacked: true,
  draggable: true,
  autoClose: 6000,
  progressStyle: {
    background: 'red',
  },
}

const toastRobotConfigs = {
  position: 'bottom-left',
  progress: undefined,
  hideProgressBar: true,
  width: '480px',
}

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    color: ${theme.colors.black[400]};
  }
  body {
    background-color: #fafafa;
  }
  * {
    margin: 0;
    padding:0;
    outline: none;
    box-sizing: border-box;
    font-family: "Source Sans 3";
  }
  *::-webkit-scrollbar {
        height: 5px;
        width: 7px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #fff;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #fff;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #fff;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #A6A6A6;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #A6A6A6;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #A6A6A6;
  }

  audio::-webkit-media-controls-panel {
    background: ${colors.grey[50]};
  }

  @keyframes slideDownModal {
    from {
      transform: translateY(-120px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes grow {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  .cursor-pointer, .cursor-pointer * {
    cursor: pointer !important;
  }

  .animation-fadeinmodal {
    animation: slideDownModal 0.5s ease forwards;
  }
  .animation-grow-element {
    animation: grow 0.5s linear forwards;
  }

  .transition {
    transition: all 0.3s ease;
  }


`

export default GlobalConfigs
