import { useEffect, useState } from 'react'

import { Card, Column, Row, Spinner, Typography } from 'design-system'
import { notify } from 'helpers'
import { useClickout } from 'hooks'
import { deleteFile, showFolderFiles, updateFile, uploadFile } from 'services'

import { EmptyData } from '../components'
import File from '../components/File'

const FilesDirectory = ({ categoryFilter, selectedFolder, checkedFiles, setModalConfirmation, folders, files, setFiles, onSelectFile }) => {
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const menuContextState = useClickout(false)

  const typeDict = {
    image: 'nenhuma imagem',
    video: 'nenhum vídeo',
    document: 'nenhum documento',
  }

  useEffect(() => {
    showFiles(selectedFolder, categoryFilter)
  }, [selectedFolder])

  const showFiles = async (folder, category) => {
    try {
      setLoading(true)

      const { data } = await showFolderFiles({ folder: folder?.id, category, ...folder?.filters, page_size: 1000 })

      if (category && data?.results?.length === 0) {
        setText(`Parece que você nao tem ${typeDict[category]} nessa pasta`)
      }

      setFiles(data.results)
    } catch {
      notify.error('Não foi possível listar arquivos da pasta.')
    } finally {
      setLoading(false)
    }
  }

  const handleRenameFile = async (values, callBack) => {
    try {
      const formData = new FormData()

      formData.append('connection', values.connection)
      formData.append('folder', values.folder)
      formData.append('name', values.name)

      const { data } = await updateFile(values.uuid, formData)

      setFiles((prevFiles) => prevFiles.map((file) => (file.uuid === data.uuid ? { ...file, name: data.name } : file)))

      callBack(false)
      notify.success('Arquivo renomeado com sucesso.')
    } catch {
      notify.success('Não foi possível renomear o arquivo.')
    }
  }

  const handleDeleteFile = async (uuid) => {
    try {
      await deleteFile(uuid)

      setFiles((prevState) => prevState.filter((f) => f.uuid !== uuid))
      setModalConfirmation(null)
      notify.success('Arquivo excluído com sucesso.')
    } catch (err) {
      if (err?.response?.data?.usages?.length > 0) {
        setModalConfirmation((prevState) => ({
          ...prevState,
          action: null,
          text: 'Esta mídia está em uso no GestãoDS e não pode ser excluída no momento.',
          cancelActionLabel: 'Fechar',
          content: (
            <Column mt='16px' gap='8px' width='100%'>
              {err?.response?.data?.usages.map(({ table, field, flux, user, client }) => (
                <Card p='8px' width='100%'>
                  <Typography fontWeight='600'>{table}</Typography>
                  <Column p='4px 24px' as='ul'>
                    {field && <Typography as='li'>Configuração: {field}</Typography>}
                    {flux && <Typography as='li'>Fluxo: {flux}</Typography>}
                    {user && <Typography as='li'>Usuário: {user}</Typography>}
                    {client && <Typography as='li'>Clínica: {client}</Typography>}
                  </Column>
                </Card>
              ))}
            </Column>
          ),
        }))
      }
      notify.error('Não foi possível deletar arquivo.')
    }
  }

  const handleUploadFile = async ({ target }) => {
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append('connection', selectedFolder?.connection?.id)
      formData.append('folder', selectedFolder?.id)
      formData.append('file', target.files[0])

      const { data } = await uploadFile(formData)

      setFiles((prevState) => [data, ...prevState])

      notify.success('Arquivo salvo com sucesso.')
    } catch {
      notify.error('Não foi possível salvar arquivo no momento.')
    } finally {
      setLoading(false)
    }
  }

  const handleMoveFile = async (_, targetFolder, file) => {
    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('folder', targetFolder)

      await updateFile(file?.uuid, formData)
      await showFiles(selectedFolder, categoryFilter)

      notify.success('Arquivo movido com sucesso.')
    } catch {
      notify.error('Não foi possível mover arquivo no momento.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Column>
      {loading ? (
        <Column alignItems='center' height='260px' justifyContent='center'>
          <Spinner />
        </Column>
      ) : (
        <Column p='4px'>
          {files.length > 0 ? (
            <Row mt='16px' flexWrap='wrap' gap='10px' overflow='auto' alignItems='flex-start'>
              {files.map((file) => (
                <File
                  key={file.uuid}
                  selectedFolder={selectedFolder}
                  setModalConfirmation={setModalConfirmation}
                  folders={folders}
                  renameAction={handleRenameFile}
                  deleteAction={handleDeleteFile}
                  moveAction={handleMoveFile}
                  menuContextState={menuContextState}
                  onSelectFile={onSelectFile}
                  file={file}
                  checkedFiles={checkedFiles}
                  active={selectedFile === file.uuid || checkedFiles}
                  setSelectedFile={setSelectedFile}
                />
              ))}
            </Row>
          ) : (
            <EmptyData uploadFile={handleUploadFile} text={text} />
          )}
        </Column>
      )}
    </Column>
  )
}

export default FilesDirectory
