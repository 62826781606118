import DOMPurify from 'dompurify'
import styled from 'styled-components'
import { space, layout, color, flexbox, compose, position, typography } from 'styled-system'

import { convertMarkdownToHtml } from 'helpers'
const ALLOWED_TAGS = ['strong', 'em', 's', 'br', 'ul', 'ol', 'li', 'span', 'p']

const styles = compose(space, layout, color, flexbox, position, typography)

const Base = styled.div`
  color: ${({ theme }) => theme.colors.grey[400]};

  word-break: break-word;

  ${({ ellipsis }) =>
    ellipsis
      ? `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `
      : ''}

  & ul,
  & ol {
    margin-left: 20px !important;
  }

  ${styles};
`

const highlightSearch = (html, searchTerm) => {
  if (!searchTerm?.trim()) return html

  const regex = new RegExp(`(${searchTerm})`, 'gi')

  return html.replace(regex, '<span style="background-color: #9ec3f1;">$1</span>')
}

const SanitizeDiv = ({ content, search, id, ...rest }) => {
  const highlightedHtml = highlightSearch(convertMarkdownToHtml(content), search)
  const sanitizedHtml = DOMPurify.sanitize(highlightedHtml, { ALLOWED_TAGS })

  return <Base id={id} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} {...rest} />
}

export default SanitizeDiv
