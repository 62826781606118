import { memo } from 'react'

const FrameGrid = (props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H8.325V15.675H5V19ZM10.325 19H13.675V15.675H10.325V19ZM15.675 19H19V15.675H15.675V19ZM5 13.675H8.325V10.325H5V13.675ZM10.325 13.675H13.675V10.325H10.325V13.675ZM15.675 13.675H19V10.325H15.675V13.675ZM5 8.325H8.325V5H5V8.325ZM10.325 8.325H13.675V5H10.325V8.325ZM15.675 8.325H19V5H15.675V8.325Z'
        fill='#0D0D0D'
      />
    </svg>
  )
}
const Memo = memo(FrameGrid)
export default Memo
