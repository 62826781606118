import { useCallback, useEffect, useRef, useState } from 'react'

import { Icon, Row, Tooltip, Typography } from 'design-system'
import { formatTime } from 'helpers'

import AudioWaveBars from './AudioWaveBars'

const Recording = ({
  setAudioUrl,
  setIsRecording,
  amplitudeRef,
  stream,
  mediaRecorderRef,
  setVoiceMessage,
  currentTime,
  setCurrentTime,
}) => {
  const audioChunks = useRef([])

  const intervalRef = useRef(null)
  const [paused, setIsPaused] = useState(false)
  const staticWaves = Array.from({ length: 32 }, () => Math.floor(Math.random() * 100))

  const startRecording = useCallback(async () => {
    try {
      mediaRecorderRef.current = new MediaRecorder(stream)

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data)
        }
      }

      mediaRecorderRef.current.onstop = () => {
        if (!mediaRecorderRef.current.cancelar) {
          const audioBlob = new Blob(audioChunks.current)
          const audioUrl = URL.createObjectURL(audioBlob)
          setAudioUrl(audioUrl)
          audioChunks.current = []
          const file = new File([audioBlob], 'audio_recording.webm')
          setVoiceMessage(file)
        }

        stream.getTracks().forEach((track) => track.stop())
      }

      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const source = audioContext.createMediaStreamSource(stream)

      const analyser = audioContext.createAnalyser()
      analyser.fftSize = 64
      const dataArray = new Uint8Array(analyser.frequencyBinCount)

      source.connect(analyser)

      const updateAmplitude = () => {
        analyser.getByteFrequencyData(dataArray)
        amplitudeRef.current = Array.from(dataArray)
        requestAnimationFrame(updateAmplitude)
      }

      updateAmplitude()

      mediaRecorderRef.current.start()
      setIsRecording(true)

      intervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => prevTime + 1)
      }, 1000)
    } catch (err) {
      console.error('Erro ao acessar o microfone:', err)
    }

    
  }, [setAudioUrl, setIsRecording, amplitudeRef, stream])

  const pauseResumeRecording = () => {
    const recording = mediaRecorderRef.current.state === 'recording'
    if (recording) {
      mediaRecorderRef.current.pause()
      clearInterval(intervalRef.current)
    } else {
      mediaRecorderRef.current.resume()
      intervalRef.current = setInterval(() => {
        setCurrentTime((prevTime) => prevTime + 1)
      }, 1000)
    }
    setIsPaused((prev) => !prev)
  }

  const stopRecording = () => {
    mediaRecorderRef.current.stop()
    setIsRecording(false)

    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  useEffect(() => {
    startRecording()

    return () => clearInterval(intervalRef.current)
  }, [startRecording])

  return (
    <Row alignItems='center' gap='4px'>
      <Row backgroundColor='danger' width='7px' height='7px' borderRadius='100%' />
      <Typography color='grey.400'>{formatTime(currentTime)}</Typography>
      <AudioWaveBars
        key={paused}
        {...(paused ? { staticWave: staticWaves } : { ref: amplitudeRef })}
      />
      <Tooltip position='top' title={paused ? 'Continuar' : 'Pausar'} width='120px'>
        <Icon
          icon={paused ? 'Play' : 'Pause'}
          color='grey.300'
          onClick={pauseResumeRecording}
          cursor='pointer'
        />
      </Tooltip>
      <Tooltip position='top' title='Parar' width='120px'>
        <Icon icon='Stop' color='grey.300' onClick={stopRecording} cursor='pointer' />
      </Tooltip>
    </Row>
  )
}

export default Recording
