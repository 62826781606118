import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { templateHeaderTypeDict } from 'components/modals/ModalCreateTemplate/templateOptions'
import PreviewTemplateCard from 'components/specific/templates/PreviewTemplateCard'
import { colors } from 'configs'
import { Button, Card, Column, Input, Row, Select, Skeleton, Typography } from 'design-system'
import { notify } from 'helpers'
import { showTemplateInfo, showTemplatesList } from 'services'

import { templateTypeDict } from '../constant'

const PreviewTemplate = ({ defaultConnection, loading, setManageFile }) => {
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [templatesList, setTemplatesList] = useState([])

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({ name: `template_params`, control })

  const [watchTemplate, watchParams] = watch(['template', 'template_params'])

  useEffect(() => {
    defaultConnection?.id && getTemplatesList(defaultConnection?.id)
  }, [defaultConnection])

  const getTemplatesList = async (connection) => {
    try {
      setLoadingInfo(true)
      const {
        data: { results },
      } = await showTemplatesList({
        connection,
        page_size: 100,
        status: 'APPROVED',
      })

      setTemplatesList(
        results.map((template) => ({
          ...template,
          name: `${template.category} - ${template.name}`,
        })),
      )
    } catch {
      notify.error('Não foi possível resgatar listagem de templates.')
    } finally {
      setLoadingInfo(false)
    }
  }

  const getTemplateInfo = async (id) => {
    try {
      remove()
      const { data } = await showTemplateInfo(id)

      const formatData = {
        ...data,
        header: {
          ...data.header,
          header_type: templateHeaderTypeDict[data?.template_type],
        },
      }

      const params = Array.from({ length: data.params_amount }, (_, id) => ({ param: `{{${id + 1}}}`, text: '' }))

      append(params)

      setValue('template', formatData)
    } catch {
      notify.error('Não foi possível resgatar informações do template.')
    }
  }

  const handleReplaceTemplateData = (data) => {
    if (watchParams?.length > 0) {
      watchParams.forEach((item) => {
        data.content = item.text ? data.content.replace(item.param, item.text) : data.content
      })
    }
    return data
  }

  return (
    <Card width='100%' mt='16px'>
      {loadingInfo || loading ? (
        <Column gap='16px'>
          <Row justifyContent='space-between'>
            <Column gap='16px'>
              <Skeleton height='30px' width='250px' />
              <Skeleton height='30px' width='250px' />
              <Skeleton height='30px' width='250px' />
            </Column>
            <Skeleton height='200px' width='320px' />
          </Row>
        </Column>
      ) : (
        <Row gap='16px'>
          <Column minWidth={defaultConnection?.id ? '298px' : '0'} gap='16px'>
            {defaultConnection?.id && (
              <Select
                label='Buscar template'
                options={templatesList}
                keys={{ label: 'name', value: 'id' }}
                name='template'
                control={control}
                error={errors.template}
                isDisabled={loading || loadingInfo}
                required
                callBack={({ id }) => getTemplateInfo(id)}
              />
            )}
            {templateTypeDict[watchTemplate?.template_type] === 'MEDIA' && setManageFile && (
              <Column>
                <Button variant='outlined' iconRight='Upload' onClick={() => setManageFile(true)}>
                  Selecionar arquivo
                </Button>
              </Column>
            )}
            {fields.length > 0 && <Typography>Defina os valores das variáveis antes de enviar o template.</Typography>}
            {fields.map((field, index) => (
              <Row
                p='16px'
                borderRadius='8px'
                key={field.id}
                background={fields?.length > 0 ? colors.grey[25] : colors.white}
                gap='16px'
                alignItems='center'
              >
                <Input maxWidth='94px' label='Variável' {...register(`template_params.${index}.param`)} disabled />
                <Input
                  maxWidth='200px'
                  disabled={loading || loadingInfo}
                  tabIndex='1'
                  label='Valor de variável'
                  error={errors?.template_params?.[index]}
                  errorMessage={errors?.template_params?.[index]?.text?.message}
                  {...register(`template_params.${index}.text`, { required: { value: true, message: 'Preencha o valor da variável' } })}
                  required
                />
              </Row>
            ))}
          </Column>

          {defaultConnection?.id ? (
            <Column width='100%'>
              {watchTemplate ? (
                <PreviewTemplateCard template={handleReplaceTemplateData({ ...watchTemplate })} />
              ) : (
                <Card p='32px' backgroundColor='grey.50'>
                  <Typography fontStyle='italic'>Selecione um template criado para visualizar uma prévia nesse espaço.</Typography>
                </Card>
              )}
            </Column>
          ) : (
            <Card width='100%' p='32px' backgroundColor='grey.50'>
              <Typography fontStyle='italic'>Selecione uma conexão para visualizar suas templates.</Typography>
            </Card>
          )}
        </Row>
      )}
    </Card>
  )
}

export default PreviewTemplate
