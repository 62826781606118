import { documentThumb, imageThumb, videoThumb } from 'assets'
import { FilePreview, Image, Row, Typography, VideoPlayer } from 'design-system'

const headerComponentDict = {
  TEXT: ({ text }) => <Typography>{text}</Typography>,
  IMAGE: ({ media }, setExpand) =>
    media?.file ? (
      <Row onClick={() => setExpand(media)} className='cursor-pointer'>
        <Image src={media?.file} width='100%' height='129px' objectFit='cover' />
      </Row>
    ) : (
      <Image width='100%' height='129px' src={imageThumb} />
    ),
  VIDEO: ({ media }) =>
    media?.file ? (
      <Row width='230px'>
        <VideoPlayer height='129px' width='100%' minHeight='129px' background='black' src={media?.file} />
      </Row>
    ) : (
      <Image src={videoThumb} width='100%' height='129px' />
    ),

  DOCUMENT: ({ media }) =>
    media?.file ? (
      <Row width='230px'>
        <FilePreview width='100%' src={media?.file} preview={media?.category} downloadLink={media.file} />
      </Row>
    ) : (
      <Image src={documentThumb} width='100%' height='129px' />
    ),
  LOCATION: () => <Typography>LOCATION</Typography>,
}

export default headerComponentDict
