import { colors } from 'configs'

export const dragStart = (e, index) => {
  e.dataTransfer.setData('dragIndexField', index)
  e.currentTarget.style.opacity = '0.5'
  e.currentTarget.style.border = `1px solid ${colors.primary}`
}

export const dragEnd = (e) => {
  e.currentTarget.style.opacity = '1'
  e.currentTarget.style.border = `1px solid ${colors.grey[50]}`
}

export const dragOver = (e) => {
  e.preventDefault()
  e.dataTransfer.dropEffect = 'move'
}

export const drop = (e, index, move) => {
  e.preventDefault()
  const dragIndex = e.dataTransfer.getData('dragIndexField')
  if (dragIndex !== null && dragIndex !== undefined) {
    move(Number(dragIndex), index)
  }
}
