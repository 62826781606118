const convertMarkdownToHtml = (content) => {
  content = content.replace(/(?:^|\n)(\d+\..*(?:\n\d+\..*)*)(?:\n|$)/g, (match) => {
    const items = match
      .trim()
      .split('\n')
      .map((item) => item.replace(/\d+\.\s*/, ''))
      .map((item) => `<li>${item}</li>`)
      .join('')

    return `\n<ol>${items}</ol>`
  })

  content = content.replace(/(?:^|\n)(\-+\ .*(?:\n\-+\ .*)*)(?:\n|$)/g, (match) => {
    const items = match
      .trim()
      .split('\n')
      .map((item) => item.replace(/\-+\ \s*/, ''))
      .map((item) => `<li>${item}</li>`)
      .join('')

    return `\n<ul>${items}</ul>`
  })

  content = content.replace(/~(.*?)~/g, '<s>$1</s>')

  content = content.replace(/_(.*?)_/g, '<em>$1</em>')

  content = content.replace(/\*(.*?)\*/g, '<strong style="font-weight: 600;">$1</strong>')

  content = content.replace(/<p>\s*<\/p>/g, '<br />')

  content = content.replace(/\n/g, '<br />')
  return content
}

export default convertMarkdownToHtml
