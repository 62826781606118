import { useEffect } from 'react'

import { useWindowResize } from 'hooks'

import Desktop from './Desktop'
import Mobile from './Mobile'

const Sidebar = ({ setOpen, open }) => {
  const { isMobile, isTablet } = useWindowResize()

  useEffect(() => {
    if (isMobile || isTablet) {
      setOpen(false)
    }
  }, [isMobile, isTablet, setOpen])

  return false ? (
    <Mobile open={open} setOpen={setOpen} />
  ) : (
    <Desktop open={open} setOpen={setOpen} />
  )
}

export default Sidebar
