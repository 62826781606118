import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { compose, layout, space } from 'styled-system'

import { colors, defaultColorsTags } from 'configs'
import { useClickout } from 'hooks'

import ColorChip from '../ColorChip'
import Icon from '../Icon'
import Popover from '../Popover'
import Row from '../Row'
import Typography from '../Typography'


const styles = compose(space, layout)

const Base = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  max-width: 120px;

  ${styles};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 5px 16px;
  border-radius: 5px;
  border: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
  background-color: ${({ disabled }) => (disabled ? `${colors.grey[40]}` : `${colors.white}`)};
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: ${({ theme: { colors } }) => colors.blue[80]};
  }
`

const ColorPicker = ({ label, onChange, name, defaultValue, colorsList, disabled, ...props }) => {
  const [selectedColor, setSelectedColor] = useState('')

  const { triggerRef, elementRef, openedState, setOpenedState } = useClickout(false)

  const handlerSelector = (color) => {
    setSelectedColor(color)
    setOpenedState(false)
    return onChange(name, color)
  }

  useEffect(() => {
    if (defaultValue) return setSelectedColor(defaultValue)

    setSelectedColor(colorsList[Math.floor(Math.random() * (colorsList.length * 1) + 1)])

    return () => setSelectedColor('')
  }, [defaultValue, colorsList])

  return (
    <Base {...props}>
      {label && <Typography variant='caption'>{label}</Typography>}
      <Container ref={triggerRef} disabled={disabled}>
        <ColorChip color={selectedColor} />
        <Icon
          icon='Arrow'
          direction={!disabled && openedState ? 'top' : 'bottom'}
          color='grey.100'
        />
      </Container>
      <Popover ref={elementRef} top='55px' open={!disabled && openedState}>
        <Row maxWidth='400px' justifyContent='center' flexWrap='wrap' p='10px' gap='10px'>
          {colorsList.length > 0 &&
            colorsList.map((elem) => (
              <ColorChip
                color={elem}
                width='32px'
                height='32px'
                borderRadius='100%'
                cursor='pointer'
                onClick={() => handlerSelector(elem)}
              />
            ))}
        </Row>
      </Popover>
    </Base>
  )
}

ColorPicker.defaultProps = {
  label: 'Color',
  name: 'color-picker',
  onChange: () => {},
  defaultValue: '',
  colorsList: defaultColorsTags,
}

export default ColorPicker
