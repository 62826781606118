import { useEffect, useRef, useState } from 'react'

import { Card, Icon, Input, Row, Typography } from 'design-system'
import { useClickout } from 'hooks'

import PopoverMenuOptions from '../PopoverMenuOptions'

const Folder = ({ folder, setModalConfirmation, deleteAction, onClick, renameFolder, noSubmenu, ...props }) => {
  const { openedState, elementRef, setOpenedState } = useClickout(false)
  const [onEdit, setOnEdit] = useState(null)
  const folderRef = useRef(null)

  const { id, name, icon = 'Folder' } = folder || {}

  const contextMenuActions = [
    {
      label: 'Renomear',
      icon: 'Edit',
      color: 'grey.300',
      action: () => setOnEdit(id),
    },
    {
      label: 'Excluir',
      icon: 'Trash',
      color: 'danger',
      action: () =>
        setModalConfirmation({
          open: true,
          action: () => deleteAction(id),
          title: `Excluir pasta ${folder.name}`,
          text: 'Deseja realmente excluir a pasta? Caso haja arquivos em uso, a pasta será mantida junto com esses arquivos. Arquivos não utilizados serão excluídos.',
        }),
    },
  ]

  const handleRenamePaste = (charCode, name) => (charCode === 13 ? renameFolder({ id, name }, setOnEdit) : null)

  useEffect(() => {
    if (onEdit) return setOpenedState(false)
  }, [onEdit])

  return (
    <Card
      className='cursor-pointer'
      flexDirection='row'
      justifyContent='space-around'
      alignItems='center'
      p='0px'
      width='100%'
      maxWidth='184px'
      backgroundColor='grey.40'
      position='relative'
      {...props}
    >
      <Row gap='8px' p='8px 0 8px 12px' width='100%' borderRadius='4px' onClick={() => onClick(folder)}>
        <Icon icon={icon} color='grey.300' />
        {onEdit ? (
          <Input
            ref={folderRef}
            height='21px'
            onKeyPress={(e) => handleRenamePaste(e.charCode, e.target.value)}
            autoFocus
            placeholder={name}
            onBlur={({ target }) => {
              handleRenamePaste(13, target.value || name)
              setOnEdit(null)
            }}
          />
        ) : (
          <Typography width='100px' ellipsis>
            {name}
          </Typography>
        )}
      </Row>

      {!noSubmenu && (
        <Row p='0 12px 0 0' height='100%' alignItems='center' onClick={() => setOpenedState(true)}>
          <Icon icon='More' color='grey.300' />
          <PopoverMenuOptions
            openedState={openedState}
            elementRef={elementRef}
            setModalConfirmation={setModalConfirmation}
            actions={contextMenuActions}
          />
        </Row>
      )}
    </Card>
  )
}

export default Folder
