import { colors } from 'configs'
import Row from 'design-system/Row'

import Typography from '../Typography'

const HighlightedText = ({ hightLight, text, content, ...props }) => (
  <Row p='8px 0' gap='4px' borderBottom={`1px solid ${colors.grey[50]}`} {...props}>
    <Typography fontWeight='600'>
      {`${hightLight}: `}
      {text && <Typography as='span'>{text}</Typography>}
    </Typography>
    {content && content}
  </Row>
)

HighlightedText.defaultProps = {
  hightLight: 'Strong',
  text: null,
  content: null,
}

export default HighlightedText
