import { forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import { space, layout, typography, color, compose, border } from 'styled-system'

import PopoverEmoji from 'components/popovers/PopoverEmoji'
import PopoverTextVariables from 'components/popovers/PopoverTextVariables'
import Icon from 'design-system/Icon'
import Row from 'design-system/Row'
import Skeleton from 'design-system/Skeleton'
import TextLabelHelper from 'design-system/TextLabelHelper'
import { insertAtCursor } from 'helpers'
import { useClickout } from 'hooks'

import Column from '../Column'
import Typography from '../Typography'

const style = compose(space, layout, typography, color, border)

const Base = styled(Column)`
  padding: 8px;
  border-radius: 4px;
  border: ${({ theme: { colors }, error }) => `1px solid ${error ? colors.danger : colors.grey[50]}`};
  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme: { colors }, disabled }) => (disabled ? colors.grey[50] : colors.blue[80])};
  }
  ${style};
`

const Input = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px;

  height: 100%;
  min-height: 50px;
  border: none;
  resize: ${({ resize }) => resize};
  color: ${({ theme: { colors } }) => colors.grey[300]};
  &:disabled {
    background-color: #fff;
  }
  ${style};
`

const TextArea = forwardRef(
  (
    {
      label,
      error,
      errorMessage,
      helperText,
      emojis,
      textVariables,
      setValue,
      getValues,
      disabled,
      containerProps,
      required,
      loading,
      watcher,
      inputRef,
      ...props
    },
    ref,
  ) => {
    const { triggerRef: triggerEmojiRef, elementRef: elementEmojiRef, openedState: openedStateEmoji } = useClickout(false)
    const { triggerRef: triggerTextVariablesRef, elementRef: elementTextVariablesRef, openedState: openedStateTextVariables } = useClickout(false)

    const handleChange = (value) =>
      setValue(props?.name, inputRef?.current ? insertAtCursor(inputRef, getValues(props?.name), value) : `${getValues(props?.name)}${value}`)

    useEffect(() => {
      if (watcher && inputRef?.current) {
        inputRef.current.style.height = 'auto'
        const newHeight = inputRef.current.scrollHeight
        inputRef.current.style.height = `${Math.min(newHeight, 200)}px`
        inputRef.current.style.overflowY = newHeight > 200 ? 'auto' : 'hidden'
      }
    }, [watcher])

    return loading ? (
      <Column width='100%' background='white' borderRadius='4px'>
        {label && <Skeleton width='100px' height='20px' />}
        <Skeleton mt='2px' p='15px' height='150px' {...containerProps} />
      </Column>
    ) : (
      <Column width='100%' background='white' borderRadius='4px' gap='4px'>
        <TextLabelHelper label={label} required={required} helper={props.helper} />
        <Base error={error} disabled={disabled} {...containerProps}>
          <Input
            ref={(e) => {
              ref(e)
              if (inputRef) {
                inputRef.current = e
              }
            }}
            disabled={disabled}
            {...props}
          />
          <Row gap='8px' justifyContent='space-between'>
            <Row gap='8px'>
              {emojis && (
                <Row position='relative'>
                  <Icon ref={triggerEmojiRef} icon='Emoji' color='grey.300' cursor='pointer' />
                  <PopoverEmoji elementRef={elementEmojiRef} top='20px' open={openedStateEmoji} onEmojiSelect={handleChange} />
                </Row>
              )}
              {textVariables && (
                <Row position='relative'>
                  <Icon ref={triggerTextVariablesRef} icon='AddCircle' color='grey.300' cursor='pointer' />
                  {openedStateTextVariables && (
                    <PopoverTextVariables
                      top='20px'
                      elementRef={elementTextVariablesRef}
                      open={openedStateTextVariables}
                      onTextSelect={handleChange}
                    />
                  )}
                </Row>
              )}
            </Row>
            {helperText && (
              <Typography mt='4px' ml='2px' variant='caption' fontStyle='italic' lineHeight='10px'>
                {helperText}
              </Typography>
            )}
          </Row>
        </Base>
        <Row></Row>
        {error && (
          <Row mt='4px' alignItems='center' gap='4px'>
            <Icon icon='Alert' color='danger' size='11' />
            <Typography mt='2px' ml='2px' variant='caption' color='danger' lineHeight='10px'>
              {errorMessage || 'Campo obrigatório'}
            </Typography>
          </Row>
        )}
      </Column>
    )
  },
)

TextArea.defaultProps = {
  label: null,
  resize: 'initial',
  placeholder: 'Digite seu texto',
  emojis: false,
  textVariables: false,
  setValue: () => undefined,
  getValues: () => undefined,
}

export default TextArea
