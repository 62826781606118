import { AxiosResponse } from 'axios'

import { clientApi } from 'providers'

import { Contact, ContactListParams, ContactSelect, ShowContactResponse, showContactsListSelectParams } from './type'

export const showContactInfo = (id: number): Promise<AxiosResponse<Contact>> => clientApi.get(`/contacts/${id}/`)

export const showContactsList = (params: ContactListParams): Promise<AxiosResponse<ShowContactResponse>> => clientApi.get('/contacts/', { params })

export const showContactsListSelect = (params: showContactsListSelectParams): Promise<AxiosResponse<ContactSelect>> =>
  clientApi.get('/contacts/select/', { params })

export const createContact = (data: Partial<Contact>): Promise<AxiosResponse<Contact>> => clientApi.post('/contacts/', data)

export const updatedContact = (id: number, data: Partial<Contact>): Promise<AxiosResponse<Contact>> => clientApi.put(`/contacts/${id}/`, data)

export const deleteContact = (id: number): Promise<AxiosResponse<void>> => clientApi.delete(`/contacts/${id}/`)

export const exportContact = (type: string): Promise<AxiosResponse> =>
  clientApi.get(`/contacts/export-contacts/${type}/`, { responseType: 'arraybuffer' })
