import L from 'leaflet'
import { memo, useMemo } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import styled from 'styled-components'

import { Row } from 'design-system'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
})

const Location = ({ longitude, latitude, title, ...props }) => {
  const initialPosition = useMemo(() => [latitude, longitude], [latitude, longitude])

  const tileUrl = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'

  return (
    <Row onClick={() => window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank')} overflow='hidden'>
      <CustomMapContainer center={initialPosition} zoom={16} scrollWheelZoom={false} {...props}>
        <TileLayer url={tileUrl} />
        <Marker key={`${latitude}_${longitude}`} position={[latitude, longitude]}>
          <Popup>{title}</Popup>
        </Marker>
      </CustomMapContainer>
    </Row>
  )
}

const CustomMapContainer = styled(MapContainer)`
  .leaflet-bottom {
    display: none !important;
  }
  .leaflet-container {
  }
  .leaflet-pane {
    z-index: 1 !important;
  }
  .leaflet-top {
    z-index: 2 !important;
  }
`

Location.defaultProps = {
  longitude: null,
  latitude: null,
  title: ' Clique aqui para expandir o mapa',
  style: { height: '200px', width: '100%', borderRadius: '8px' },
}

export default memo(Location)
