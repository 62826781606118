import styled from 'styled-components'

import { robotTags } from 'assets/ilustrations'
import { theme } from 'configs'
import { Row, Image } from 'design-system'

const Balloon = styled.div`
  position: relative;
  background: ${({ color }) => theme.colors[color]};
  color: white;
  padding: 20px;
  border-radius: 32px;
  min-width: 300px;
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: ${({ color }) => `transparent ${theme.colors[color]} transparent transparent`};
    display: block;
    width: 0;
    z-index: 1;
    left: -9px;
    top: 30px;
  }
`

const RobotAlert = ({ message, ...props }) => {
  return (
    <Row>
      <Image width='120px' src={robotTags} />
      <Balloon {...props}>{message}</Balloon>
    </Row>
  )
}

RobotAlert.defaultProps = {
  color: 'primary',
  message: 'ChatGDS message',
}
export default RobotAlert
