const colors = {
  white: '#ffffff',
  black: {
    100: '#0D0D0D',
    200: '#3A3A3A',
    300: '#121212',
  },
  grey: {
    20: '#F4F4F4',
    25: '#F3F3F3',
    30: '#FCFCFC',
    40: '#FBFBFB',
    50: '#E8E8E8',
    75: '#c0c0c0',
    90: '#EDEDED',
    100: '#a6a6a6',
    200: '#7f7f7f',
    300: '#656565',
    400: '#474747',
    500: '#3e3e3e',
  },
  yellow: {
    100: ' #FDECC8',
  },
  blue: {
    30: '#D3E5EF',
    40: '#EDF3FC',
    50: '#E9EBF8',
    60: '#F2F7FA',
    70: '#9ec3f1',
    80: '#136cdc',
  },
  brown: {
    200: '#eee0da',
  },
  red: {
    10: '#F6E6E6',
    100: '#FFE2DD',
    300: '#c52525',
    400: '#EB5757',
  },
  green: {
    10: '#E4F1E9',
    20: '#E4EDE7',
    100: '#DBEDDB',
    200: '#179848',
    300: '#106a32',
    400: '#8E98A8',
  },
  orange: {
    10: '#FBF0E6',
    20: '#F4EBE5',
    200: '#fadec9',
    300: '#ff8826',
    400: '#b35f1b',
    500: '#9c5317',
  },
  pink: {
    200: '#f5e0e9',
  },
  purple: {
    50: '#F4F3F9',
    100: '#5e54a9',
    200: '#554c98',
    300: '#4b4387',
    400: '#59597c',
    500: '#505070',
    600: '#474763',
  },
  violet: {
    50: '#8E98A8',
    60: '#EFEEF6',
    70: '#928aac',
    80: '#a49cc2',
    90: '#b6add7',
    100: '#ccc9e4',
    200: '#b8b5cd',
    300: '#a3a1b6',
  },
}

export default colors
