import { format } from 'date-fns'
import { memo } from 'react'
import styled from 'styled-components'

import { colors, dashboardSurveyDict } from 'configs'
import { Row, Typography, Column, Badge, Image } from 'design-system'

const RatingTicketCard = ({ ticket, handleGetTicketHistoric, active, ...props }) => {
  const { contact, department, closed_at, rating, id } = ticket

  const { color, label, backgroundColor, emoji } = dashboardSurveyDict[rating?.rate] || {
    label: 'Sem avaliação',
    color: colors.grey[300],
    backgroundColor: colors.grey[50],
  }

  return (
    <CustomColumn
      width='100%'
      p='8px 24px'
      borderBottom={`1px solid ${colors.grey[50]}`}
      className='cursor-pointer'
      onClick={() => handleGetTicketHistoric(id)}
      active={active}
      {...props}
    >
      <Row width='100%' justifyContent='space-between'>
        {closed_at && <Typography>{format(closed_at, 'dd/MM/yyyy HH:mm')}</Typography>}
        <Badge
          borderRadius='12px'
          backgroundColor={backgroundColor}
          title={
            <Row alignItems='center' gap='4px'>
              <Image width='14px' height='14px' src={emoji} />
              <Typography color={color} fontWeight='600' ellipsis>
                {label}
              </Typography>
            </Row>
          }
          noTooltip
          ellipsis
        />
      </Row>
      <Typography fontSize='12px'>{`Contato: ${contact?.name} | ${department?.name}`}</Typography>
    </CustomColumn>
  )
}

const CustomColumn = styled(Column)`
  background-color: ${({ active }) => (active ? colors.grey[50] : colors.grey[30])};

  &:hover {
    background-color: ${colors.grey[50]};
  }
`

export default memo(RatingTicketCard)
