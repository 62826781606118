import { AxiosResponse } from 'axios'

import { clientApi } from 'providers'

import { FileInterface, Folder, FoldersResponseListParams, MoveFile } from './type'

export const showFoldersList = (params: FoldersResponseListParams): Promise<AxiosResponse<Folder>> =>
  clientApi.get('/message-templates/medias/folders/', { params })

export const createFolder = (data: Folder): Promise<AxiosResponse<Folder>> => clientApi.post('/message-templates/medias/folders/', data)

export const updatedFolder = (id: string, data: Folder): Promise<AxiosResponse<Folder>> =>
  clientApi.put(`/message-templates/medias/folders/${id}/`, data)

export const deleteFolder = (id: string): Promise<AxiosResponse<void>> => clientApi.delete(`/message-templates/medias/folders/${id}/`)

export const uploadFile = (data: FileInterface): Promise<AxiosResponse> => clientApi.post('message-templates/medias/', data)

export const showFolderFiles = (params: FoldersResponseListParams) => clientApi.get('/message-templates/medias/', { params })

export const updateFile = (id: string, data: FileInterface): Promise<AxiosResponse> => clientApi.put(`message-templates/medias/${id}/`, data)

export const deleteFile = (id: string): Promise<AxiosResponse<void>> => clientApi.delete(`/message-templates/medias/${id}/`)

export const moveFile = (data: MoveFile): Promise<AxiosResponse<Folder>> => clientApi.put(`/message-templates/medias/folders/move-medias/`, data)
