import { useEffect, useState, forwardRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { space, color, layout, position, compose } from 'styled-system'

const styles = compose(space, color, layout, position)

const BasePopover = styled.div`
  position: absolute;
  width: fit-content;
  min-width: 110px;
  background-color: #fff;
  box-shadow: 4px 5px 15px -7px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  height: fit-content;
  z-index: 1060;
  ${styles};
`

const portalContainer = document.getElementById('portal-container')

const Popover = forwardRef(({ open, children, withPortal, parentRef, customPosition, ...props }, ref) => {
  const [positionStyle, setPositionStyle] = useState(null)

  useEffect(() => {
    if (withPortal && open && parentRef?.current) {
      const parentRect = parentRef.current.getBoundingClientRect()
      setPositionStyle({
        top: `${parentRect.bottom + window.scrollY + customPosition?.top || 0}px`,
        left: `${parentRect.left + window.scrollX + customPosition?.left || 0}px`,
      })
    }
  }, [withPortal, parentRef, open, customPosition])

  if (withPortal && open && positionStyle) {
    return createPortal(
      <BasePopover ref={ref} style={positionStyle} {...props}>
        {children}
      </BasePopover>,
      portalContainer,
    )
  }

  if (!withPortal && open) {
    return (
      <BasePopover ref={ref} {...props}>
        {children}
      </BasePopover>
    )
  }

  return null
})

export default Popover
