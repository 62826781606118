import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Input, Row, Button, Typography, TextArea } from 'design-system'
import { formatErrorMessages, notify } from 'helpers'
import { createQuickAnswer, showQuickAnswersInfo, updatedQuickAnswer } from 'services'
import { underscoreValidateRegex } from 'utils'

const ModalQuickAnswers = ({ onClose, quickResponse, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm()

  const initialValues = {
    shortcut: '',
    message: '',
  }

  useEffect(() => {
    if (quickResponse?.id) {
      handleGetInfo()
    }

    reset(initialValues)

    return () => reset(initialValues)
  }, [quickResponse])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      const { data } = quickResponse?.id ? await updatedQuickAnswer(quickResponse?.id, values) : await createQuickAnswer(values)

      dispatch({
        type: quickResponse?.id ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM,
        payload: data,
      })
      notify.success(quickResponse?.id ? 'Resposta rápida salva com sucesso.' : 'Nova resposta rápida criada com sucesso.')
      onClose()
    } catch (err) {
      if (err?.response?.data) {
        const errors = formatErrorMessages(err?.response?.data)
        errors.forEach(({ key, message }) => setError(key, message))
      }

      notify.error('Não foi possível salvar configuração da resposta rápida.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showQuickAnswersInfo(quickResponse?.id)

      reset(data)
    } catch {
      notify.error('Não foi possível buscar dados da resposta rápida.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='sm' open={quickResponse} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {quickResponse?.id ? 'Editar resposta rápida' : 'Nova resposta rápida'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row gap='16px'>
            <Input
              label='Nome'
              error={errors?.name}
              disabled={loading}
              errorMessage={errors?.name?.message}
              {...register('name', {
                required: true,
              })}
            />
            <Input
              label='Atalho'
              error={errors?.shortcut}
              disabled={loading}
              errorMessage={errors?.shortcut?.message}
              {...register('shortcut', {
                required: true,
                maxLength: {
                  message: 'O atalho pode ter no máximo 15 caracteres',
                  value: 15,
                },
                pattern: {
                  value: underscoreValidateRegex,
                  message: 'O atalho só pode conter letras minúsculas e underscores',
                },
              })}
            />
          </Row>
          <Row width='100%' mt='8px'>
            <TextArea
              label='Mensagem'
              error={errors?.message}
              disabled={loading}
              setValue={setValue}
              getValues={getValues}
              height='200px'
              emojis
              textvariables
              {...register('message', { required: true })}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalQuickAnswers
