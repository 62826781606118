import { colors } from 'configs'
import { Row, Column, Typography, Select } from 'design-system'


const SettingSelect = ({ label, description, ...props }) => {
  return (
    <Row alignItems='center' gap='16px' borderBottom={`1px solid ${colors.grey[50]}`} p='15px 0'>
      <Column width='100%'>
        <Typography>{label}</Typography>
        {description && (
          <Typography variant='caption' color={colors.grey[100]}>
            {description}
          </Typography>
        )}
        <Select width='100%' {...props} />
      </Column>
    </Row>
  )
}

SettingSelect.defaultProps = {
  label: 'Select label',
  description: null,
}

export default SettingSelect
